@use '../utils' as *;

/*----------------------------------------*/
/*  44. FOOTER CSS START
/*----------------------------------------*/

.footer{
    $self:&;
    &__widget{
        &-space{
            padding-top: 40px;
            padding-bottom: 40px;
        }
        &-title{
            font-size: 24px;
            color: var(--tp-common-white);
            margin-bottom: 25px;
            position: relative;
            font-family: var(--tp-ff-space);

            &-6{
                font-weight: 600;
                font-size: 20px;
                color: var(--tp-common-white);
                margin-bottom: 25px;
                margin-top: 15px;
            }

            &-7{
                font-family: var(--tp-ff-inter);
                font-weight: 600;
                font-size: 24px;
                color: var(--tp-common-white);
                margin-bottom: 40px;
                margin-top: 0;
            }

            &-8{
                font-family: var(--tp-ff-space);
                font-weight: 700;
                font-size: 22px;
                color: var(--tp-common-white);
                margin-bottom: 45px;
                margin-top: 0;
            }
        }
        & ul{
            & li{
                list-style: none;
                margin-bottom: 17px;
                line-height: 1;
                color: var(--tp-text-3);
                a{
                    font-family: var(--tp-ff-inter);
                    font-size: 16px;
                    color: var(--tp-text-3);
                    position: relative;
                    padding-left: 13px;

                    @include rtl{
                        padding-left: 0;
                        padding-right: 13px;
                    }

                    &::after{
                        position: absolute;
                        content: '';
                        left: 0;
                        top: 50%;
                        @include transform(translateY(-50%));
                        background-color: var(--tp-text-3);
                        width: 5px;
                        height: 5px;
                        background-color: var(--tp-text-3);
                        border-radius: 50%;
                        @extend %tp-transition;

                        @include rtl{
                            left: auto;
                            right: 0;
                        }
                    }

                    &:hover{
                        color: var(--tp-theme-1);
                    }
                }
            }
        }

        & p{
            color: var(--tp-text-4);
            font-size: 16px;
            font-weight: 500;
            font-family: var(--tp-ff-inter);
            margin-bottom: 20px;
        }

        &-3{
            & .footer__widget-title{
                margin-bottom: 50px;
                font-size: 22px;
            }
            & ul{
                & li{
                    list-style: none;
                    margin-bottom: 24px;
                    line-height: 1;
                    a{
                        font-family: var(--tp-ff-inter);
                        font-size: 16px;
                        color: var(--tp-text-8);
                        position: relative;
                        padding-left: 0;
                        font-weight: 500;

                        @include rtl{
                            padding-right: 0;
                        }
                        
                        &::after{
                            display: none;
                        }

                        &:hover{
                            color: var(--tp-theme-1);
                        }
                    }
                }
            }
        }

        &-4{

            & p{
                font-size: 16px;
                font-weight: 500;
                font-family: var(--tp-ff-inter);
                margin-bottom: 27px;
                color: #FFFFFF;
                opacity: 0.7;
            } 
            
            & ul{
                & li{
                    color: var(--tp-text-12);
                    a{
                        color: var(--tp-text-12);
                        &::after{
                            background-color: var(--tp-text-12);
                        }
    
                        &:hover{
                            color: var(--tp-theme-4);
                        }
                    }
                }
            }
        }

        &-5{

            & p{
                font-family: var(--tp-ff-inter);
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: var(--tp-text-3);
                margin-bottom: 27px;
            } 
            
            & ul{
                & li{
                    color: var(--tp-text-3);
                    a{
                        color: var(--tp-text-3);
                        &::after{
                            background-color:  var(--tp-text-3);
                        }
    
                        &:hover{
                            color: var(--tp-common-white);
                        }
                    }
                }
            }

            #{$self}{
                &__logo{
                    margin-bottom: 25px;
                }
            }
        }

        &-7{
            & p{
                font-family: var(--tp-ff-inter);
                font-weight: 400;
                font-size: 16px;
                line-height: 1.38;
                color: var(--tp-text-24);
                margin-bottom: 27px;
            }

        }

        &-6{

            & p{
                font-family: var(--tp-ff-roboto);
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: var(--tp-text-3);
                margin-bottom: 27px;
            } 
            
            & ul{
                & li{
                    list-style: none;
                    color: rgba($color: #fff, $alpha: .7);
                    margin-bottom: 8px;
                    font-size: 15px;
                    font-weight: 500;
                    font-family: var(--tp-ff-roboto);
                    a{
                        color: rgba($color: #fff, $alpha: .7);
                        font-family: var(--tp-ff-roboto);

                        @include rtl{
                            padding-right: 0;
                        }
                        &:hover{
                            color: var(--tp-common-white);
                        }
                    }
                }
            }

            #{$self}{
                &__logo{
                    margin-bottom: 25px;
                }
                &__info{
                    & p{
                        font-family: var(--tp-ff-roboto);
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 22px;
                        color: var(--tp-common-white);
                        margin-bottom: 20px;
                    }

                    & .link-btn-2{
                        font-family: var(--tp-ff-roboto);
                        font-weight: 500;
                        font-size: 15px;                    
                        color: #8F98AA;
                        
                        &::after{
                            background-color: #8F98AA;
                        }

                        &::before{
                            background-color: var(--tp-theme-6);
                        }
                        &:hover{
                            color: var(--tp-theme-6);
                        }
                    }
                }
            }
        }

        &-8{
            & p{
                font-family: var(--tp-ff-space);
                font-size: 16px;
                line-height: 24px;
                color: #C5C5C5;
            }

            & ul{
                & li{
                    list-style: none;
                    color:#C5C5C5;
                    margin-bottom: 22px;
                    font-size: 16px;
                    font-weight: 500;
                    font-family: var(--tp-ff-space);
                    a{
                        color: #C5C5C5;
                        padding-left: 0;
                        font-family: var(--tp-ff-space);

                        @include rtl{
                            padding-right: 0;
                        }
                        &::after{
                            display: none;

                        }
                        &:hover{
                            color: var(--tp-theme-8);
                        }
                    }
                }
            }

            & #{$self}{
                &__logo{
                    margin-bottom: 26px;
                }
            }
        }

        &-9{
            & #{$self}{
                &__logo{
                    margin-bottom: 0;
                }
            }

            & p{
                font-family: var(--tp-ff-syne);
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: rgba($color: #051214, $alpha: .8);
            }

            & ul{
                & li{
                    list-style: none;
                    color: rgba($color: #051214, $alpha: .8);
                    margin-bottom: 22px;
                    font-size: 16px;
                    font-weight: 500;
                    font-family: var(--tp-ff-syne);
                    a{
                        color: rgba($color: #051214, $alpha: .8);
                        padding-left: 0;
                        font-family: var(--tp-ff-syne);

                        @include rtl{
                            padding-right: 0;
                        }
                        &::after{
                            display: none;

                        }
                        &:hover{
                            color: var(--tp-common-black);
                        }
                    }
                }
            }
        }

        &-10{
            & #{$self}{
                &__logo{
                    margin-bottom: 21px;
                }

                &__widget{
                    &-title{
                        margin-bottom: 30px;
                    }
                }
            }

            & p{
                font-family: var(--tp-ff-space);
                font-weight: 500;
                font-size: 16px;
                line-height: 1.5;
                color: #C5C5C5;
            }

            & ul{
                & li{
                    font-family: var(--tp-ff-space);
                    font-size: 16px;
                    color: #C5C5C5;
                    a{
                        color: #C5C5C5;
                        padding-left: 15px;
                        font-family: var(--tp-ff-space);

                        @include rtl{
                            padding-left: 0;
                            padding-right: 15px;
                        }
                        &::after{
                            background-color: #C5C5C5;
                        }
                        &:hover{
                            color: var(--tp-theme-10);

                            &::after{
                                background-color: var(--tp-theme-10);
                            }
                        }
                    }
                }
            }
        }

       
    }
    &__logo{
        margin-bottom: 15px;
    }
    &__blog{
        &-item{
            margin-bottom: 15px;

            &:last-child{
                margin-bottom: 0;
            }

            &-2{
                margin-bottom: 22px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        &-meta{
            margin-bottom: 3px;
            & span{
                font-size: 14px;
                color: rgba($color: #fff, $alpha: .7);
                & i{
                    color: var(--tp-theme-3);
                    margin-right: 3px;

                    @include rtl{
                        margin-right: 0;
                        margin-left: 3px;
                    }
                }
               & a{
                    &:hover{
                        color: var(--tp-theme-3);
                    }
               }
            }

            &-4{
                & span{
                    font-family: var(--tp-ff-inter);
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 14px;
                    color: var(--tp-text-11);
                }
            }
        }
        &-thumb{
            & img{
                width: 86px;
                height: 86px;
                border-radius: 2px;
                margin-right: 13px;

                @include rtl{
                    margin-right: 0;
                    margin-left: 13px;
                }
            }
        }
        &-title{
            font-size: 17px;
            text-transform: capitalize;
            color: var(--tp-common-white);
            margin-bottom: 4px;
            line-height: 1.3;
            font-family: var(--tp-ff-space);
            letter-spacing: -1px;
            & a{
                &:hover{
                    color: var(--tp-theme-3);
                }
            }

            &-4{
                font-family: var(--tp-ff-inter);
                font-weight: 600;
                font-size: 16px;
                letter-spacing: -0.04em;
                color: var(--tp-common-white);
                margin-bottom: 7px;
                line-height: 1.3;

                & a{
                    &:hover{
                        color: var(--tp-theme-4);
                    }
                }
            }
        }
        &-content{
            padding-top: 4px;
        }
        &-tag{
            & a{
                display: inline-block;
                font-family: var(--tp-ff-inter);
                font-weight: 600;
                font-size: 12px;
                text-transform: uppercase;
                color: var(--tp-theme-4);
                position: relative;
                padding-left: 12px;

                @include rtl{
                    padding-left: 0;
                    padding-right: 12px;
                }
                &::after{
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 50%;
                    @include transform(translateY(-50%));
                    width: 5px;
                    height: 5px;
                    background-color: var(--tp-theme-4);
                    border-radius: 50%;

                    @include rtl{
                        left: auto;
                        right: 0;
                    }
                }
            }
        }
    }
    &__info{
        &-8{
            & p{
                font-weight: 500;
                font-size: 18px;
                color: var(--tp-common-white);
                margin-bottom: 27px;
            }
        }
        &-10{
            #{$self}{
                &__info{

                    &-icon-8{
                        & span{
                            color: var(--tp-theme-10);
                            & svg{
                                width: 20px;
                            }
                        }
                    }
                    &-text-8{
                        & h4{
                            color: #C5C5C5;
                            opacity: 1;
                            font-weight: 400;
                        }

                        & a{
                            &:hover{
                                color: var(--tp-theme-10);
                            }
                        }
                    }
                }
            }
        }
        &-item{
            margin-bottom: 20px;

            &-6{
                margin-bottom: 10px;

                &:last-child{
                    margin-bottom: 35px;
                }
            }

            &-8{
                margin-bottom: 20px;

                &:last-child{
                    margin-bottom: 0px;
                }
            }
        }
        &-icon{
            @include rtl{
                margin-right: 0;
                margin-left: 20px;
            }
            & i{
                display: inline-block;
                color: var(--tp-common-white);
                font-size: 16px;
                width: 46px;
                height: 46px;
                line-height: 46px;
                text-align: center;
                background-color: rgba($color: #fff, $alpha: .1);
                border-radius: 8px;
            }
            & span{
                display: inline-block;
                color: var(--tp-common-white);
                font-size: 16px;
                width: 46px;
                height: 46px;
                line-height: 46px;
                text-align: center;
                background-color: rgba($color: #fff, $alpha: .1);
                border-radius: 8px;
                & svg{
                    @include transform(translateY(-2px));
                }
            }

            &-6{
                @include rtl{
                    margin-right: 0;
                    margin-left: 20px;
                }
                & i,
                & span{
                    font-size: 16px;
                    color: var(--tp-common-white);
                }
                & svg{
                    width: 16px;
                }
            }

            &-8{
                @include rtl{
                    margin-right: 0;
                    margin-left: 20px;
                }
                & i,
                & span{
                    font-size: 16px;
                    color: var(--tp-theme-8);
                }
                & svg{
                    @include transform(translateY(-3px));
                }
            }
        }
        &-text{
            & h4{
                color: var(--tp-common-white);
                font-family: var(--tp-ff-space);
                font-weight: 700;
                font-size: 16px;
                margin-bottom: 0;
            }
            & a{
                color: var(--tp-text-3);
                font-weight: 400;
                font-size: 14px;
                font-family: var(--tp-ff-inter);

                &:hover{
                    color: var(--tp-common-white);
                }
            }

            &-6{
                & a{
                    font-weight: 500;
                    font-size: 15px;
                    color: rgba($color: #fff, $alpha: .7);
                    font-family: var(--tp-ff-roboto);
                    
                    &:hover{
                        color: var(--tp-common-white);
                    }
                }
            }

            &-8{
                & h4{
                    font-family: var(--tp-ff-space);
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: var(--tp-common-white);
                    opacity: 0.7;
                    margin-bottom: 0;
                }
                & a{
                    font-weight: 500;
                    font-size: 16px;
                    color: var(--tp-common-white);
                    font-family: var(--tp-ff-space);
                    
                    &:hover{
                        color: var(--tp-theme-8);
                    }
                }
            }
        }

        & .tp-link-btn-text{
            color: var(--tp-text-20);
            font-family: var(--tp-ff-roboto);
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            position: relative;
            padding-right: 20px;

            @include rtl{
                padding-right: 0;
                padding-left: 20px;
            }

            &::after{
                position: absolute;
                content: '';
                left: 0;
                bottom: -1px;
                width: 100%;
                background-color: var(--tp-text-20);
                height: 1px;
                @extend %tp-transition;
            }

            &:hover{
                color: var(--tp-common-white);

                &::after{
                    background-color: var(--tp-common-white);
                }
            }
        }
    }
    &__contact{
        margin-bottom: 12px;
        & p{
            color: var(--tp-text-1);
            font-size: 16px;
            margin-bottom: 20px;
        }

        & iframe{
            width: 100%;
            height: 110px;
            filter: grayscale(100%);
        }

        &-call{
            & span{
                font-size: 20px;
                font-weight: 500;
                color: var(--tp-common-white);
                font-family: var(--tp-ff-inter);
                & i{
                    color: var(--tp-theme-1);
                }
                & a{
                    &:hover{
                        color: var(--tp-theme-1);
                    }
                }
            }
        }
        &-mail{
            & span{
                font-size: 16px;
                color: var(--tp-text-3);
                font-family: var(--tp-ff-inter);
                line-height: 1;
                & i{
                    color: var(--tp-theme-1);
                }
                & a{
                    &:hover{
                        color: var(--tp-theme-1);
                    }
                }
            }
        }
    }
    &__subscribe{
        & p{
            color: var(--tp-text-1);
            font-size: 16px;
            margin-bottom: 20px;
        }

        &-7{
            & p{
                margin-bottom: 22px;
            }
        }

        &-8{
            & p{
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #C5C5C5;
                margin-bottom: 17px;
            }
        }
        &-box{
            position: relative;

            &-3{
                position: relative;

                & .footer-sub-btn-3{
                    display: inline-block;
                    font-size: 20px;
                    color: var(--tp-common-white);
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    @include transform(translateY(-50%));

                    @include rtl{
                        right: auto;
                        left: 20px;
                    }

                    &:hover{
                        color: var(--tp-theme-1);
                    }
                }
            }
        }
        &-input{
            & input{
                width: 100%;
                height: 60px;
                padding: 0 23px;
                padding-right: 70px;
                border: 1px solid #ccc;
                background-color: transparent;
                font-size: 16px;
                color: var(--tp-common-black);

                @include rtl{
                    padding-right: 23px;
                    padding-left: 70px;
                }
                
                @include tp-placeholder{
                    color: var(--tp-text-1);

                }
            }

            &-3{
                position: relative;
                & input{
                    width: 100%;
                    height: 60px;
                    padding: 0 60px;
                    padding-right: 45px;
                    border: 0;
                    box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.24);
                    background: rgba(255, 255, 255, 0.04);
                    box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.24);
                    backdrop-filter: blur(4px);
                    font-size: 16px;
                    color: var(--tp-common-white);

                    @include rtl{
                        padding-left: 45px;
                        padding-right: 60px;
                        text-align: right;
                    }
                    
                    @include tp-placeholder{
                        font-size: 16px;
                        color: rgba($color: #fff, $alpha: .6);
                        font-family: var(--tp-ff-inter);

                    }

                    &:focus{
                        box-shadow: 0px 2px 0px var(--tp-theme-3);
                    }
                }
                & i,
                & svg{
                    display: inline-block;
                    font-size: 16px;
                    color: rgba($color: #fff, $alpha: .6);
                    position: absolute;
                    top: 50%;
                    left: 25px;
                    @include transform(translateY(-50%));

                    @include rtl{
                        left: auto;
                        right: 25px;
                    }
                }
                & svg path{
                    stroke: rgba($color: #fff, $alpha: .6);
                }
            }

            &-7{
                position: relative;
                & input{
                    width: 100%;
                    height: 60px;
                    background-color: var(--tp-common-white);
                    border-radius: 30px;
                    font-family: var(--tp-ff-inter);
                    font-size: 15px;
                    border: 0;
                    outline: 0;
                    padding-left: 30px;
                    padding-right: 60px;

                    @include rtl{
                        padding-left: 60px;
                        padding-right: 30px;
                        text-align: right;
                    }
                    @include tp-placeholder{
                        color: #7D8995;
                    }
                }

                & button{
                    display: inline-block;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    color: var(--tp-common-black-11);
                    background-color: var(--tp-common-yellow-3);
                    border-radius: 50%;
                    font-size: 18px;
                    position: absolute;
                    top: 5px;
                    right: 5px;

                    @include rtl{
                        right: auto;
                        left: 5px;
                    }

                    &:hover{
                        color: var(--tp-common-white);
                        background-color: var(--tp-theme-7);
                    }
                }
            }

            &-8{
                position: relative;
                & input{
                    width: 100%;
                    height: 60px;
                    background-color: #313446;
                    font-family: var(--tp-ff-inter);
                    font-size: 16px;
                    border: 0;
                    outline: 0;
                    padding-left: 25px;
                    padding-right: 140px;
                    color: var(--tp-common-white);

                    @include rtl{
                        padding-left: 140px;
                        padding-right: 25px;
                        text-align: right;
                    }
                    @include tp-placeholder{
                        color: #FFFFFF;
                        opacity: .6;
                    }
                }

                & button{
                    display: inline-block;
                    font-family: var(--tp-ff-space);
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 60px;
                    height: 60px;
                    text-align: center;
                    padding: 0 26px;
                    color: var(--tp-common-white);
                    background-color: var(--tp-theme-8);
                    position: absolute;
                    top: 0;
                    right: 0;

                    @include rtl{
                        right: auto;
                        left: 0;
                    }
                    &:hover{
                        color: var(--tp-common-black-12);
                        background-color: var(--tp-common-white);
                    }
                }
            }
        }

        &-title{
            font-size: 50px;
            line-height: 1;
            font-weight: 700;
            font-family: var(--tp-ff-space);
            @include tp-gradient((89.64deg, #F87171 0.32%, #FACC15 32.07%, #4ADE80 60.55%, #38BDF8 99.28%));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            -moz-animation: sectionPreTitle 3s ease-in-out 1s forwards infinite alternate;
            -webkit-animation: sectionPreTitle 3s ease-in-out 1s forwards infinite alternate;
            animation: sectionPreTitle 3s ease-in-out 1s forwards infinite alternate;
            background-size: 150% 150%;
            margin-bottom: 35px;

            @media #{$xl}{
                font-size: 45px;
            }

            @media #{$xs}{
                font-size: 40px;
            }
        }
    }
    &__copyright{
        @media #{$sm, $xs}{
            margin-bottom: 20px;
        }
        & p{
            font-size: 14px;
            color: var(--tp-text-3);
            font-weight: 500;
            margin-bottom: 0;
            font-family: var(--tp-ff-inter);
            & a{
                color: var(--tp-common-white);
                display: inline-block;
            }
        }

        &-2{
            @media #{$sm, $xs}{
                margin-bottom: 20px;
            }
            & p{
                font-size: 14px;
                font-weight: 400;
                color: #7E7E7E;
                font-family: var(--tp-ff-inter);
                margin-bottom: 0;

                & a{
                    font-weight: 600;
                    color: var(--tp-theme-2);
                    display: inline-block;
                    &:hover{
                        color: var(--tp-common-black);
                    }
                }
            }
        }

        &-3{
            @media #{$sm, $xs}{
                margin-bottom: 20px;
            }
            & p{
                font-size: 14px;
                font-weight: 400;
                color: var(--tp-text-9);
                font-family: var(--tp-ff-inter);
                margin-bottom: 0;

                & a{
                    font-weight: 600;
                    color: var(--tp-common-white);
                    display: inline-block;
                }
            }
        }

        &-4{
            & p{
                color: var(--tp-text-11);
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 0;
                font-family: var(--tp-ff-inter);
                & a{
                    color: var(--tp-common-white);
                    display: inline-block;
                }
            }
        }

        &-5{
            & p{
                color: var(--tp-text-15);
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 0;
                font-family: var(--tp-ff-inter);
                & a{
                    font-weight: 600;
                    color: var(--tp-common-white);
                    display: inline-block;
                }
            }
        }

        &-6{
            & p{
                color: rgba($color: #fff, $alpha: .7);
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 0;
                & a{
                    font-weight: 500;
                    color: var(--tp-common-white);
                    display: inline-block;
                }
            }
        }

        &-7{
            & p{
                font-family: var(--tp-ff-inter);
                color: var(--tp-text-24);
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 0;
                & a{
                    font-weight: 500;
                    color: var(--tp-theme-7);
                    display: inline-block;
                }
            }
        }

        &-8{
            & p{
                font-family: var(--tp-ff-inter);
                font-size: 15px;
                color: #93949B;
                margin-bottom: 0;
                & a{
                    font-weight: 500;
                    color: var(--tp-common-white);
                    display: inline-block;
                    &:hover{
                        color: var(--tp-theme-8);
                    }
                }
            }
        }

        &-9{
            & p{
                font-family: var(--tp-ff-syne);
                font-weight: 400;
                font-size: 16px;
                color: #626272;
                margin-bottom: 0;

                & a{
                    font-weight: 500;
                    color: var(--tp-common-black);
                    display: inline-block;
                }
            }
        }

        &-10{
            & p{
                font-family: var(--tp-ff-space);
                font-size: 15px;
                color: #C5C5C5;

                & a{
                    color: var(--tp-theme-10);
                    display: inline-block;
                }
            }
        }

    }
    &__social{
        & a{
            display: inline-block;
            color: var(--tp-common-white);
            font-size: 16px;
            margin-right: 15px;

            @include rtl{
                margin-right: 0;
                margin-left: 15px;
            }

            &:last-child{
                margin-right: 0;

                @include rtl{
                    margin-left: 0;
                }
            }

            &:hover{
                color: var(--tp-theme-1);
            }
        }
        &-2{
            & a{
                display: inline-block;
                width: 40px;
                height: 40px;
                line-height: 36px;
                border: 2px solid rgba(22, 34, 19, 0.1);
                font-size: 14px;
                color: #5E6167;
                text-align: center;
                margin-right: 5px;

                @include rtl{
                    margin-right: 0;
                    margin-left: 2px;
                }
                &:last-child{
                    margin-right: 0;
                    @include rtl{
                        margin-left: 2px;
                    }
                }
                &:hover{
                    color: var(--tp-common-white);
                    border-color: var(--tp-theme-2);
                    background-color: var(--tp-theme-2);
                }
            }
        }
        &-3{
            & a{
                display: inline-block;
                width: 40px;
                height: 40px;
                line-height: 38px;
                text-align: center;
                color: var(--tp-common-white);
                border: 1px solid rgba($color: #fff, $alpha: .1);
                border-radius: 50%;
                font-size: 14px;
                margin-right: 5px;

                @include rtl{
                    margin-right: 0;
                    margin-left: 3px;
                }

                &:last-child{
                    margin-right: 0;

                    @include rtl{
                        margin-left: 0;
                    }
                }
                &:hover{
                    color: var(--tp-common-white);
                    background-color: var(--tp-theme-3);
                    border-color: var(--tp-theme-3);
                }
            }
        }
        &-4{
            & h5{
                font-family: var(--tp-ff-space);
                font-size: 18px;
                color: var(--tp-common-white);
            }
            & a{
                display: inline-block;
                width: 40px;
                height: 40px;
                line-height: 36px;
                text-align: center;
                color: var(--tp-common-white);
                border: 2px solid #FFFFFF1A;
                margin-right: 2px;

                @include rtl{
                    margin-right: 0;
                    margin-left: 2px;
                }
                &:last-child{
                    margin-right: 0;

                    @include rtl{
                        margin-left: 0;
                    }
                }
                &:hover{
                    color: var(--tp-common-white);
                    background-color: var(--tp-theme-4);
                    border-color: var(--tp-theme-4);
                }
            }
        }
        &-5{
            @media #{$xs}{
                margin-top: 15px;
            }
            & a{
                display: inline-block;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: var(--tp-common-white);
                margin-right: 6px;
                background-color: rgba($color: #fff, $alpha: .06);
                border-radius: 8px;

                @include rtl{
                    margin-right: 0;
                    margin-left: 3px;
                }
                &:last-child{
                    margin-right: 0;
                    @include rtl{
                        margin-left: 0;
                    }
                }
                &:hover{
                    color: var(--tp-common-white);
                    background-color: var(--tp-theme-5);
                }
            }
        }
        &-6{
            @media #{$xs}{
                margin-top: 15px;
            }
            & a{
                display: inline-block;
                width: 36px;
                height: 36px;
                line-height: 34px;
                text-align: center;
                color: var(--tp-common-white);
                margin-right: 3px;
                border: 1px solid rgba($color: #fff, $alpha: .1);

                @include rtl{
                    margin-right: 0;
                    margin-left: 3px;
                }
                &:last-child{
                    margin-right: 0;

                    @include rtl{
                        margin-left: 0;
                    }
                }
                &:hover{
                    color: var(--tp-common-white);
                    background-color: var(--tp-theme-6);
                    border-color: var(--tp-theme-6);
                }
            }
        }
        &-8{
            @media #{$xs}{
                margin-top: 15px;
            }
            & a{
                display: inline-block;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: var(--tp-common-white);
                margin-right: 5px;
                background-color: #313446;

                @include rtl{
                    margin-right: 0;
                    margin-left: 2px;
                }
                &:last-child{
                    margin-right: 0;

                    @include rtl{
                        margin-left: 0;
                    }
                }
                &:hover{
                    color: var(--tp-common-white);
                    background-color: var(--tp-theme-8);
                }
            }
        }
        &-9{
            @media #{$xs}{
                margin-top: 15px;
                justify-content: start;

                @include rtl{
                    justify-content: end;
                }
            }
            & a{
                display: inline-block;
                width: 44px;
                height: 44px;
                line-height: 44px;
                text-align: center;
                color: rgba($color: #051214, $alpha: .7);
                margin-right: 6px;
                background-color: #fff;
                box-shadow: 0px 1px 2px rgba(3, 4, 28, 0.08);

                @include rtl{
                    margin-right: 0;
                    margin-left: 6px;
                }
                &:last-child{
                    margin-right: 0;
                    @include rtl{
                        margin-left: 0;
                    }
                }
                &:hover{
                    color: var(--tp-common-white);
                    background-color: var(--tp-common-black);
                }
            }
        }
        &-10{
            margin-top: 2px;
            @media #{$xs}{
                margin-top: 15px;
            }
            & a{
                display: inline-block;
                text-align: center;
                color: var(--tp-common-white);
                margin-right: 15px;

                @include rtl{
                    margin-right: 0;
                    margin-left: 15px;
                }
                &:last-child{
                    margin-right: 0;

                    @include rtl{
                        margin-left: 0;
                    }
                }
                &:hover{
                    color: var(--tp-theme-10);
                }
            }
        }
    }
    &__top{
        position: relative;
        padding-top: 100px;
        padding-bottom: 40px;
        z-index: 1;

        &-2{
            padding-top: 130px;
            padding-bottom: 13px;
            border-bottom: 1px solid var(--tp-border-9);
        }

        &-3{
            position: relative;
            padding-top: 100px;
            padding-bottom: 70px;
            z-index: 1;
            border-bottom: 1px solid rgba($color: #fff, $alpha: .1);
        }
        &-4{
            padding-top: 95px;
            padding-bottom: 30px;
        }

        &-5{
            position: relative;
            padding-top: 100px;
            padding-bottom: 20px;
            z-index: 1;
            border-bottom: 1px solid rgba($color: #fff, $alpha: .08);
        }

        &-6{
            position: relative;
            padding-top: 120px;
            padding-bottom: 50px;
            z-index: 1;
        }
        &-7{
            position: relative;
            padding-top: 103px;
            padding-bottom: 45px;
            z-index: 1;
        }
        &-8{
            position: relative;
            padding-top: 113px;
            padding-bottom: 65px;
            z-index: 1;
            border-bottom: 1px solid var(--tp-border-1);
        }
        &-9{
            padding: 0;
            &-inner{
                position: relative;
                padding-top: 24px;
                padding-bottom: 24px;
                z-index: 1;
                border-bottom: 1px solid #E2E2E8;
            }
        }
    }
    &__bottom{
        position: relative;
        z-index: 1;
        &-inner{
            padding-top: 20px;
            padding-bottom: 20px;
            border-top: 1px solid var(--tp-border-1);

            &-2{
                padding-top: 20px;
                padding-bottom: 33px;
            }

            &-4{
                padding-top: 20px;
                padding-bottom: 20px;
                border-top: 1px solid var(--tp-border-1);
            }

            &-6{
                padding-top: 25px;
                padding-bottom: 25px;
                border-top: 1px solid var(--tp-border-1);
            }

            &-7{
                padding-top: 22px;
                padding-bottom: 40px;
                border-top: 1px solid var(--tp-border-1);
            }

        }
        &-3{
            padding-top: 26px;
            padding-bottom: 37px;
            position: relative;
            z-index: 1;
        }

        &-5{
            padding-top: 22px;
            padding-bottom: 40px;
        }

        &-8{
            padding-top: 23px;
            padding-bottom: 23px;
            background-color: #11142866;
        }

        &-9{
            padding-top: 23px;
            padding-bottom: 28px;
            position: relative;

            &::after{
                position: absolute;
                content: '';
                left: 0;
                bottom: 0;
                width: 100%;
                height: 5px;
                @include tp-gradient((329deg, #FF91FA 13.55%, #FA6490 48.54%, #F7D86A 86.44%));
            }
        }

        &-10{
            padding: 14px 0 7px;
            background-color: rgba($color: #fff, $alpha: .04);

            @media #{$sm, $xs}{
                padding-bottom: 20px;
            }
        }

        &-line{
            position: relative;

            &::after{
                position: absolute;
                content: '';
                left: 0;
                bottom: 0;
                width: 100%;
                height: 5px;
                @include tp-gradient((180deg, #FF3939 0.01%, #BF2132 100%));
            }
        }
    }
    &__app{
        &-item{
            margin-bottom: 10px;
        }
    }
    &__qrcode{
        background-color: var(--tp-common-white);
        padding: 10px 10px;
        &-thumb{
            @include rtl{
                margin-right: 0;
                margin-left: 10px;
            }
            & img{
                width: 44px;
                height: 44px;
            }
        }
        &-content{
            & p{
                margin-bottom: 0;
                font-family: var(--tp-ff-inter);
                color: #525258;
                font-size: 14px;
                & a{
                    &:hover{
                        color: var(--tp-theme-1);
                    }
                }
            }
        }
    }
    &__link{
        & a{
            display: inline-block;
            font-family: var(--tp-ff-inter);
            font-size: 14px;
            font-weight: 500;
            color: var(--tp-text-3);
            margin-right: 25px;

            @include rtl{
                margin-right: 0;
                margin-left: 25px;
            }

            &:last-child{
                margin-right: 0;
                @include rtl{
                    margin-left: 0;
                }
            }


            &:hover{
                color: var(--tp-common-white);
            }
        }

        &-4{
            & a{
                display: inline-block;
                font-family: var(--tp-ff-inter);
                font-size: 14px;
                font-weight: 500;
                color: var(--tp-text-11);
                margin-right: 25px;

                @include rtl{
                    margin-right: 0;
                    margin-left: 25px;
                }
    
                &:last-child{
                    margin-right: 0;

                    @include rtl{
                        margin-left: 0;
                    }
                }
   
                &:hover{
                    color: var(--tp-common-white);
                }
            }
        }

        &-6{
            & a{
                display: inline-block;
                font-size: 14px;
                font-weight: 500;
                color: rgba($color: #fff, $alpha: .5);
                position: relative;
                padding-right: 12px;
                margin-right: 5px;
                font-family: var(--tp-ff-roboto);

                @include rtl{
                    padding-right: 0;
                    margin-right: 0;
                    padding-left: 12px;
                    margin-left: 5px;
                }

                &::after{
                    position: absolute;
                    content: '';
                    right: 0;
                    top: 50%;
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background-color: rgba($color: #fff, $alpha: .5);

                    @include rtl{
                        right: auto;
                        left: 0;
                    }
                }
                

                &:last-child{
                    margin-right: 0;
                    padding-right: 0;

                    @include rtl{
                        margin-left: 0;
                        padding-left: 0;
                    }

                    &::after{
                        display: none;

                    }
                }
               
    
                &:hover{
                    color: var(--tp-common-white);
                }
            }
        }

        &-7{
            @media #{$xs}{
                margin-bottom: 15px;
            }
            & a{
                font-family: var(--tp-ff-inter);
                font-size: 14px;
                font-weight: 500;
                color: var(--tp-text-24);
                position: relative;
                margin-right: 25px;

                @include rtl{
                    margin-right: 0;
                    margin-left: 25px;
                }
                &:last-child{
                    margin-right: 0;
                }
                &:first-child{
                    margin-left: 0;
                }
    
                &:hover{
                    color: var(--tp-common-white);
                }
            }
        }

        &-10{
            & a{
                font-family: var(--tp-ff-space);
                font-size: 15px;
                color: #C5C5C5;
                margin-right: 15px;
                display: inline-block;

                @include rtl{
                    margin-right: 0;
                    margin-left: 15px;
                }

                &:last-child{
                    margin-right: 0;

                    @include rtl{
                        margin-left: 0;
                    }
                }

                &:hover{
                    color: var(--tp-theme-10);
                }
            }
        }
    }
    &__hello{
        & a{
            display: inline-block;
            line-height: 1;
            font-size: 15px;
            font-weight: 500;
            color: var(--tp-text-6);
            font-family: var(--tp-ff-inter);

            &:hover{
                color: var(--tp-theme-2);
            }
        }
    }
    &__opening{
        &-icon{
            @include rtl{
                margin-right: 0;
                margin-left: 15px;
            }
            & i{
                color: var(--tp-common-orange);
                font-size: 35px;
            }
            & span{
                color: var(--tp-common-orange);
            }
        }
        &-content{
            padding-right: 80px;

            @include rtl{
                padding-right: 0;
                padding-left: 80px;
            }
            & h4{
                font-family: var(--tp-ff-space);
                font-size: 17px;
                letter-spacing: -0.02em;
                color: var(--tp-common-white);
            }
            & p{
                font-family: var(--tp-ff-space);
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: var(--tp-text-3);
            }
        }
    }
    &__shape{
        &-1{
            position: absolute;
            bottom: 35%;
            left: 8%;
            -moz-animation: tprotate 3s ease-in-out 1s forwards infinite alternate;
            -webkit-animation: tprotate 3s ease-in-out 1s forwards infinite alternate;
            animation: tprotate 3s ease-in-out 1s forwards infinite alternate;
            @media #{$xl}{
                bottom: 47%;
                left: 6%;
            }

            @media #{$sm, $xs}{
                bottom: 65%;
            }
        }
        &-3{
            position: absolute;
            top: -6%;
            left: 10%;
            z-index: -1;

            @media #{$xxxxl, $xxxl, $xxl, $xl}{
                left: 0;
            }

            @media #{$xxl, $xl, $lg, $md, $sm, $xs}{
                left: 0;
                width: 100%;
            }
        }
        &-4{
            position: absolute;
            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            z-index: -1;
            object-fit: cover;
        }
    }
    &__bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        opacity: .1;
        background-blend-mode: luminosity;
    }
    &__lang{
        position: relative;
        padding-right: 0;
        $self:&;
        z-index: 9;

        @include rtl{
            padding-left: 0;
        }

        @media #{$sm, $xs}{
            margin-top: 15px;
        }
        & .nice-select{
            padding: 0;
            padding-right: 18px;
            padding-left: 28px;
            font-size: 14px;
            border: 0;
            color: var(--tp-common-white);
            background-color: transparent;

            @include rtl{
                padding-right: 28px;
                padding-left: 18px;
            }
            & .current{
                color: var(--tp-common-white);
                font-size: 14px;
                font-weight: 500;
            }
           
            & .list{
                border-radius: 0;
                margin-top: 0;
                left: auto;
                right: 0;

                @include rtl{
                    right: auto;
                    left: 0;
                }

                & .option{
                    color: var(--tp-text-1);
                    &:hover,
                    &.selected.focus{
                        color: var(--tp-theme-1);
                    }
                }
            }
        }

        &-selected-lang{
            color: var(--tp-common-white-op-7);
            font-size: 14px;
            font-weight: 500;
            background-color: transparent;
            position: relative;
            padding-right: 20px;
            font-family: var(--tp-ff-inter);
            @extend %tp-transition;

            @include rtl{
                padding-right: 0;
                padding-left: 20px;
            }
            &:hover{
                
                cursor: pointer;
            }
            &::after{
                position: absolute;
                content: '\f107';
                right: 0;
                top: 50%;
                @include transform(translateY(-50%));
                color: var(--tp-common-white);
                font-size: 14px;
                font-family: var(--tp-ff-fontawesome);
                @extend %tp-transition;

                @include rtl{
                    right: auto;
                    left: -20px;
                }
            }
        }
        &-wrapper{
            position: relative;
        }
        &-list{
            position: absolute;
            bottom: 150%;
            right: 0;
            background-color: var(--tp-common-white);
            z-index: 11;
            padding: 15px 28px;
            border-radius: 4px;
            visibility: hidden;
            opacity: 0;
            @include transition(.2s);

            @include rtl{
                right: auto;
                left: 0;
            }

            &.tp-lang-list-2{
                &.tp-lang-list-open-2{
                    visibility: visible;
                    opacity: 1;
                    bottom: 112%;
                }
            }

            & li{
                list-style: none;
                color: var(--tp-common-black);
                text-align: left;

                @include rtl{
                    text-align: right;
                }
                &:hover{
                    color: var(--tp-theme-7);
                    cursor: pointer;
                }
            }
        }
        &-img{
            @include rtl{
                margin-right: 0;
                margin-left: 15px;
            }
        }
    }
    &__text{
        font-size: 14px;
        line-height: 1.43;
        color: var(--tp-text-1);
        margin-bottom: 8px;

        & a{
            color: var(--tp-theme-1);
            text-decoration: underline;
        }
    }
}


#footer-list-inline{
    & li{
        display: inline-block;
        list-style: none;
        margin-right: 25px;
        margin-bottom: 0;

        @include rtl{
            margin-right: 0;
            margin-left: 25px;
        }

        &:last-child{
            margin-right: 0;

            @include rtl{
                margin-left: 0;
            }
        }
        & a{
            font-size: 14px;
            font-weight: 500;
            color: var(--tp-text-6);
            font-family: var(--tp-ff-inter);
            display: inline-block;
            padding-left: 0;
            text-transform: uppercase;
            
            @include rtl{
                padding-right: 0;
            }

            &::after{
                display: none;
            }

            &:hover{
                color: var(--tp-theme-2);
            }
        }
    }
}

#footer-list-inline-2{
    & li{
        display: inline-block;
        list-style: none;
        margin-right: 25px;
        margin-bottom: 0;

        @include rtl{
            margin-right: 0;
            margin-left: 25px;
        }

        &:last-child{
            margin-right: 0;

            @include rtl{
                margin-left: 0;
            }
        }
        & a{
            font-size: 14px;
            font-weight: 500;
            color: var(--tp-text-6);
            font-family: var(--tp-ff-inter);
            display: inline-block;
            padding-left: 0;
            text-transform: uppercase;

            @include rtl{
                padding-right: 0;
            }

            &::after{
                display: none;
            }

            &:hover{
                color: var(--tp-theme-2);
            }
        }
    }
}

#footer-list-inline-3{
    float: right;

    @include rtl{
        float: left;
    }
    @media #{$sm, $xs}{
        float: none;
        margin-top: 15px;
    }
    & li{
        display: inline-block;
        list-style: none;
        margin-right: 35px;
        margin-bottom: 0;

        @include rtl{
            margin-right: 0;
            margin-left: 35px;
        }

        @media #{$xs}{
            margin-right: 15px;

            @include rtl{
                margin-right: 0;
                margin-left: 15px;
            }
        }

        &:last-child{
            margin-right: 0;

            @include rtl{
                margin-left: 0;
            }
        }
        & a{
            font-size: 16px;
            font-weight: 500;
            color: rgba($color: #051214, $alpha: .8);
            font-family: var(--tp-ff-syne);
            display: inline-block;
            padding-left: 0;         
            position: relative;

            @include rtl{
                padding-right: 0;
            }

            &::after{
                display: none;
            }
            
            &::before{
                position: absolute;
                content: '';
                left: auto;
                right: 0;
                bottom: 0;
                width: 0%;
                height: 1px;
                background-color: var(--tp-common-black);
                @extend %tp-transition;

                @include rtl{
                    left: 0;
                    right: auto;
                }
            }

            &:hover{
                color: var(--tp-common-black);

                &::before{
                    left: 0;
                    right: auto;
                    width: 100%;
                    
                    @include rtl{
                        left: auto;
                        right: 0;
                    }
                }
            }
        }
    }
}

#footer-float-link{
    overflow: hidden;
    & li{
        float: left;
        width: 50%;

        @include rtl{
            float: right;
        }

        @media #{$xs}{
            width: 100%;
            float: none;
        }
    }
}

.footer-sub-btn{
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 20px;
    color: var(--tp-common-white);
    background-color: var(--tp-theme-1);

    @include rtl{
        right: auto;
        left: 0;
    }

    &:hover{
        color: var(--tp-common-white);
        background-color: var(--tp-common-black);
    }
}

/* footer col design for home 1 */
.footer-col{
    &-1{
       padding-right: 15px;

       @include rtl{
        padding-right: 0;
        padding-left: 15px;
    }
       
    }
    &-2{
        padding-left: 100px;

        @include rtl{
            padding-left: 0;
            padding-right: 100px;
        }
        
        @media #{$lg}{
            padding-left: 40px;

            @include rtl{
               padding-left: 0;
               padding-right: 40px;
            }
        }
        
        @media #{$xs}{
            padding-left: 0;
            @include rtl{
                padding-right: 0;
            }
        }
    }
    &-3{
        padding-left: 40px;

        @include rtl{
            padding-left: 0;
            padding-right: 40px;
        }

        @media #{$md, $sm, $xs}{
            padding-left: 0;

            @include rtl{
                padding-right: 0;
            }
        }
    }
    &-4{
        padding-right: 70px;

        @include rtl{
            padding-right: 0;
            padding-left: 70px;
        }
        @media #{$xl}{
            @include rtl{
                padding-right: 0;
                padding-left: 60px;
            }
        }
        @media #{$lg}{
            padding-right: 20px;
            @include rtl{
                padding-right: 0;
                padding-left: 20px;
            }
        }
        @media #{$sm}{
            padding-right: 35px;
            @include rtl{
              padding-right: 0;
              padding-left: 35px;
            }
        }
    }
}

/* footer col design for home 2 */
.footer-col-2{
    &-1{
        & .footer__logo{
            margin-bottom: 0;
        }
        @media #{$md}{
            margin-bottom: 20px;
            text-align: center;
        }
        @media #{$sm, $xs}{
            margin-bottom: 20px;
        }
    }
    &-2{
        margin-left: -50px;

        @include rtl{
            margin-left: 0;
            margin-right: -50px;
        }

        @media #{$md}{
            margin-left: 0;
            margin-bottom: 20px;

            @include rtl{
                margin-right: 0;
            }
        }

        @media #{$sm}{
            margin-bottom: 20px;
            text-align: right;

            @include rtl{
               text-align: left;
            }
        }

        @media #{$xs}{
            margin-bottom: 20px;
            margin-left: 0;

            @include rtl{
                margin-right: 0;
            }
        }
    }
    &-3{
        text-align: right;
        margin-right: -80px;

        @include rtl{
            text-align: left;
            margin-right: 0;
            margin-left: -80px;
        }

        @media #{$lg}{
            margin-right: -45px;

            @include rtl{
                margin-right: 0;
                margin-left: -45px;
            }
        }
        @media #{$md, $sm, $xs}{
            text-align: left;
            margin-bottom: 20px;
            margin-right: 0;

            @include rtl{
                text-align: right;
                margin-left: 0;
            }
        }
    }
    &-4{
        text-align: right;
        
        @include rtl{
            text-align: left;
        }
        @media #{$md, $sm, $xs}{
            text-align: left;
            margin-right: 0;
            margin-bottom: 20px;

            @include rtl{
               text-align: right;
               margin-left: 0;
            }
        }
    }
}

/* footer col design for home 3 */
.footer-col-3{
    &-1{
        padding-right: 70px;
        
        @include rtl{
            padding-right: 0;
            padding-left: 70px;
        }

        @media #{$xs}{
            padding-right: 0;
            @include rtl{
               padding-left: 0;
            }
        }

        & .footer__logo{
            margin-bottom: 33px;
            padding-top: 10px;
        }
    }

    &-3{
        padding-left: 30px;

        @include rtl{
            padding-left: 0;
            padding-right: 30px;
         }
        @media #{$md, $xs}{
            padding-left: 0;
            @include rtl{
                padding-right: 0;
             }
        }
        
    }

}

/* footer col design for home 4 */
.footer-col-4{
    &-1{
        padding-right: 30px;

        @include rtl{
           padding-right: 0;
           padding-left: 30px;
         }
        @media #{$lg}{
            padding-right: 0;
            @include rtl{
                padding-left: 0;
            }
        }
    }
    &-2{
       padding-left: 100px;
        @include rtl{
            padding-left: 0;
            padding-right: 100px;
        }
        @media #{$lg, $sm, $xs}{
            padding-left: 0;

            @include rtl{
                padding-right: 0;
            }
        }
    }
    &-3{
        padding-left: 40px;
        @include rtl{
            padding-left: 0;
            padding-right: 40px;
        }
        @media #{$lg, $md, $sm, $xs}{
            padding-left: 0;

            @include rtl{
                padding-right: 0;
             }
        }
    }

}

/* footer col design for home 5 */
.footer-col-5{
    &-1{
        padding-right: 75px;

        @include rtl{
            padding-right: 0;
            padding-left: 75px;
         }
        @media #{$xl}{
            padding-right: 55px;
            
            @include rtl{
                padding-right: 0;
                padding-left: 55px;
            }
        }
        @media #{$md}{
            padding-right: 40px;
            @include rtl{
                padding-right: 0;
                padding-left: 40px;
            }
        }
        @media #{$xs}{
            padding-right: 0;
            @include rtl{
                padding-left: 0;
            }
        }
    }

    &-3{
        padding-left: 100px;
        @include rtl{
            padding-left: 0;
            padding-right: 100px;
        }
        @media #{$xl}{
            padding-left: 70px;
            @include rtl{
                padding-left: 0;
                padding-right: 70px;
            }
        }

        @media #{$lg, $md, $sm, $xs}{
            padding-left: 0;

            @include rtl{
                padding-left: 0;
            }
        }
    }

}

/* footer col design for home 6 */
.footer-col-6{
    &-1{
        padding-right: 5px;

        @include rtl{
            padding-right: 0;
            padding-left: 5px;
        }
    }
    &-2{
       padding-left: 70px;

       @include rtl{
            padding-left: 0;
            padding-right: 70px;
       }
       @media #{$xs}{
            padding-left: 0;

            @include rtl{
                padding-right: 0;
            }
        }
    }

    &-4{
        padding-left: 70px;
        @include rtl{
            padding-left: 0;
            padding-right: 70px;
        }
        @media #{$lg, $xs}{
            padding-left: 0;
            @include rtl{
                padding-right: 0;
            }
        }
    }
}


/* footer col design for home 7 */
.footer-col-7{
    &-1{
       padding-right: 15px;
       @include rtl{
        padding-right: 0;
        padding-left: 15px;
       }
    }
    &-2{
        padding-left: 100px;
        @include rtl{
            padding-left: 0;
            padding-right: 100px;
        }
        @media #{$lg}{
            padding-left: 40px;
            @include rtl{
                padding-left: 0;
                padding-right: 40px;
            }
        }
        
        @media #{$xs}{
            padding-left: 0;
            @include rtl{
                padding-right: 0;
            }
        }
    }
    &-3{
        padding-left: 40px;
        @include rtl{
            padding-left: 0;
            padding-right: 40px;
        }
        @media #{$md, $sm, $xs}{
            padding-left: 0;
            @include rtl{
                padding-right: 0;
            }
        }
    }
    &-4{
        padding-left: 70px;
        @include rtl{
            padding-left: 0;
            padding-right: 70px;
        }
        @media #{$lg, $md, $sm, $xs}{
            padding-left: 0;
            @include rtl{
                padding-right: 0;
            }
        }
    }
}


/* footer col design for home 8 */
.footer-col-8{

    &-2{
        padding-left: 100px;
        @include rtl{
            padding-left: 0;
            padding-right: 100px;
        }
        @media #{$lg}{
            padding-left: 55px;
            @include rtl{
                padding-left: 0;
                padding-right: 55px;
            }
        }
        
        @media #{$xs}{
            padding-left: 0;
            @include rtl{
                padding-right: 0;
            }
        }
    }
    &-3{
        padding-left: 30px;
        @include rtl{
            padding-left: 0;
            padding-right: 30px;
        }
        @media #{$md, $sm, $xs}{
            padding-left: 0;
            @include rtl{
                padding-right: 0;
            }
        }
    }
    &-4{
        padding-left: 40px;
        @include rtl{
            padding-left: 0;
            padding-right: 40px;
        }
        @media #{$lg, $md, $sm, $xs}{
            padding-left: 0;
            @include rtl{
                padding-right: 0;
            }
        }
    }
}

/* footer col design for home 10 */
.footer-col-10{
    &-1{
       
       
    }
    &-2{
        padding-left: 38px;
        @include rtl{
            padding-left: 0;
            padding-right: 38px;
        }
        
        @media #{$lg}{
            padding-left: 0;
            @include rtl{
                padding-right: 0;
            }
        }
        
        @media #{$sm, $xs}{
            padding-left: 0;
            @include rtl{
                padding-right: 0;
            }
        }
    }
    &-3{
        padding-left: 60px;
        @include rtl{
            padding-left: 0;
            padding-right: 60px;
        }
        @media #{$md, $sm, $xs}{
            padding-left: 0;
            @include rtl{
                padding-right: 0;
            }
        }
    }
    &-4{

    }
}


.footer-col-11{
    &-1{
       padding-right: 70px;
       @include rtl{
        padding-right: 0;
        padding-left: 70px;
       }
       @media #{$lg}{
        padding-right: 0;
        @include rtl{
            padding-left: 0;
        }
       }
    }
    &-2{
        padding-left: 28px;
        @include rtl{
            padding-left: 0;
            padding-right: 28px;
        }
        @media #{$lg}{
            padding-left: 0;
            @include rtl{
                padding-right: 0;
            }
        }
        
        @media #{$sm, $xs}{
            padding-left: 0;
            @include rtl{
                padding-right: 0;
            }
        }
    }
    &-3{
        padding-left: 42px;
        @include rtl{
            padding-left: 0;
            padding-right: 42px;
        }
        @media #{$md, $sm, $xs}{
            padding-left: 0;
            @include rtl{
                padding-right: 0;
            }
        }
    }
    &-4{
        margin-left: -13px;
        @include rtl{
            margin-left: 0;
            margin-right: -13px;
        }
        @media #{$lg}{
            margin-left: 0;
            padding-left: 15px;
            @include rtl{
                margin-right: 0;
                padding-left: 0;
                padding-right: 15px;
            }
        }

        @media #{$md, $xs}{
            margin-left: 0;
            @include rtl{
                margin-right: 0;
            }
        }
    }
    &-5{
        padding-left: 70px;
        @include rtl{
            padding-left: 0;
            padding-right: 70px;
        }

        @media #{$lg, $sm, $xs}{
            padding-left: 0;
            @include rtl{
                padding-right: 0;
            }
        }
    }
}


/* footer style 7 */

.footer{
    $self: &;
    &__style{
        &-green{

            .footer__logo {
                margin-bottom: 20px;
            }

            .footer__contact-call span{
                
                & i{
                    color: var(--tp-common-yellow-3);
                }
                & a{
                    &:hover{
                        color: var(--tp-theme-7);
                    }
                }
            }

            & .footer__contact-mail span{
                color: var(--tp-text-24);

                & a{
                    &:hover{
                        color: var(--tp-theme-7);
                    }
                }
            }

            & .footer__widget p{
                color: var(--tp-text-24);
            }

            

            & .footer__social a{
                color: var(--tp-common-white);

                &:hover{
                    color: var(--tp-theme-7);
                }
            }

            & .footer__widget ul li a{
                color: var(--tp-text-24);

                &::after{
                    background-color: var(--tp-text-24);
                }

                &:hover{
                    color: var(--tp-common-white);
                }
            }
        }
        &-blue{
            .footer__contact-call span{
                
                & i{
                    color: #FFD584;
                }
            }

            & .footer__contact-mail span{
                color: var(--tp-text-24);
            }

            & .footer__widget p{
                color: var(--tp-text-24);
            }

            

            & .footer__widget ul li a{
                color: var(--tp-text-24);

                &::after{
                    background-color: var(--tp-text-24);
                }

                &:hover{
                    color: var(--tp-theme-1);
                }
            }

            & .footer__link-7{
                & a{
                    &:hover{
                        color: var(--tp-theme-1);
                    }
                }
            }
            .footer__copyright-7 p a{
                color: var(--tp-theme-1);

                &:hover{
                    color: var(--tp-common-white);
                }
            }

            .footer__subscribe-input-7{
                
                & input{
                    border-radius: 0;

                }

                & button{
                    border-radius: 0;
                    background-color: var(--tp-theme-1);
                    color: var(--tp-common-white);

                    &:hover{
                        background-color: var(--tp-common-black);
                        color: var(--tp-common-white);
                    }
                }
            }

            .footer__lang-selected-lang:hover::after,
            .footer__lang-selected-lang:hover{
                color: var(--tp-theme-1);
            }
            .footer__lang-list li:hover{
                color: var(--tp-theme-1);
            }
            .footer__lang-list{
                border-radius: 0;
            }
        }
        &-2{
            #{$self}{
                &__widget{
                    & p{
                        color: #B0B0B8;
                    }

                    & ul{
                        & li{
                            & a{
                                color: #B0B0B8;

                                &:hover{
                                    color: var(--tp-theme-1);
                                }
                            }
                        }
                    }
                }
                &__subscribe{
                    &-title{
                        -webkit-text-fill-color: var(--tp-common-white);
                        background-clip: inherit;
                        color: var(--tp-common-white);
                        background: none;
                        letter-spacing: -0.04em;
                    }
                }
                &__bottom-inner{
                    padding-bottom: 30px;
                }
            }
        }
        &-3{
            #{$self}{
                &__widget{
                    &-title{
                        font-weight: 700;
                        font-size: 24px;
                        letter-spacing: -0.02em;
                        color: var(--tp-common-black);
                    }
                    & p{
                        color: var(--tp-text-1);
                        line-height: 1.5;
                    }

                    & ul{
                        & li{
                            & a{
                                color: var(--tp-text-1);
                                &::after{
                                    background-color: var(--tp-text-1);
                                }
                                &:hover{
                                    color: var(--tp-theme-1);
                                }
                            }
                        }
                    }
                }
                &__opening{
                    &-icon{
                        & span{
                            color: var(--tp-common-purple);
                        }
                    }
                    &-content{
                        & h4{
                            color: var(--tp-common-black);
                        }
                        & p{
                            font-weight: 400;
                            color: var(--tp-text-1);
                        }
                    }
                }
                &__info{
                    &-icon{
                        & span{
                            background-color: var(--tp-common-white);
                            box-shadow: 0px 1px 2px rgba(3, 4, 28, 0.1);
                            border-radius: 0;
                            color: var(--tp-text-1);
                        }
                    }
                    &-text{
                        & h4{
                            font-size: 16px;
                            color: var(--tp-common-black);
                        }
                        & a{
                            color: var(--tp-text-1);

                            &:hover{
                                color: var(--tp-theme-1);
                            }
                        }
                    }
                }
                &__bottom{
                    &-5{
                        padding-top: 27px;
                        padding-bottom: 35px;
                        border-top: 1px solid #E1E1EC;
                    }
                }
                &__copyright-5{
                    & p{
                        color: #81849B;
                        font-size: 15px;
                        & a{
                            color: var(--tp-theme-1);
                        }
                    }
                }
                &__social{
                    &-5{
                        & a{
                            border-radius: 0;
                            box-shadow: 0px 1px 2px rgba(3, 4, 28, 0.1);
                            background-color: var(--tp-common-white);
                            color: var(--tp-text-1);

                            &:hover{
                                background-color: var(--tp-theme-1);
                                color: var(--tp-common-white);
                            }
                        }
                    }
                }
            }
        }
        &-4{
            #{$self}{
                &__widget{
                    & .footer__widget-title{
                        font-family: var(--tp-ff-roboto);
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 1;
                        color: var(--tp-common-black);
                        margin-bottom: 36px;
                        padding-top: 3px;
                    }
        
                    & p{
                        font-size: 16px;
                        line-height: 1.38;
                        color: var(--tp-text-1);
                        font-weight: 400;
                    }
                    
                    & ul{
                        & li{
                            list-style: none;
                            margin-bottom: 15px;
                            line-height: 1;
                            font-size: 15px;
                            color: var(--tp-text-1);
                            a{
                                font-family: var(--tp-ff-inter);
                                font-weight: 400;
                                font-size: 15px;
                                color: var(--tp-text-1);
                                padding-left: 0;

                                @include rtl{
                                    padding-right: 0;
                                }
        
                                &::after{
                                    display: none;
                                }
        
                                &:hover{
                                    color: var(--tp-theme-1);
                                }
                            }
                        }
                    }
                }
                &__contact{
                    &-call{
                        span{
                            color: var(--tp-common-black);

                            &:hover{
                                color: var(--tp-theme-1);
                            }
                        }
                    }
                    &-mail{
                        span{
                            font-size: 15px;
                            color: var(--tp-text-1);

                            &:hover{
                                color: var(--tp-theme-1);
                            }
                        }
                    }
                }
                &__social{
                    & a{
                        color: var(--tp-text-1);

                        &:hover{
                            color: var(--tp-theme-1);
                        }
                    }
                }
                &__bottom{
                    &-inner{
                        padding-top: 20px;
                        padding-bottom: 40px;
                        border-color: #EBECED;
                    }
                }
                &__copyright{
                    & p{
                        color: #7D7F82;
                        font-weight: 400;
                        & a{
                            color: var(--tp-theme-1);
                        }
                    }
                }
            }
        }
    }
}


.zoom-instagram-widget__items{
    list-style: none;
}

.zoom-instagram-widget__item{
    float: left;
    margin-right: 12px;
    width: 30.44%;
    border-radius: 6px;
    overflow: hidden;

    @include rtl{
        float: right;
        margin-right: 0;
        margin-left: 12px;
    }
    &:nth-child(3n){
        margin-right: 0;

        @include rtl{
            margin-left: 0;
        }
    }
    & a{
        padding-left: 0 !important;
        position: relative;
        display: inline-block;

        @include rtl{
            padding-right: 0 !important;
        }
        &::after{
            display: none;
        }

        &::before{
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 6px;
            background-color: var(--tp-common-black);
            opacity: 0;
            @extend %tp-transition;
        }

        &:hover{
            &::before{
                opacity: .4;
            }
        }
    }

    & img{
        border-radius: 6px;
        width: 86px;
        height: 86px;
    }

    @media #{$xl}{
        width: 30.2%;
    }


    @media #{$lg}{
        width: 44%;

        &:nth-child(2n){
            margin-right: 0;

            @include rtl{
                margin-left: 0;
            }
        }
        &:nth-child(3n){
            margin-right: 12px;
            @include rtl{
                margin-right: 0;
                margin-left: 12px;
            }
        }
    }


    @media #{$sm}{
        width: 30%;

    }
}
