@use '../utils' as *;

/*----------------------------------------*/
/*  12. FAQ CSS START
/*----------------------------------------*/

.faq{
    &__text{
        position: absolute;
        top: 30%;
        left: 0;
        @include transform(rotate(-90deg) translateY(-102px));
        
        @include rtl{
            left: auto;
            right: -13%;
        }

        & h3{
            color: #d5d5df80;
            font-size: 200px;
            font-weight: 700;
            text-transform: uppercase;
            font-family: var(--tp-ff-space);
            position: relative;            
        }
    }
    &__wrapper{
        & .accordion-item{
            border-color: var(--tp-border-5);
            background-color: var(--tp-common-white);
            &:last-of-type{
                border-bottom: none;
            }
        }
        & .accordion-button{
            padding: 24px 25px 25px;
            font-size: 26px;
            font-weight: 700;
            color: var(--tp-common-black);
            font-family: var(--tp-ff-space);
            position: relative;
            @include transform(inherit);

            @media #{$sm, $xs}{
                font-size: 20px;
            }
            &::before{
                position: absolute;
                content: '';
                left: 0;
                top: 35px;
                width: 8px;
                height: 8px;
                background-color: var(--tp-common-black);
                border-radius: 50%;

                @include rtl{
                    left: auto;
                    right: 0;
                }
            }
            &::after{
                content: '\f068';
                display: none;
            }
            &.collapsed{ 
                &::after{
                    color:  var(--tp-common-black);
                    content: '\2b';
                    @include transform(inherit);
                }

                
            }
            &:not(.collapsed){
                color: var(--tp-common-black);

                &::after{
                    @include transform(rotate(0deg));
                }

                & .accordion-btn{
                    &::before{
                        @include transform(translateX(-50%) rotate(90deg));
                        background-color: var(--tp-theme-1);

                        @include rtl{
                            @include transform(translateX(50%) rotate(90deg));
                        }
                    }
                    &::after{
                        background-color: var(--tp-theme-1);
                    }
                }
            }

            & .accordion-btn{
                position: absolute;
                top: 50%;
                right: 0;
                @include transform(translateY(-50%));
                display: inline-block;
                width: 14px;
                height: 14px;
                @extend %tp-transition;

                @include rtl{
                    right: auto;
                    left: 0;
                }

                &::after{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 2px;
                    left: 0;
                    top: 50%;
                    @include transform(translateY(-50%));
                    background-color: #525258;
                    border-radius: 2px;
                    @extend %tp-transition;

                    @include rtl{
                        right: 0;
                        left: auto;
                    }
                }
                &::before{
                    position: absolute;
                    content: "";
                    width: 2px;
                    height: 100%;
                    left: 50%;
                    top: 0;
                    background-color: #525258;
                    @include transform(translateX(-50%));
                    border-radius: 2px;
                    @extend %tp-transition;

                    @include rtl{
                        right: 50%;
                        left: auto;
                        @include transform(translateX(50%));
                    }
                }
            }

            &:hover{
                color: var(--tp-theme-1);
            }
        }

        & .accordion-body {
            padding: 0 0 15px;
            padding-right: 30px;
            padding-left: 25px;
            @include transform(translateY(-5px));

            @include rtl{
                padding-left: 30px;
                padding-right: 25px;
            }
            & p{
                font-size: 16px;
                color: var(--tp-text-1);
                font-family: var(--tp-ff-inter);
            }
        }
    }
    &__tab{
        @include rtl{
            padding-right: 0;
            padding-left: 200px;
        }

        @media #{$lg}{
            padding-right: 150px;

            @include rtl{
                padding-right: 0;
                padding-left: 150px;
            }
        }

        @media #{$md, $sm, $xs}{
            padding-right: 0;
            margin-bottom: 50px;

            @include rtl{
                padding-left: 0;
            }
        }
        
        & .nav-link{
            padding: 20px 30px;
            text-align: left;
            border-radius: 40px;
            font-family: var(--tp-ff-inter);
            font-weight: 500;
            font-size: 16px;
            color: var(--tp-text-1);
            @extend %tp-transition;

            @include rtl{
                text-align: right;
            }

            & span{
                display: inline-block;
                @include transform(translateY(-2px));
                margin-right: 10px;
                color: var(--tp-theme-7);

                @include rtl{
                    margin-right: 0;
                    margin-left: 10px;
                }
            }
            &.active{
                background-color: #2CAE761A;
                color: var(--tp-theme-7);
            }

            &:hover{
                color: var(--tp-theme-7);
            }
            
        }

        &-content{
            & .accordion-item{
                border: 1px solid #04121F14;
                border-radius: 45px;
                margin-bottom: 15px;
                background-color: var(--tp-common-white);
                &:last-of-type{
                    border-bottom-right-radius: 45px;
                    border-bottom-left-radius: 45px;

                    @include rtl{
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 0;
                        border-bottom-left-radius: 45px;
                        border-bottom-right-radius: 45px;
                    }
                    
    
                }
            }
            & .accordion-button{
                font-family: var(--tp-ff-inter);
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: var(--tp-common-black-11);
                padding: 33px 34px;

                @media #{$xs}{
                    padding-left: 22px;
                    padding-right: 40px;

                    @include rtl{
                        padding-left: 40px;
                        padding-right: 22px;
                    }
                }
                
                &:not(.collapsed){
                    color: var(--tp-common-black-11);
                }

                &.collapsed{
                    
                    &::after{
                        color: var(--tp-common-black-11);
                    }
                }

                &::after{
                    top: 35%;
                    right: 34px;
                    color: var(--tp-common-black-11);

                    @include rtl{
                        right: auto;
                        left: 34px;
                    }

                    @media #{$xs}{
                        right: 20px;

                        @include rtl{
                            right: auto;
                            left: 20px;
                        }
                    }
                }

                &:hover{
                    color: var(--tp-theme-7);

                    &::after{
                        color: var(--tp-theme-7);
                    }
                }
            }

            & .accordion-body{
                padding: 0 34px 29px;

                &.show{
                    box-shadow: 0px 14px 50px rgba(4, 11, 17, 0.12);
                }
                & p{
                    font-family: var(--tp-ff-inter);
                    font-size: 16px;
                    line-height: 1.5;
                    margin-bottom: 0;
                    @include transform(translateY(-5px));
                }
            }
        }

        &-2{
            border-bottom: 1px solid #EAEAEF;
            & .nav-link{
                font-weight: 500;
                font-size: 15px;
                color: var(--tp-text-1);
                padding: 14px 30px;
                background-color: var(--tp-common-white);
                position: relative;
                border-radius: 0;
                &::after{
                    position: absolute;
                    content: '';
                    left: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 0%;
                    margin: auto;
                    background-color: var(--tp-theme-1);
                    height: 2px;
                    @extend %tp-transition;
                }
                & span{
                    margin-right: 7px;

                    @include rtl{
                        margin-right: 0;
                        margin-left: 7px;
                    }
                }

                &.active{
                    color: var(--tp-theme-1);
                    background-color: rgba($color: #F50963, $alpha: .06);
                    &:hover{
                        background-color: rgba($color: #F50963, $alpha: .06);
                    }
                    &::after{
                        width: 100%;
                    }

                }

                &:hover{
                    background-color: var(--tp-grey-16);
                }
            }
        }
    }
    &__shape{
        &-1{
            position: absolute;
            bottom: -8%;
            right: 0;
            z-index: -1;
        }
    }
    &__title{
        font-size: 50px;
        line-height: 1;
        letter-spacing: -0.04em;
        color: var(--tp-common-black);
        margin-bottom: 40px;

        @media #{$lg}{
            font-size: 44px;
        }

        &-pre{
            display: inline-block;
            font-family: var(--tp-ff-space);
            font-weight: 500;
            font-size: 18px;
            line-height: 1.1;
            letter-spacing: -0.02em;
            color: var(--tp-common-black-solid);
            margin-bottom: 12px;
        }

        &-2{
            padding-top: 22px;
            font-size: 30px;
            line-height: 1.13;
            letter-spacing: -0.04em;
            margin-bottom: 30px;

            @media #{$sm, $xs}{
                & br{
                    display: none;
                }
            }
        }
    }
    &__gradient-border{
        position: relative;

        &::after,
        &::before{
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 2px;
            @include tp-gradient((90deg, rgba(3,4,28,1) 0%, rgba(255,255,255,1) 100%));

            @include rtl{
                @include tp-gradient((-90deg, rgba(3,4,28,1) 0%, rgba(255,255,255,1) 100%));
            }
        }

        &::before{
            top: auto;
            bottom: 0;
        }
    }
    &__video{
        position: absolute;
        top: 60px;
        left: 0;
        width: 46%;
        height: calc(100% - 120px);
        @include background();
        z-index: 1;
        
        @include rtl{
            left: auto;
            right: 0;
        }

        @media #{$md, $sm, $xs}{
            position: relative;
            width: 100%;
            height: 560px;
            top: 0;
        }

        &-btn{
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));
            & a{
                display: inline-block;
                width: 90px;
                height: 90px;
                line-height: 90px;
                text-align: center;
                background-color: var(--tp-common-white);
                border-radius: 50%;
                color: var(--tp-theme-1);

                &::after,
                &::before{
                    background: rgba(255, 255, 255, 0.06);
                    border: 1px solid rgba(255, 255, 255, 0.6);
                    box-shadow: 0px 0px 40px rgba(3, 4, 28, 0.06);
                    backdrop-filter: blur(4px);
                }

                & svg{
                    @include transform(translate(2px, -1px));
                }
            }
        }
    }
    &__style-2{
        padding-right: 415px;
        padding-top: 95px; 
        padding-bottom: 100px;
        border-left: 2px solid var(--tp-common-black);

        @include rtl{
            border-left: 0;
            border-right: 2px solid var(--tp-common-black);
            padding-right: 0;
            padding-left: 415px;
        }

        @media #{$xxxxl}{
            padding-right: 230px;

            @include rtl{
                padding-right: 0;
                padding-left: 230px;
            }
        }

        @media #{$xxxl}{
            padding-right: 210px;
            @include rtl{
                padding-right: 0;
                padding-left: 210px;
            }
        }

        @media #{$xxl}{
            padding-right: 120px;
            @include rtl{
                padding-right: 0;
                padding-left: 120px;
            }
        }

        @media #{$xl}{
            padding-right: 60px;
            padding-left: 70px;

            @include rtl{
                padding-right: 70px;
                padding-left: 60px;
            }
        }

        @media #{$lg}{
            padding-right: 20px;
            padding-left: 70px;

            @include rtl{
                padding-right: 70px;
                padding-left: 20px;
            }
        }

        @media #{$md}{
            padding-right: 50px;
            padding-left: 50px;
            margin-top: 50px;
            
        }

        @media #{$sm}{
            padding-right: 30px;
            padding-left: 30px;
            margin-top: 50px;
        }

        @media #{$xs}{
            padding-right: 20px;
            padding-left: 20px;
            margin-top: 50px;
        }

        & .accordion-item{
            border-bottom: 1px solid #E7E7E7;
            padding-top: 25px;
            padding-bottom: 25px;

            &:first-child{
                border-top: 1px solid #E7E7E7;
            }

            &:first-of-type{
                border-radius: 0;
            }
        }
        & .accordion-button{
            font-weight: 500;
            font-size: 18px;
            letter-spacing: -0.02em;
            color: var(--tp-common-black);
            padding-top: 0;
            padding-bottom: 0;

            &::after{
                display: none;
            }

            & .accordion-btn{
                position: absolute;
                top: 50%;
                right: 0;
                @include transform(translateY(-50%));
                display: inline-block;
                width: 14px;
                height: 14px;
                @extend %tp-transition;

                @include rtl{
                    right: auto;
                    left: 0;
                }
                &::after{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 2px;
                    left: 0;
                    top: 50%;
                    @include transform(translateY(-50%));
                    background-color: var(--tp-common-black);
                    border-radius: 2px;
                    @extend %tp-transition;
                }
                &::before{
                    position: absolute;
                    content: "";
                    width: 2px;
                    height: 100%;
                    left: 50%;
                    top: 0;
                    background-color: var(--tp-common-black);
                    @include transform(translateX(-50%));
                    border-radius: 2px;
                    @extend %tp-transition;
                }
            }

            &:not(.collapsed){
                color: var(--tp-theme-1);

                & .accordion-btn{
                    &::before{
                        @include transform(translateX(-50%) rotate(90deg));
                        background-color: var(--tp-theme-1);
                    }
                    &::after{
                        background-color: var(--tp-theme-1);
                    }
                }
            }
        }

        & .accordion-body{
            padding-top: 24px;
            padding-bottom: 0;
            & p{
                font-size: 15px;
                line-height: 1.6;
                color: var(--tp-text-1);
                margin-bottom: 2px;
                padding-right: 50px;

                @include rtl{
                    padding-right: 0;
                    padding-left: 50px;
                }
            }
        }
    }
    &__style-3{
        & .section__title-pre-7{
            font-family: var(--tp-ff-space);
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 0.04em;
            color: var(--tp-common-black);
            text-transform: uppercase;
        }
        & .section__title-7{
            font-family: var(--tp-ff-space);
            font-size: 50px;
            line-height: 1;
            letter-spacing: -0.04em;
            margin-bottom: 11px;
        }

        .faq__tab {
            & .nav-link{
                border-radius: 0;
                background: rgba(255, 255, 255, 0.06);
                border: 1px solid #EAEAEF;
                color: var(--tp-text-1);
                &:not(:last-child){
                    margin-bottom: 8px;
                }

                & span{
                    color: var(--tp-text-1);
                }

                &.active{
                    background-color: var(--tp-theme-1);
                    border-color: var(--tp-theme-1);
                    color: var(--tp-common-white);

                    & span{
                        color: var(--tp-common-white);
                    }
                }
            }

            &-content{
                & .accordion-body{
                    padding-bottom: 34px;
                }
                & .accordion-item{
                    background: var(--tp-common-white);
                    border: 1px solid rgba(4, 18, 31, 0.08);
                    border-radius: 0;
                }
                .accordion-button{
                    font-family: var(--tp-ff-space);
                    font-size: 20px;
                    font-weight: 500;
                    color: var(--tp-common-black-11);
                    

                    &:hover{
                        color: var(--tp-theme-1);

                        &::after{
                            color: var(--tp-theme-1);
                        }
                    }
                }
            }
        }
    }
    &__style-4{

        & .accordion-item{
            border-bottom: 1px solid #E7E7E7;
            padding-top: 27px;
            padding-bottom: 27px;


            &:first-of-type{
                border-radius: 0;
            }

            &:last-of-type{
                border-bottom: 1px solid #E7E7E7;
            }
        }
        & .accordion-button{
            font-weight: 500;
            font-size: 20px;
            letter-spacing: -0.02em;
            color: var(--tp-common-black);
            padding-top: 0;
            padding-bottom: 0;

            &::before{
                width: 6px;
                height: 6px;
                top: 9px;
            }
        }

        & .accordion-body{
            padding-top: 24px;
            padding-bottom: 10px;
            & p{
                font-size: 16px;
                padding-right: 85px;

                @include rtl{
                    padding-right: 0;
                    padding-left: 85px;
                }

                @media #{$md, $sm, $xs}{
                    padding-right: 0;

                    @include rtl{
                        padding-left: 0;
                    }
                }
            }
        }
    }
}
