@use '../utils' as *;

/*----------------------------------------*/
/*  35. LOGIN CSS START
/*----------------------------------------*/

.login{
    &__wrapper{
        padding: 50px 85px 55px;
        background: var(--tp-common-white);
        box-shadow: 0px 30px 60px rgba(3, 4, 28, 0.1);  

        @media #{$xl}{
            padding: 50px 60px 55px;
        }

        @media #{$sm}{
            padding: 50px 40px 55px;
        }

        @media #{$xs}{
            padding: 40px 25px 45px;
        }
    }
    &__title{
        font-size: 34px;
        letter-spacing: -0.04em;
        margin-bottom: 6px;
    }
    &__top{
        & p{
            font-size: 16px;
        }
    }
    &__input{
        position: relative;
        &-wrapper{
            margin-bottom: 14px;
        }
        & input{
            background: #FFFFFF;
            border: 1px solid #EAEAEF;
            padding-left: 55px;
            padding-right: 50px;
            height: 60px;
            line-height: 60px;

            @include rtl{
                padding-left: 50px;
                padding-right: 55px;
            }
            &:focus{
                border-color: var(--tp-theme-1);
            }
        }
        & span{
            position: absolute;
            top: 50%;
            left: 27px;
            @include transform(translateY(calc(-50% - 3px)));

            @include rtl{
                left: auto;
                right: 27px;
            }
        }

        &-item{
            position: relative;
            &:not(:last-child){
                margin-bottom: 20px;
            }
            
            & > span,
            & .login-input-eye{
                position: absolute;
                top: 50%;
                right: 22px;
                @include transform(translateY(calc(-50% - 3px)));

                @include rtl{
                    right: auto;
                    left: 22px;
                }
                &:hover{
                    cursor: pointer;
                }
            }
        }
    }
    &__remember{
        & input[type="checkbox"],
        & input[type="radio"]{
            display: none;

            &:checked{
                & ~ label{
                    &::after{
                        background-color: transparent;
                        border-color: var(--tp-theme-1);
                    }
                    &::before{
                        @include transform(scale(1));
                    }
                }
            }
        }
        & label{
            font-size: 14px;
            position: relative;
            padding-left: 30px;

            @include rtl{
                padding-left: 0;
                padding-right: 30px;
            }
            &::after,
            &::before{
                position: absolute;
                content: '';
                left: 0;
                top: 2px;
                width: 20px;
                height: 20px;
                background-color: var(--tp-common-white);
                border: 1px solid #EAEAEF;
                @include transition(.2s);

                @include rtl{
                    left: auto;
                    right: 0;
                }
            }
            &::before{
                width: 14px;
                height: 14px;
                background-color: var(--tp-theme-1);
                left: 3px;
                top: 5px;
                right: 3px;
                bottom: 3px;
                @include transform(scale(0));

                @include rtl{
                    left: auto;
                    right: 3px;
                }
            }
            &:hover{
                cursor: pointer;
            }
        }
    }
    &__forgot{
        & a{
            font-size: 14px;
            color: var(--tp-common-black);
            text-transform: capitalize;
            &:hover{
                color: var(--tp-theme-1);
            }
        }
    }
    &__btn{
        margin-bottom: 18px;
        & .tp-btn{
            font-size: 18px;
            padding: 17px 30px;

            &:hover{
                background-color: var(--tp-common-black);
            }
        }
    }
    &__social{
        &-header{
            font-weight: 500;
            font-size: 15px;
            letter-spacing: -0.02em;
            color: var(--tp-common-black-solid);
            position: relative;
            display: inline-block;
            text-align: center;
            padding: 0 10px;
            margin-bottom: 25px;

            &::after,
            &::before{
                position: absolute;
                content: '';
                left: 100%;
                top: 10px;
                width: 30px;
                height: 1px;
                background-color: var(--tp-common-black);

                @include rtl{
                    left: auto;
                    right: 100%;
                }
            }
            &::before{
                left: auto;
                right: 100%;
                @include rtl{
                    left: 100%;
                    right: auto;
                }
            }
        }

        &-item{
            & a{
                display: inline-block;
                padding: 11px 25px;
                font-weight: 500;
                font-size: 14px;
                background: var(--tp-common-white);
                border: 1px solid #EAEAEF;
                margin-bottom: 20px;
                margin-right: 5px;

                @include rtl{
                    margin-right: 0;
                    margin-left: 5px;
                }

                @media #{$xs}{
                    width: 100%;
                    margin-right: 0;

                    @include rtl{
                        margin-left: 0;
                    }
                }
                

                &:last-child{
                    margin-right: 0;

                    @include rtl{
                        margin-left: 0;
                    }
                    
                }

                & .login-social-icon{
                    margin-right: 3px;

                    @include rtl{
                        margin-right: 0;
                        margin-left: 3px;
                    }
                    & svg{
                        @include transform(translateY(-2px));
                    }
                }

                &:hover{
                    border-color: var(--tp-theme-1);
                }
            }
        }
    }
    &__register{
        &-now{
            text-align: center;
            & p{
                font-size: 14px;
                color: #525258;
                margin-bottom: 0;
                & a{
                    color: var(--tp-theme-1);
                    font-weight: 500;
                }
            }
        }
    }
    &__shape{
        &-1{
            position: absolute;
            right: 14%;
            bottom: 32%;
            z-index: -1;
            
        }
        &-2{
            position: absolute;
            right: -1%;
            bottom: 14%;
            z-index: -1;
        }
        &-3{
            position: absolute;
            left: 3%;
            top: 35%;
            z-index: -1;

            @media #{$md}{
                left: -4%;
            }
        }
        &-4{
            position: absolute;
            left: 18%;
            bottom: 10%;
            z-index: -1;

            @media #{$md}{
                left: 0%;
            }
        }
        &-5{
            position: absolute;
            right: 20%;
            top: 43%;
            z-index: -1;

            @media #{$md}{
                right: 0%;
            }
        }
        &-6{
            position: absolute;
            right: 22%;
            top: 27%;
            z-index: -1;
            @media #{$md}{
                right: 1%;
            }
        }
    }
}