@use '../utils' as *;

/*----------------------------------------*/
/*  02. LOGO CSS START
/*----------------------------------------*/


/* logo css */
.logo{
    & .logo-dark{
        display: none;
    }
}

.logo{
    &-2{
        padding-top: 8px;
        padding-bottom: 8px;
    }
    &-border{
        position: relative;
        padding: 8px 0;
        &::after{
            position: absolute;
            content: '';
            right: 23%;
            top: 0;
            width: 1px;
            height: 102%;
            background-color: var(--tp-border-1);

            @include rtl{
                right: auto;
                left: 23%;
            }
            @media #{$md, $sm, $xs}{
                display: none;
            }

            @media #{$lg}{
                right: 0;

                @include rtl{
                    right: auto;
                    left: 0;
                }
            }
        }
    }
}
