@use '../utils' as *;

/*----------------------------------------*/
/*  39. HELP CSS START
/*----------------------------------------*/

.help{
    &__title{
        font-size: 70px;
        line-height: 1;
        letter-spacing: -0.04em;
        margin-bottom: 12px;

        @media #{$sm}{
            font-size: 55px;
        }

        @media #{$xs}{
            font-size: 35px;
        }
    }
    &__content{
        & p{
            font-size: 16px;
            margin-bottom: 42px;
        }
    }
    &__input{
        position: relative;

        &-box{
            position: relative;
            margin-bottom: 35px;

            & button{
                position: absolute;
                top: 0;
                right: 0;
                padding: 22px 43px;

                @include rtl{
                    right: auto;
                    left: 0;
                }


                @media #{$xs}{
                    position: relative;
                    margin-top: 15px;
                }

                &:hover{
                    background-color: var(--tp-common-black);

                }
            }
        }
        & input{
            width: 100%;
            height: 70px;
            padding-left: 60px;
            padding-right: 167px;
            background-color: var(--tp-common-white);
            border: 1px solid  var(--tp-common-white);
            box-shadow: -3px 0px 0px var(--tp-theme-1), 0px 1px 2px rgba(3, 4, 28, 0.14);

            @include rtl{
                padding-left: 167px;
                padding-right: 60px;
            }

            @media #{$xs}{
                padding-right: 25px;

                @include rtl{
                    padding-right: 0;
                    padding-left: 25px;
                }
            }
        }
        & span{
            position: absolute;
            top: 50%;
            left: 30px;
            @include transform(translateY(-50%));
            font-size: 18px;
            color: #A0A0B5;

            @include rtl{
                left: auto;
                right: 30px
            }

            & svg{
                @include transform(translateY(-2px));
            }
        }

        
    }
    &__features{
        &-item{
            margin-right: 50px;
            margin-bottom: 30px;

            @include rtl{
                margin-right: 10p0x;
                margin-left: 50px;
            }

            &:last-child{
                margin-right: 0;

                @include rtl{
                    margin-left: 0;
                }
            }
        }
        &-icon{
            & span{
                margin-right: 13px;
                display: inline-block;
                background-color: var(--tp-common-white);
                box-shadow: 0px 2px 4px rgba(3, 4, 28, 0.1);
                border-radius: 30px;
                height: 54px;
                width: 54px;
                line-height: 54px;
                text-align: center;
                color: var(--tp-common-black);

                @include rtl{
                    margin-right: 0;
                    margin-left: 13px;
                }
                & svg{
                    @include transform(translateY(-2px));
                }
            }
        }
        &-content{
            & p{
                font-family: var(--tp-ff-space);
                font-size: 16px;
                line-height: 1;
                margin-bottom: 3px;
            }
        }
        &-title{
            font-weight: 500;
            font-size: 16px;
            line-height: 1;
            color: var(--tp-common-black-solid);
            margin-bottom: 0;
        }
    }
}