@use '../utils' as *;

/*----------------------------------------*/
/*  BREADCRUMB CSS START
/*----------------------------------------*/

.breadcrumb{
    $self : &;
    &__title{
        font-size: 70px;
        line-height: 1;
        letter-spacing: -0.04em;
        color: var(--tp-common-black);
        margin-bottom: 10px;

        @media #{$md}{
            font-size: 60px;
        }

        @media #{$sm}{
            font-size: 55px;
        }

        @media #{$xs}{
            font-size: 40px;
        }

        &-pre{
            display: inline-block;
            font-family: var(--tp-ff-space);
            font-weight: 700;
            font-size: 14px;
            line-height: 1;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: var(--tp-common-white);
            margin-bottom: 27px;
        }

        &-2{
            font-size: 70px;

            @media #{$md}{
                font-size: 50px;
            }
    
            @media #{$sm}{
                font-size: 40px;
            }
    
            @media #{$xs}{
                font-size: 30px;
            }
        }
    }
    &__list{
        & span{
            font-family: var(--tp-ff-space);
            font-weight: 400;
            font-size: 15px;
            line-height: 19px;
            color: var(--tp-common-black);
            display: inline-block;
            &.dvdr{
                font-size: 6px;
                @include transform(translateY(-3px));
                display: inline-block;
                color:  var(--tp-common-black);
                padding: 0 12px;
                
            }
            &:nth-last-child(2){
                &.dvdr{
                    color: #6B6B7B;
                }
            }
            &:last-child{
                color: #6B6B7B;
            }
            & a{
                &:hover{
                    color: var(--tp-theme-1);
                }
            }
        }
    }
    &__content{
        & p{
            font-size: 20px;
            line-height: 1.3;
            color: #343438;
        }
        &-2{
            & p{
                font-size: 18px;
                line-height: 1.56;
                color: #F0F0F5;
            }
        }
    }
    &__overlay{
        position: relative;

        &::after{
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba($color: #03041C, $alpha: .56);
        }
    }
    &__video{
        &-btn{
            display: inline-block;
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 18px;
            color: var(--tp-common-white);
            background-color: var(--tp-common-black);
            border-radius: 50%;

            & svg{
                @include transform(translate(3px,-2px));
            }

            &:hover{
                color: var(--tp-common-white);
                background-color: var(--tp-theme-1);
            }
        }
    }
    &__bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--tp-common-blue);
        opacity: .4;

        &-2{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &-3{
            @include tp-gradient((173.39deg, #6364DB -16.2%, #4D4ECC 101.3%));
        }
        &-4{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            z-index: -1;
        }
        &-overlay{

            &::after{
                position: absolute;
                content: "";
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(3, 4, 28, 0.3);
            }
        }
        &-shape{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            mix-blend-mode: luminosity;
            opacity: .7;
        }
    }
    &__spacing{
        padding-top: 220px;
        padding-bottom: 180px;

        @media #{$sm}{
            padding-top: 170px;
            padding-bottom: 140px;
        }

        @media #{$xs}{
            padding-top: 150px;
            padding-bottom: 120px;
        }

        &-2{
            padding-top: 200px;
            padding-bottom: 235px;
        }
    }
    &__shape{
        &-1{
            position: absolute;
            bottom: 20%;
            left: 8%;
            z-index: -1;
            -moz-animation: tptranslateX 3s ease-in-out 1s forwards infinite alternate;
            -webkit-animation: tptranslateX 3s ease-in-out 1s forwards infinite alternate;
            animation: tptranslateX 3s ease-in-out 1s forwards infinite alternate;
        }
        &-2{
            position: absolute;
            top: 28%;
            right: 7%;
            z-index: -1;
            -moz-animation: tptranslateY2 3s ease-in-out 1s forwards infinite alternate;
            -webkit-animation: tptranslateY2 3s ease-in-out 1s forwards infinite alternate;
            animation: tptranslateY2 3s ease-in-out 1s forwards infinite alternate;
        }

        &-3{
            position: absolute;
            top: 39%;
            left: 3%;
            z-index: -1;
            -moz-animation: tptranslateY2 3s ease-in-out 1s forwards infinite alternate;
            -webkit-animation: tptranslateY2 3s ease-in-out 1s forwards infinite alternate;
            animation: tptranslateY2 3s ease-in-out 1s forwards infinite alternate;
        }
        &-4{
            position: absolute;
            bottom: 10%;
            left: 13%;
            z-index: -1;
            -moz-animation: tprotate 3s ease-in-out 1s forwards infinite alternate;
            -webkit-animation: tprotate 3s ease-in-out 1s forwards infinite alternate;
            animation: tprotate 3s ease-in-out 1s forwards infinite alternate;
        }
        &-5{
            position: absolute;
            bottom: 20%;
            right: 39%;
            z-index: -1;
            -moz-animation: tprotate 6s ease-in-out 1s forwards infinite alternate;
            -webkit-animation: tprotate 6s ease-in-out 1s forwards infinite alternate;
            animation: tprotate 6s ease-in-out 1s forwards infinite alternate;
        }
        &-6{
            position: absolute;
            top: 40%;
            right: 25%;
            z-index: -1;
            -moz-animation: tptranslateX 8s ease-in-out 1s forwards infinite alternate;
            -webkit-animation: tptranslateX 8s ease-in-out 1s forwards infinite alternate;
            animation: tptranslateX 8s ease-in-out 1s forwards infinite alternate;
        }
        &-7{
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
        }
    }
    &__border{
        border-bottom: 1px solid var(--tp-border-5);
    }
    &__style-2{
        #{$self}{
            &__title{
                display: none;
            }
        }
    }
    &__style-3{
        #{$self}{
            &__list{
                margin-bottom: 5px;
                & span{
                    font-weight: 500;
                    font-size: 16px;
                    color: var(--tp-text-1);
                    
                    &.dvdr{
                        font-size: 6px;
                        color: var(--tp-text-1);                        
                    }
                }
            }
            &__title{
                margin-bottom: 18px;
            }
        }
    }
    &__style-4{
        #{$self}{
            &__list{
                margin-bottom: 6px;
                & span{
                    font-weight: 500;
                    font-size: 16px;
                    color: var(--tp-text-1);
                    
                    &.dvdr{
                        font-size: 6px;
                        color: var(--tp-text-1);                        
                    }
                }
            }
            &__title{
                font-size: 90px;

                @media #{$sm}{
                    font-size: 65px;
                }

                @media #{$xs}{
                    font-size: 40px;
                }
            }
        }
    }
    &__style-5{
        #{$self}{
            &__list{
                margin-bottom: 20px;
                display: none;
                & span{
                    font-weight: 500;
                    font-size: 16px;
                    color: var(--tp-common-white);
                    
                    &.dvdr{
                        font-size: 6px;
                        color: var(--tp-common-white);                        
                    }
                }
            }
            &__title{
                font-size: 70px;
                color: #F0F0F5;

                @media #{$sm}{
                    font-size: 65px;
                }

                @media #{$xs}{
                    font-size: 40px;
                }
            }
        }
    }
    &__style-6{
        #{$self}{
            &__list{
                margin-bottom: 20px;
                & span{
                    font-weight: 500;
                    font-size: 16px;
                    color: var(--tp-common-white);
                    
                    &.dvdr{
                        font-size: 6px;
                        color: var(--tp-common-white);                        
                    }
                    &:nth-last-child(2){
                        &.dvdr{
                            color: #FFFFFFB2;
                        }
                    }
                    &:last-child{
                        color: #FFFFFFB2;
                    }
                }
            }
            &__title{
                font-size: 70px;
                color: #F0F0F5;

                @media #{$md}{
                    font-size: 64px;
                }

                @media #{$sm}{
                    font-size: 50px;
                }

                @media #{$xs}{
                    font-size: 40px;
                }
            }
        }
    }
    &__style-7{
        #{$self}{
            &__list{
                margin-bottom: 20px;
                & span{
                    font-weight: 500;
                    font-size: 16px;
                    color: var(--tp-common-white);
                    
                    &.dvdr{
                        font-size: 6px;
                        color: var(--tp-common-white);                        
                    }
                    &:nth-last-child(2){
                        &.dvdr{
                            color: #FFFFFFCC;
                        }
                    }
                    &:last-child{
                        color: #FFFFFFCC;
                    }
                }
            }
            &__title{
                color: #FFFFFF;

                @media #{$md}{
                    font-size: 64px;
                }

                @media #{$sm}{
                    font-size: 50px;
                }

                @media #{$xs}{
                    font-size: 40px;
                }
            }
        }
    }
    &__style-8{
        #{$self}{
            &__list{
                margin-bottom: 20px;
                & span{
                    font-weight: 500;
                    font-size: 16px;
                    color: var(--tp-common-black);
                    
                    &.dvdr{
                        font-size: 6px;
                        color: #86868B;                        
                    }
                    &:nth-last-child(2){
                        &.dvdr{
                            color: #86868B;
                        }
                    }
                    &:last-child{
                        color: var(--tp-text-1);
                    }
                }
            }
            &__title{
                color: var(--tp-common-black);
                font-size: 50px;
                margin-bottom: 13px;

                @media #{$xs}{
                    font-size: 40px;
                }
            }
        }
    }
    &__style-9{
        #{$self}{
            &__list{
                margin-bottom: 20px;

                &.has-icon{
                    position: relative;
                    padding-left: 24px;

                    @include rtl{
                        padding-left: 0;
                        padding-right: 24px;
                    }

                    & .breadcrumb-icon{
                        position: absolute;
                        left: 0;
                        top: 4px;
                        & svg{
                            @include transform(translateY(-3px));
                        }

                        @include rtl{
                            left: auto;
                            right: 0;
                        }
                    }
                }
                & span{
                    font-weight: 400;
                    font-size: 14px;
                    color: var(--tp-text-1);
                    font-family: var(--tp-ff-roboto);
                    display: inline-block;
                    
                    &.dvdr{
                        @include transform(translateY(0px));
                        color: var(--tp-text-1);   
                        padding: 0 8px;              
                    }
                    &:nth-last-child(2){
                        &.dvdr{
                            color: var(--tp-text-1);
                        }
                    }
                    &:last-child{
                        color: var(--tp-text-1);
                    }
                }
            }
            &__title{
                color: var(--tp-common-black);
                font-size: 50px;
                margin-bottom: 13px;

                @media #{$xs}{
                    font-size: 40px;
                }
            }
        }
    }
    &__style-10{
        #{$self}{
            &__list{
                margin-bottom: 20px;

                &.has-icon{
                    position: relative;
                    padding-left: 24px;

                    & .breadcrumb-icon{
                        position: absolute;
                        left: 0;
                        top: 4px;
                        & svg{
                            @include transform(translateY(-3px));
                        }
                    }
                }
                & span{
                    font-weight: 400;
                    font-size: 14px;
                    color: var(--tp-common-white);
                    
                    &.dvdr{
                        @include transform(translateY(-3px));
                        color: var(--tp-common-white);       
                        font-size: 6px;
                        padding: 0 12px;     
                    }
                    &:nth-last-child(2){
                        &.dvdr{
                            color: var(--tp-common-white);
                        }
                    }
                    &:last-child{
                        color: var(--tp-common-white);
                    }
                }
            }
            &__title{
                color: var(--tp-common-white);
                margin-bottom: 27px;
                font-size: 70px;

                @media #{$xs}{
                    font-size: 40px;
                }
            }
        }
    }
}