@use '../utils' as *;

/*----------------------------------------*/
/*  21. INSTAGRAM CSS START
/*----------------------------------------*/

.instagram{
    $self : &;
    &__item{
        position: relative;
        overflow: hidden;
        z-index: 1;

        &::after{
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: var(--tp-common-black);
            visibility: hidden;
            opacity: 0;
            @extend %tp-transition;
        }
        &:hover{
            &::after{
                opacity: .6;
                visibility: visible;
            }

            #{$self}{
                &__btn{
                    top: 50%;
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
    &__btn{
        position: absolute;
        top: 60%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        visibility: hidden;
        opacity: 0;
        @extend %tp-transition;
        z-index: 1;
    }
}

.tp-instagram-btn{
    font-size: 24px;
    color: var(--tp-common-white);
    display: inline-block;
    width: 80px;
    height: 80px;
    text-align: center;
    line-height: 76px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.2);
    background-color: transparent;

    &:hover{
        color: var(--tp-common-black);
        border-color: transparent;
        background-color: var(--tp-common-white);
    }
}