@use '../utils' as *;

/*----------------------------------------*/
/*  14. TESTIMONIAL CSS START
/*----------------------------------------*/

.testimonial{
    $self: &;
    &__wrapper{
        & .section__title-wrapper{
            padding-left: 125px;
            padding-right: 125px;

            @media #{$xl}{
                padding-left: 70px;
                padding-right: 70px;
            }

            @media #{$lg}{
                padding-left: 60px;
                padding-right: 60px;
            }

            @media #{$md, $sm, $xs}{
                padding-left: 0;
                padding-right: 0;
            }
        }
        & .section__title-pre.section__title-pre-1-2{
            letter-spacing: 0;
            font-weight: 400;
        }

        &-8{
            @media #{$lg}{
                padding-left: 150px;
                padding-right: 150px;
            }
            @media #{$md}{
                padding-left: 80px;
                padding-right: 80px;
            }
            @media #{$sm, $xs}{
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }
    &__avater{
        @media #{$md, $sm, $xs}{
            margin-bottom: 25px;
        }
        &-8{
            padding-bottom: 45px;
            padding-top: 45px;
            &.swiper-slide-active{
                & img{
                    @include transform(scale(1.42));
                }
            }
        }

        &-thumb{
            @include rtl{
                margin-right: 0;
                margin-left: 15px;
            }
            & img{
                width: 70px;
                height: 70px;
                border-radius: 50%;
            }
            &-3{
                & img{
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    margin-right: 25px;
                    mix-blend-mode: luminosity;

                    @include rtl{
                        margin-right: 0;
                        margin-left: 25px;
                    }

                    @media #{$xs}{
                        margin-right: 0;
                        margin-bottom: 25px;

                        @include rtl{
                            margin-left: 0;
                        }
                    }
                }
            }
            &-4{
                & img{
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    margin-bottom: 25px;
                    margin-right: 20px;

                    @include rtl{
                        margin-right: 0;
                        margin-left: 20px;
                    }

                    @media #{$xs}{
                        width: 100px;
                        height: 100px;
                        margin-right: 0;
                        margin-bottom: 25px;

                        @include rtl{
                            margin-left: 0;
                        }
                    }
                }
            }
            &-5{
                margin-bottom: 30px;
                & img{
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;

                    @media #{$xs}{
                        width: 100px;
                        height: 100px;
                        margin-bottom: 25px;
                    }
                }
            }
            &-6{
                & img{
                    width: 44px;
                    height: 44px;
                    border-radius: 50%;
                    margin-right: 10px;

                    @include rtl{
                        margin-right: 0;
                        margin-left: 10px;
                    }
                    @media #{$xs}{
                        margin-bottom: 25px;
                    }
                }
            }
            &-7{
                & img{
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    margin-right: 15px;

                    @include rtl{
                        margin-right: 0;
                        margin-left: 15px;
                    }
                    @media #{$xs}{
                        margin-bottom: 25px;
                    }
                }
            }
            &-8{
                & img{
                    width: 64px;
                    height: 64px;
                    border-radius: 50%;
                }
            }
            &-9{
                & img{
                    width: 54px;
                    height: 54px;
                    border-radius: 50%;
                }
            }
        }
        &-title{
            font-size: 20px;
            color: var(--tp-common-white);
            font-family: var(--tp-ff-inter);
            margin-bottom: 0;

            &-2{
                font-size: 17px;
                font-weight: 700;
                color: var(--tp-common-white);
                font-family: var(--tp-ff-inter);
                margin-bottom: 1px;
                line-height: 1;
            }

            &-3{
                font-size: 20px;
                font-weight: 700;
                color: var(--tp-common-white);
                font-family: var(--tp-ff-space);
                margin-bottom: 0;
            }

            &-4{
                color: var(--tp-common-white);
                font-family: var(--tp-ff-space);
                font-size: 24px;
                margin-bottom: 0;
                line-height: 1;
            }

            &-5{
                color: var(--tp-common-black);
                font-family: var(--tp-ff-space);
                font-size: 18px;
                margin-bottom: 0;
                line-height: 1;
                letter-spacing: -0.02em;
            }

            &-6{
                color: var(--tp-common-black-9);
                font-size: 16px;
                margin-bottom: 0;
                line-height: 1;
                font-weight: 600;
                font-family: var(--tp-ff-roboto);
            }

            &-7{
                font-family: var(--tp-ff-inter);
                font-weight: 600;
                font-size: 17px;
                line-height: 1;
                color: var(--tp-common-black-11);
                margin-bottom: 0;
            }

            &-8{
                font-family: var(--tp-ff-space);
                font-weight: 700;
                font-size: 18px;
                line-height: 1;
                letter-spacing: -0.02em;
                color: var(--tp-common-white);
                margin-bottom: 0;
            }

            &-9{
                font-family: var(--tp-ff-syne);
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                color: var(--tp-common-black);
                margin-bottom: 0;
            }

            &-10{
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.02em;
                color: var(--tp-common-white);
                margin-bottom: 0;
                margin-right: 5px;

                @include rtl{
                    margin-right: 0;
                    margin-left: 5px;
                }
            }
        }
        &-designation{
            font-size: 12px;
            font-weight: 400;
            color: var(--tp-common-white);
            text-transform: uppercase;
            font-family: var(--tp-ff-space);

            &-2{
                font-size: 14px;
                color: var(--tp-common-white);
                opacity: .8;
                line-height: 1;
            }

            &-3{
                font-size: 16px;
                color: var(--tp-common-white);
                font-family: var(--tp-ff-space);
            }

            &-4{
                font-family: var(--tp-ff-inter);
                font-size: 14px;
                color: rgba(255, 255, 255, 0.6);
                line-height: 1;
            }

            &-5{
                font-family: var(--tp-ff-inter);
                font-size: 15px;
                color: #717388;
                line-height: 1;
            }

            &-6{
                font-size: 13px;
                color: var(--tp-text-19);
                line-height: 1;
                font-family: var(--tp-ff-roboto);
            }

            &-7{
                font-family: var(--tp-ff-inter);
                font-size: 14px;
                line-height: 1;
            }

            &-8{
                font-family: var(--tp-ff-space);
                font-weight: 500;
                font-size: 12px;
                line-height: 10px;
                text-align: center;
                letter-spacing: 0.11em;
                text-transform: uppercase;
                color: var(--tp-common-white);
                opacity: 0.7;
            }
            &-9{
                font-family: var(--tp-ff-syne);
                font-weight: 400;
                font-size: 14px;
                line-height: 1.6;
                color: #525258;
            }

            &-10{
                font-family: var(--tp-ff-space);
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.02em;
                color: var(--tp-common-white);
            }
        }
        &-date{

            @media #{$xs}{
                margin-top: 10px;
            }
            & span{
                font-family: var(--tp-ff-space);
                font-weight: 400;
                font-size: 14px;
                color: var(--tp-common-white);
                position: relative;
                padding-left: 13px;
                margin-left: 13px;

                @include rtl{
                    padding-left: 0;
                    margin-left: 0;
                    padding-right: 13px;
                    margin-right: 13px;
                }

                @media #{$xs}{
                    padding-left: 0;
                    margin-left: 0;

                    @include rtl{
                        padding-right: 0;
                        margin-right: 0;
                    }
                }
                &::after{
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 50%;
                    @include transform(translateY(-50%));
                    width: 1px;
                    height: 20px;
                    background-color: rgba($color: #fff, $alpha: .2);

                    @include rtl{
                        left: auto;
                        right: 0;
                    }

                    @media #{$xs}{
                        display: none;
                    }
                }
            }
        }
    }
    &__content{
        position: relative;

        &::after{
            @include tp-afbf-default($bg-color: var(--tp-border-1), $height : calc(100% + 40px), $top: -20px, $width: 1px, $left: -50px, $x:0, $y:0);

            @include rtl{
                left: auto;
                right: -50px;
            }

            @media #{$lg, $md, $sm, $xs}{
                display: none;
            }
        }
        & p{
            font-weight: 300;
            font-size: 20px;
            color: var(--tp-common-white);
            line-height: 1.4;
            margin-bottom: 0;
        }

        &-2{
            & p{
                font-size: 22px;
                font-family: var(--tp-ff-oswald);
                font-weight: 300;
                color: var(--tp-common-white);
                line-height: 1.6;
                margin-bottom: 33px;
            }
        }

        &-3{
            & h4{
                font-size: 22px;
                color: var(--tp-common-white);
                font-family: var(--tp-ff-space);
            }
            & p{
                font-size: 16px;
                font-weight: 400;
                color: rgba($color: #fff, $alpha: .7);
                line-height: 1.8;
                margin-bottom: 0;
            }
        }

        &-4{
            & p{
                font-size: 20px;
                line-height: 1.5 ;
                color: var(--tp-common-white);
                margin-bottom: 22px;
            }
        }

        &-5{
            & p{
                font-family: var(--tp-ff-space);
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                letter-spacing: -0.02em;
                color: var(--tp-common-black);
                margin-bottom: 35px;
            }
        }

        &-6{
            & p{
                color: var(--tp-common-black-9);
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 30px;
                font-family: var(--tp-ff-roboto);
            }
        }

        &-7{
            margin-bottom: 65px;
            & p{
                font-size: 16px;
                line-height: 1.6;
            }
        }

        &-8{
            margin-bottom: 3px;
            & p{
                font-family: var(--tp-ff-space);
                font-size: 24px;
                line-height: 1.5;
                text-align: center;
                letter-spacing: -0.01em;
                color: var(--tp-common-white);
                margin-bottom: 37px;
            }
        }

        &-9{
            margin-bottom: 3px;

            & #{$self}-heading{
                font-family: var(--tp-ff-syne);
                font-weight: 700;
                font-size: 20px;
                line-height: 1;
                color: var(--tp-common-black);
                margin-bottom: 15px;
            }
            & p{
                font-family: var(--tp-ff-syne);
                font-weight: 400;
                font-size: 20px;
                line-height: 1.6;
                color: var(--tp-text-1);
                margin-bottom: 23px;

                @media #{$md, $sm, $xs}{
                    & br{
                        display: none;
                    }
                }
            }
        }

        &-10{
            & p{
                font-family: var(--tp-ff-space);
                font-weight: 400;
                font-size: 24px;
                line-height: 1.33;
                letter-spacing: -0.02em;
                color: var(--tp-common-white);
                margin-bottom: 15px;
            }
        }
  
    }
    &__slider{

        & #{$self}-slider-dot{
            margin-right: 206px;
            text-align: center;
            width: auto;
            @include rtl{
                margin-right: 0;
                margin-left: 206px;
            }

            @media #{$xl, $lg}{
                margin-right: 140px;

                @include rtl{
                    margin-right: 0;
                    margin-left: 140px;
                }
            }

            @media #{$md, $sm, $xs}{
                margin-right: 0;
                @include rtl{
                   margin-left: 0;
                }
            }
            
        }
        &-nav{
            margin-bottom: 25px;

            & .slick-current.slick-active{
                
                &::after{
                    visibility: visible;
                    opacity: 1;
                }
                
                .tp-border-loader svg circle {
                    &:last-child{
                        animation: tp-border-loader 8s linear infinite;
                    }

                }

            }
            &-4{
                & button{
                    width: 40px;
                    height: 180px;
                    line-height: 176px;
                    text-align: center;
                    border: 2px solid #FFFFFF1A;
                    color: var(--tp-common-white);
                    
                    @media #{$md, $sm, $xs}{
                        margin-top: 10px;
                        width: 50%;
                        height: 40px;
                        line-height: 36px;
                    }

                    &:hover{
                        color: var(--tp-common-white);
                        background-color: var(--tp-theme-4);
                        border-color: var(--tp-theme-4);
                    }

                    &#{$self}-4-button-prev{
                        margin-bottom: 10px;
                        @media #{$md, $sm, $xs}{
                            margin-right: 5px;

                            @include rtl{
                                margin-right: 0;
                                margin-left: 5px;
                            }
                        }
                    }

                    &#{$self}-4-button-next{
                        @media #{$md, $sm, $xs}{
                            margin-left: 5px;

                            @include rtl{
                                margin-left: 0;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }
        &-arrow{
            &-2{
                @media #{$md, $sm, $xs}{
                    display: none;
                }
                & button{
                    width: 60px;
                    height: 60px;
                    line-height: 56px;
                    text-align: center;
                    color: var(--tp-common-white);
                    border: 2px solid rgba($color: #fff, $alpha: .2);
                    border-radius: 50%;
                    font-size: 16px;
                    position: absolute;
                    top: 50%;
                    left: 70px;
                    z-index: 1;
                    @include transform(translateY(-50%));

                    @include rtl{
                        left: auto;
                        right: 70px;
                    }

                    @media #{$lg}{
                        left: 40px;

                        @include rtl{
                            left: auto;
                            right: 40px;
                        }
                    }

                    &#{$self}-2-button-next{
                        left: auto;
                        right: 70px;

                        @include rtl{
                            left: 70px;
                            right: auto;
                        }
                        @media #{$lg}{
                            right: 40px;

                            @include rtl{
                                right: auto;
                                left: 40px;
                            }
                        }
                    }
                    &:hover{
                        border-color: var(--tp-common-white);
                        background-color: var(--tp-common-white);
                        color: var(--tp-common-black);
                    }
                }
            }

            &-6{
                & button{
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    line-height: 38px;
                    text-align: center;
                    font-size: 14px;
                    border: 1px solid rgba(13, 18, 30, 0.2);
                    color: var(--tp-common-black-10);
                    border-radius: 50%;

                    &:hover{
                        color: var(--tp-common-white);
                        background-color: var(--tp-theme-6);
                        border-color: var(--tp-theme-6);
                    }

                    &#{$self}-6-button-next{
                        margin-left: 3px;

                        @include rtl{
                            margin-left: 0;
                            margin-right: 3px;
                        }
                    }
                }
            }

            &-8{
                & button{
                    display: inline-block;
                    width: 60px;
                    height: 60px;
                    line-height: 58px;
                    text-align: center;
                    font-size: 18px;
                    border: 1.5px solid rgba($color: #fff, $alpha: .3);
                    color: var(--tp-common-white);
                    position: absolute;
                    top: 50%;
                    left: 0;

                    @include rtl{
                        left: auto;
                        right: 0;
                    }

                    &#{$self}-8-button-next{
                        left: auto;
                        right: 0;

                        @include rtl{
                            left: 0;
                            right: auto;
                        }
                    }

                    &:hover{
                        color: var(--tp-common-white);
                        background-color: var(--tp-theme-8);
                        border-color: var(--tp-theme-8);
                    }
                }
            }

            &-9{
                & button{
                    position: absolute;
                    top: 50%;
                    left: 0;
                    @include transform(translateY(-50%));
                    z-index: 1;
                    font-family: var(--tp-ff-syne);
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 12px;
                    text-transform: uppercase;
                    color: var(--tp-common-black);
                    padding-right: 30px;

                    @include rtl{
                        left: auto;
                        right: 0;
                        padding-right: 0;
                        padding-left: 30px;
                    }

                    &::after{
                        position: absolute;
                        content: '';
                        right: 0;
                        top: 50%;
                        @include transform(translateY(-50%));
                        width: 1px;
                        height: 100px;
                        background-color: #EAEAEF;

                        @include rtl{
                            right: auto;
                            left: 0;
                        }
                    }

                    &:hover{
                        color: #FA6490;
                    }
                    
                    & span{
                        position: relative;
                    }

                    & i,
                    & svg{
                        margin-right: 4px;

                        @include rtl{
                            margin-right: 0;
                            margin-left: 4px;
                        }
                    }

                    & svg{
                        @include transform(translateY(-1px));
                    }
                    

                    &#{$self}-9-button-next{
                        padding-left: 30px;
                        padding-right: 0;
                        left: auto;
                        right: 0;

                        @include rtl{
                            padding-left: 0;
                            padding-right: 30px;
                            left: 0;
                            right: auto;
                        }
                        &::after{
                            left: 0;
                            right: auto;

                            @include rtl{
                                left: auto;
                                right: 0;
                            }
                        }
                        & i,
                        & svg{
                            margin-right: 0;
                            margin-left: 4px;

                            @include rtl{
                                margin-right: 4px;
                                margin-left: 0;
                            }
                        }
                    }
                }
            }

            &-15{
                & button{
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    line-height: 36px;
                    text-align: center;
                    font-size: 14px;
                    border: 2px solid #E7E7EF;
                    color: var(--tp-common-black);

                    &:hover{
                        color: var(--tp-common-white);
                        background-color: var(--tp-theme-1);
                        border-color: var(--tp-theme-1);
                    }

                    &#{$self}-15-button-next{
                        margin-left: 3px;

                        @include rtl{
                            margin-left: 0;
                            margin-right: 3px;
                        }
                    }
                }
            }
        }
        &-thumb-nav{
            position: relative;
            background-color: var(--tp-theme-2);
            &:hover{
                cursor: pointer;
            }

            & img{
                width: 70px !important;
                height: 70px;
                border: 1px solid transparent;
                border-radius: 50%;
                padding: 5px;
            }

            & .tp-border-loader{
                position: absolute;
                top: 0;
                left: 0;

                @include rtl{
                    left: auto;
                    right: 0;
                }
                & svg{
                    width: 70px;
                    height: 70px;
                }
            }
        }

        &-5{
            & .slick-list{
                overflow: visible;
                margin: 0 -15px;
            }

            & .slick-slide{
                & > div{
                    margin: 0 15px;
                }
            }
        }

        &-6{
            margin-right: -410px;
            @include rtl{
                margin-right: 0;
                margin-left: -410px;
            }
            @media #{$xs}{
                margin-right: 0;

                @include rtl{
                    margin-left: 0;
                }
            }
        }

        &-8{
            &-thumb{
                width: 270px;
                margin: auto;
            }
        }

        &-9{
            padding-left: 250px;
            padding-right: 250px;

            @media #{$xl}{
                padding-left: 200px;
                padding-right: 200px;
            }

            @media #{$lg}{
                padding-left: 150px;
                padding-right: 150px;
            }

            @media #{$md}{
                padding-left: 140px;
                padding-right: 140px;
            }

            @media #{$sm, $xs}{
                padding-left: 0;
                padding-right: 0;
            }

            & .slick-current.slick-active{
                .tp-border-loader svg circle {
                    &:last-child{
                        animation: tp-border-loader 8s linear infinite;
                    }

                }
            }
            
            &-thumb-nav{
                position: relative;
                &:hover{
                    cursor: pointer;
                }

                & .tp-border-loader{
                    position: absolute;
                    top: 0;
                    left: 0;
                    & svg{
                        width: 62px;
                        height: 62px;

                        & circle{
                            stroke: var(--tp-common-black);
                        }
                        
                    }
                }

                & img{
                    width: 62px;
                    height: 62px;
                    border-radius: 50%;
                    padding: 4px;

                    @include rtl{
                        margin-right: 12px;
                    }
                }
    
                
            }
        }

        &-14{
            padding: 40px 60px 90px;
            border: 1px solid #EAEAEF;

            @media #{$lg, $md}{
                padding-left: 40px;
                padding-right: 40px;
            }
            @media #{$sm, $xs}{
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        &-15{
            margin-right: -770px;

            @include rtl{
                margin-right: 0;
                margin-left: -770px;
            }

            @media #{$xxl}{
                margin-right: -400px;
                @include rtl{
                    margin-right: 0;
                    margin-left: -400px;
                }
            }

            @media #{$xl}{
                margin-right: -600px;
                @include rtl{
                    margin-right: 0;
                    margin-left: -600px;
                }
            }

            @media #{$lg}{
                margin-right: -300px;
                @include rtl{
                    margin-right: 0;
                    margin-left: -300px;
                }
            }

            @media #{$md, $sm, $xs}{
                margin-right: 0;
                @include rtl{
                    margin-left: 0;
                }
            }
        }
    }
    &__item{
        &-2{
            padding: 0 70px;
            text-align: center;

            @media #{$md}{
                padding: 0;
            }

            @media #{$sm, $xs}{
                padding: 0 35px;
            }
        }
        &-3{
            padding: 60px 60px 50px;
            background: #22264C;
            
            @media #{$xs}{
                padding: 60px 35px 50px;
            }
        }
        &-4{
            padding: 40px 60px;
            background: rgba(255, 255, 255, 0.04);
            border: 1px solid rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(20px);

            @media #{$xs}{
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        &-5{
            position: relative;
            padding: 60px 40px 50px;
            background: var(--tp-common-white);
            box-shadow: 0px 30px 40px rgba(2, 45, 62, 0.08);
            border-radius: 10px;
            @extend %tp-transition;
            @media #{$xs}{
                padding: 40px 20px 20px;
            }
           
        }
        &-6{
            padding: 35px 30px 40px 30px;
            background: var(--tp-common-white);
            box-shadow: 0px 1px 2px rgba(13, 18, 30, 0.08);
            position: relative;

            &::after{
                position: absolute;
                content: '';
                left: auto;
                right: 0;
                top: 0;
                width: 0;
                height: 2px;
                background-color: var(--tp-theme-6);
                @extend %tp-transition;

                @include rtl{
                    left: 0;
                    right: auto;
                }
            }
            &:hover{
                box-shadow: 0px 30px 30px rgba(13, 18, 30, 0.08);

                &::after{
                    width: 100%;
                    right: auto;
                    left: 0;

                    @include rtl{
                        left: auto;
                        right: 0;
                    }
                }
            }
        }
        &-7{
            border-radius: 40px;
            padding: 50px 35px 45px;

            @media #{$md}{
                padding-left: 25px;
                padding-right: 25px;
            }

            @media #{$xs}{
                padding-left: 20px;
                padding-right: 20px;
            }
            &:hover{
                box-shadow: 0px 30px 50px rgba(4, 40, 34, 0.1);
            }
        }
        &-wrapper{
            &-3{
                @media #{$md, $sm, $xs}{
                    margin-top: 30px;
                }
            }
        }
    }
    &__inner{
        &-2{
            background-blend-mode: luminosity;
            padding-top: 90px;
            padding-bottom: 72px;
            box-shadow: 0px 40px 50px rgba(5, 31, 31, 0.14);
        }

        &-10{
            padding: 60px 100px 40px;
            background-color: #159BFC;
            border-radius: 20px;

            @media #{$sm}{
                padding: 60px 50px 40px;
            }

            @media #{$xs}{
                padding: 60px 20px 40px;
            }
        }
    }
    &__bg{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 200px;
        background-color: var(--tp-grey-7);
    }
    &__shape{
        &-quote{
            position: absolute;
            bottom: -7%;
            right: 6%;

            @include rtl{
                left: 6%;
                right: auto;
            }

            &-8{
                margin-bottom: 40px;
            }

            &-9{
                margin-bottom: 35px;
                & img{
                    display: inline-block !important;
                }
            }
        }
        &-1{
            position: absolute;
            top: -4%;
            left: 14%;
            z-index: -1;

            @include rtl{
                left: auto;
                right: 14%;
            }
            @media #{$xxxxl, $xxxl, $xxl, $xl}{
                left: 0;

                @include rtl{
                    right: 0;
                }
            }

            @media #{$xxl, $xl, $lg, $md, $sm, $xs}{
                left: 0;
                width: 100%;

                @include rtl{
                    right: 0;
                }
            }
        }

        &-2{
            position: absolute;
            top: 15%;
            right: 40px;

            @include rtl{
                right: auto;
                left: 40px;
            }
        }

        &-3{
            position: absolute;
            top: 22%;
            right: 8%;
            z-index: -1;
            -webkit-animation: tprotate 3s infinite  alternate;
            animation: tprotate 3s infinite  alternate;

            @include rtl{
                right: auto;
                left: 8%;
            }
        }
        &-4{
            position: absolute;
            bottom: 23%;
            right: 9%;
            z-index: -1;
            -webkit-animation: tpleftright 1s infinite  alternate;
            animation: tpleftright 1s infinite  alternate;

            @include rtl{
                right: auto;
                left: 9%;
            }
        }

        &-5{
            position: absolute;
            top: 20%;
            right: 20%;
            z-index: -1;
            -webkit-animation: tpupdown 1s infinite  alternate;
            animation: tpupdown 1s infinite  alternate;

            @include rtl{
                right: auto;
                left: 20%;
            }
        }

        &-6{
            position: absolute;
            top: 16%;
            left: 12%;
            z-index: -1;
            -webkit-animation: tpupdown 1s infinite  alternate;
            animation: tpupdown 1s infinite  alternate;

            @include rtl{
                right: 12%;
                left: auto;
            }
        }

        &-7{
            position: absolute;
            bottom: 33%;
            left: 16%;
            z-index: -1;
            -webkit-animation: tprotate 1s infinite  alternate;
            animation: tprotate 1s infinite  alternate;

            @include rtl{
                right: 16%;
                left: auto;
            }
        }

        &-8{
            position: absolute;
            top: -5%;
            left: 17%;
            z-index: -1;

            @include rtl{
                right: 17%;
                left: auto;
            }

            @media #{$xs, $sm, $md}{
                left: 0;
                width: 100%;
                object-fit: cover;

                @include rtl{
                    right: 0;
                    left: auto;
                }
            }
        }

        &-9{
            position: absolute;
            left: 9%;
            bottom: 11%;
            -moz-animation: tptranslateY2 3s ease-in-out 1s forwards infinite alternate;
            -webkit-animation: tptranslateY2 3s ease-in-out 1s forwards infinite alternate;
            animation: tptranslateY2 3s ease-in-out 1s forwards infinite alternate;

            @include rtl{
                right: 9%;
                left: auto;
            }
        }

        &-10{
            position: absolute;
            right: 9%;
            bottom: 19%;
            -moz-animation: tptranslateX 3s ease-in-out 1s forwards infinite alternate;
            -webkit-animation: tptranslateX 3s ease-in-out 1s forwards infinite alternate;
            animation: tptranslateX 3s ease-in-out 1s forwards infinite alternate;

            @include rtl{
                right: auto;
                left: 9%;
            }
        }

        &-11{
            position: absolute;
            mix-blend-mode: luminosity;
            opacity: .04;
            bottom: -41%;
            left: -11%;
            z-index: -1;

            @include rtl{
                right: -11%;
                left: auto;
            }
        }
    }
    &__rating{
        margin-bottom: 5px;
        & a{
            font-size: 16px;
            color: var(--tp-common-yellow);
        }
        &-5{
            margin-bottom: 0;
            line-height: 1;
            & a{
                font-size: 11px;
            }
        }

        &-7{
            margin-bottom: 30px;
            line-height: 1;
            & a{
                font-size: 16px;
            }
        }

        &-content{
            & p{
                font-weight: 500;
                font-size: 14px;
                color: var(--tp-common-black);
                margin-bottom: 0;
            }
        }

        &-logo{
            @include rtl{
                margin-right: 0;
                margin-left: 15px;
            }
        }
    }
    &__icon{
        margin-bottom: 24px;

        & span{
            color: var(--tp-common-white);
            font-size: 40px;
        }

        &-10{
            margin-bottom: 17px;
            & span{
                display: inline-block;
                width: 70px;
                height: 70px;
                line-height: 66px;
                text-align: center;
                border: 2px solid rgba($color: #fff, $alpha: .12);
                border-radius: 50%;
                font-size: 24px;
                color: var(--tp-common-white);
            }
        }
    }
    &__gradient-bg{
        @include tp-gradient((180deg, #F8F8F8 0%, rgba(248, 248, 248, 0) 100%));

        &-2{
            position: absolute;
            left: 150px;
            top: 0;
            width: calc(100% - 300px);
            height: calc(100% + 20px);
            @include tp-gradient((100.21deg, rgba(255, 63, 3, 0.1) -2.54%, rgba(123, 214, 80, 0.1) 50.09%, rgba(129, 108, 236, 0.1) 101.82%));

            @include rtl{
                left: auto;
                right: 150px;
            }

            @media #{$md}{
                left: 50px;
                width: calc(100% - 100px);

                @include rtl{
                    left: auto;
                    right: 50px;
                }
            }

            @media #{$sm, $xs}{
                left: 20px;
                width: calc(100% - 40px);

                @include rtl{
                    left: auto;
                    right: 20px;
                }
            }
        }
    }
    &__info{
        padding-right: 60px;
        @include rtl{
            padding-right: 0;
            padding-left: 60px;
        }

        @media #{$xl}{
            padding-right: 40px;
            @include rtl{
                padding-right: 0;
                padding-left: 40px;
            }
        }
        @media #{$lg}{
            padding-right: 0;
            @include rtl{
                padding-left: 0;
            }
        }
        @media #{$md, $sm, $xs}{
            padding-right: 0;
            margin-bottom: 50px;
            @include rtl{
                padding-left: 0;
            }
        }
        &-title{
            font-size: 170px;
            line-height: 1.1;
            color: var(--tp-common-black);

            @media #{$lg}{
               font-size: 158px;
            }

            @media #{$xs}{
                font-size: 120px;
            }

            & img{
                @include transform(translateY(-6px));
            }

            &.has-background{
                -webkit-background-clip:text;
                color: transparent;
                @include background();
            }
        }
        & p{
            font-family: var(--tp-ff-space);
            font-weight: 500;
            font-size: 26px;
            line-height: 1.15;
            letter-spacing: -0.04em;
            color: var(--tp-common-black);
            margin-bottom: 37px;
        }
        &-btn{
            & .tp-btn{
                padding: 12px 28px;

                &:hover{
                    background-color: var(--tp-common-black);
                }
            }
        }
    }
    &__nav{
        &-5{
            @media #{$xs}{
                margin-top: 40px;
            }
            & button{
                display: inline-block;
                width: 50px;
                height: 50px;
                line-height: 46px;
                text-align: center;
                border: 2px solid rgba(4, 18, 31, 0.1);
                font-size: 14px;
                color: var(--tp-common-black);
                background-color: transparent;
                border-radius: 50%;
                &:hover{
                    color: var(--tp-common-white);
                    background-color: var(--tp-theme-5);
                    border-color: var(--tp-theme-5);
                }

                &#{$self}-5-button-next{
                    margin-left: 5px;

                    @include rtl{
                        margin-left: 0;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    &__heading{
        font-family: var(--tp-ff-inter);
        font-weight: 600;
        font-size: 20px;
        line-height: 1.5;
        color: var(--tp-common-black-11);
        margin-bottom: 10px;
    }
    &__style-black{
        & #{$self}{
            &__item-4{
                padding: 0;
                background: var(--tp-common-white);
                border: none;
            }
            &__icon{
                & span{
                    color: var(--tp-common-black);
                }
            }
            &__content-4{
                & p{
                    color: #03041C;
                }
            }
            &__avater-title-4{
                color: var(--tp-common-black);
            }
            &__avater-designation-4{
                color: var(--tp-text-1);
            }
            &__avater-thumb-4{
                & img{
                    margin-bottom: 60px;
                }
            }
        }
    }
    &__style-2{
        #{$self}{
            &__item-5{
                border-radius: 0;
                padding: 50px 40px 34px;

                @media #{$xl}{
                    padding-left: 30px;
                    padding-right: 30px;
                }

                @media #{$md}{
                   padding: 40px 30px 34px;
                }
            }
            &__content-5 p{
                margin-bottom: 46px;
            }
        }
    }
    &__style-3{
        #{$self}{
            &__rating{
                margin-bottom: 10px;
            }
            &__item-6{
                padding: 48px 50px 50px;

                @media #{$xs}{
                    padding: 48px 30px 50px;
                }

                &::after{
                    background-color: var(--tp-theme-1);
                }
            }
            &__content-6{
                & p{
                    font-family: var(--tp-ff-space);
                    font-size: 20px;
                    line-height: 1.4;
                    color: var(--tp-text-1);
                }
            }
            &__avater{
                &-title-6{
                    font-family: var(--tp-ff-inter);
                    font-size: 16px;
                }
                &-designation-6{
                    font-family: var(--tp-ff-space);
                    font-weight: 500;
                    font-size: 12px;
                    text-transform: uppercase;
                    color: var(--tp-text-1);

                }
            }
        }
    }
    &-slider-dot-2{
    
        .swiper-pagination-bullet{
            background-color: var(--tp-common-white-op-7);
          
            &.swiper-pagination-bullet-active{
                background-color: var(--tp-common-white);
    
                &::after{
                    border-color: var(--tp-common-white);
                }
            }
        }
    }
    &-slider-dot-7{
        
        .swiper-pagination-bullet{
            background-color: #04121F24;
            width: 10px;
            height: 10px;
            margin: 0 4px;
            &::after{
                display: none;
            }
          
            &.swiper-pagination-bullet-active{
                background-color: var(--tp-theme-7);
            }
        }
    }
    &-slider-dot-9{
    
        .swiper-pagination-bullet{
            background-color: rgba($color: #03041C, $alpha: .3);
          
            &.swiper-pagination-bullet-active{
                background-color: rgba($color: #03041C, $alpha: 1);
    
                &::after{
                    border-color: rgba($color: #03041C, $alpha: 1);
                }
            }
        }
    }
    &-slider-dot-10{
    
        .swiper-pagination-bullet{
            background-color: rgba($color: #fff, $alpha: .5);
            margin: 0 4px;
          
            &.swiper-pagination-bullet-active{
                background-color: rgba($color: #fff, $alpha: 1);
                width: 50px;
    
                &::after{
                    border-color: rgba($color: #fff, $alpha: 1);
                }
            }
        }
    }
    &-slider-dot-14.swiper-pagination-bullets.swiper-pagination-horizontal{
        position: absolute;
        left: 20.5%;
        bottom: 40px;
        width: auto !important;
        @include rtl{
            left: auto;
            right: 20.5%;
        }

        @media #{$xl}{
            left: 21.5%;
            @include rtl{
                left: auto;
                right: 21.5%;
            }
        }

        @media #{$lg}{
            left: 21.5%;
            @include rtl{
                left: auto;
                right: 21.5%;
            }
        }
        @media #{$md}{
            left: 17.5%;
            @include rtl{
                left: auto;
                right: 17.5%;
            }
        }
        @media #{$sm}{
            left: 19.5%;
            @include rtl{
                left: auto;
                right: 19.5%;
            }
        }
        @media #{$xs}{
            left: 4%;
            @include rtl{
                left: auto;
                right: 4%;
            }
        }
        .swiper-pagination-bullet{
            background-color: #DBDBE6;
            margin: 0 4px;
            &::after{
                display: none;
            }
          
            &.swiper-pagination-bullet-active{
                background-color: var(--tp-theme-1);
            }
        }
    }
}


.testimonial-slider-dot{
    .swiper-pagination-bullet{
        background-color: var(--tp-common-white-op-3);
        
        &.swiper-pagination-bullet-active{
            background-color: var(--tp-common-white);

            &::after{
                border-color: var(--tp-common-white);
            }
        }
    }
}

.testimonial__item-8 {
    & .testimonial__shape-quote-8{
        & img{
            display: inline-block;
        }
    }
}

.testimonial__slider-8-thumb {
    & .slick-active.slick-center.slick-current{
        & img{
            @include transform(scale(1.42));
        } 
    }
}
.slick-list {
    padding: 0 !important;
}
.testimonial__area{
    & .testimonial__slider-8-thumb{
        & .testimonial__avater-8{
            @media #{$xs}{
                display: flex !important;
                align-items: center;
                justify-content: center;
            }
        }
    }
}