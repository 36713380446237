@use '../utils' as *;

/*----------------------------------------*/
/*  04. MENU CSS START
/*----------------------------------------*/

.main-menu{
    @include rtl{
        padding-left: 0;
        padding-right: 55px;
    }
    @media #{$lg}{
        padding-left: 30px;
        @include rtl{
            padding-left: 0;
            padding-right: 30px;
        }
    }
    & ul{
        & li{
            position: relative;
            list-style: none;
            display: inline-block;
            margin-right: 34px;

            @include rtl{
                margin-right: 0;
                margin-left: 34px;
            }

            @media #{$lg}{
                margin-right: 25px;
                @include rtl{
                    margin-left: 25px;
                    margin-right: 0;
                }
            }

            &:last-child{
                margin-right: 0;
                @include rtl{
                    margin-left: 0;
                }
            }

            & a{
                display: inline-block;
                font-size: 16px;
                font-weight: 500;
                color: var(--tp-common-white);
                padding: 16px 0;
                text-align: left;

                @include rtl{
                    text-align: right;
                }
            }

			&.has-dropdown{
				& > a{
                    position: relative;
                    &::after{
                        content: '\f107';
                        @include transform(translateY(1px));
                        font-size: 14px;
                        color: var(--tp-common-white);
                        font-family: var(--tp-ff-fontawesome);
                        font-weight: 400;
                        margin-left: 5px;
                        display: inline-block;
                        @extend %tp-transition;

                        @include rtl{
                            margin-right: 5px;
                            margin-left: 0;
                        }
                    }
                }
			}

            &.has-mega-menu{
                position: static;
            }

            /* submenu css start */
			& .submenu{
                position: absolute;
                top: 100%;
                left: 0;
                min-width: 200px;
                background: var(--tp-common-white);
                z-index: 99;
                visibility: hidden;
                opacity: 0;
                transform-origin: top center;
                padding: 25px 0 5px;
                background: var(--tp-common-white);
                @include transform(scaleY(0));
                @extend %tp-transition;
                @include box-shadow(0px 20px 30px rgba(3, 4, 28, 0.1));

                @include rtl{
                    left: auto;
                    right: 0;
                }
                & li{
                    display: block;
                    width: 100%;
					margin: 0;
                    padding: 0;
                    @include transform(translateY(-10px));

                    &.has-dropdown{
                        & > a{
                            &::after{
								position: absolute;
								top: 50%;
                                right: 25px;
                                @include transform(translateY(-50%) rotate(-90deg));
                                color: var(--tp-common-black);

                                @include rtl{
                                    right: auto;
                                    left: 25px;
                                }
                            }
                        }
                    }
                    & a{
                        position: relative;
                        z-index: 11;
						color: var(--tp-common-black);
                        font-weight: 400;
                        font-size: 14px;
                        padding: 0 25px;
                        padding-bottom: 1px;
                        width: 100%;
                        &::before{
                            position: absolute;
                            content: '';
                            left: 25px;
                            top: 50%;
                            @include transform(translateY(-50%));
                            width: 0;
                            height: 1px;
                            background-color: var(--tp-theme-1);
                            @extend %tp-transition;

                            @include rtl{
                                left: auto;
                                right: 25px;
                            }
                        }
                    }
                    & .submenu{
                        left: 100%;
                        top:0;
                        visibility: hidden;
                        opacity: 0;

                        @include rtl{
                            left: auto;
                            right: 100%;
                        }
                    }
                    &:hover{
                        & > a{
                            color: var(--tp-theme-1);
                            padding-left: 43px;

                            @include rtl{
                                padding-left: 0;
                                padding-right: 43px;
                            }
                            &::before{
                                width: 12px;
                            }
                            &::after{
                                color: var(--tp-theme-1);
                            }
                        }
                        & > .submenu{
                            @include transform(translateY(1));
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }

            /* mega menu start */
            & .mega-menu{
                position: absolute;
                top: 100%;
                left: 0;
                padding: 30px 40px;
                background: #FFFFFF;
                box-shadow: 0px 20px 30px rgba(3, 4, 28, 0.1);
                visibility: hidden;
                opacity: 0;
                @include transform(scaleY(0));
                transform-origin: top center;
                @extend %tp-transition;
                z-index: 99;

                @include rtl{
                    right: 0;
                    left: auto;
                }

                & li{
                    float: left;
                    width: 25%;
                    margin-right: 0;
                    text-align: left;

                    @include rtl{
                        float: right;
                        margin-left: 0;
                        text-align: right;
                    }

                    & .mega-menu-title{
                        font-weight: 500;
                        font-size: 12px;
                        letter-spacing: 0.1em;
                        text-transform: uppercase;
                        color: #7D7D82;
                        cursor: text;
                        padding: 0;
                        display: inline-block;
                        margin-bottom: 10px;

                        &::before{
                            display: none;
                        }
                    }

                    & ul{
                        & li{
                            width: 100%;
                            float: none;
                            margin-bottom: 2px;

                            &:last-child{
                                margin-bottom: 0;
                            }

                            & a{
                                font-weight: 400;
                                font-size: 14px;
                                color: var(--tp-common-black);
                                padding: 0;
                                position: relative;
                                &::before{
                                    position: absolute;
                                    content: '';
                                    left: 0;
                                    right: auto;
                                    top: 50%;
                                    bottom: auto;
                                    @include transform(translateY(-50%));
                                    width: 0;
                                    height: 1px;
                                    background-color: var(--tp-theme-1);
                                    @extend %tp-transition;

                                    @include rtl{
                                        right: 0;
                                        left: auto;
                                    }
                                    
                                }
                            }                           
                        }
                    }
                    &:hover{
                        & > .mega-menu-title{
                            color: var(--tp-theme-1);
                            padding-left: 0;

                            @include rtl{
                                padding-right: 0;
                            }
                        }

                        & > a{
                            color: var(--tp-theme-1);
                            padding-left: 18px;

                            @include rtl{
                               padding-left: 0;
                               padding-right: 18px;
                            }
                            &::before{
                                width: 12px;
                            }
                        }
                    }
                }
            }

            &:hover{
				& > a{
					color: var(--tp-theme-1);
					&::after{
						color: var(--tp-theme-1);
					}
				}
				& > .submenu{
					visibility: visible;
					opacity: 1;
                    @include transform(scaleY(1));
				}
				& .mega-menu{
					visibility: visible;
					opacity: 1;
                    @include transform(scaleY(1));
				}
			}
        }
    }
    &-ff{
        &-space{
            & ul{
                & li{
                    & a{
                        font-family: var(--tp-ff-space);
                    }
                }
            }
        }
        &-syne{
            & ul{
                & li{
                    & a{
                        font-family: var(--tp-ff-syne);
                    }
                }
            }
        }
    }
    &-2{
        text-align: center;
        @media #{$lg}{
            padding-left: 0;

            @include rtl{
                padding-right: 0;
            }
        }
    }
    &-3{
        @media #{$lg}{
            padding-left: 0;

            @include rtl{
                padding-right: 0;
            }
        }

        & ul{
            & li{
                margin-right: 25px;

                @include rtl{
                    margin-right: 0;
                    margin-left: 25px;
                }
                &:last-child{
                    margin-right: 0;

                    @include rtl{
                        margin-left: 0;
                    }
                }
                & a{
                    color: var(--tp-common-white);
                    padding: 21px 0;
                    
                }

                & .submenu{
                    background-color: var(--tp-common-black-5);
                    border: 1px solid var(--tp-border-1);

                    & li{
                        & a{
                            color: var(--tp-common-white);
                        }

                        &.has-dropdown{
                            & > a{
                                &::after{
                                    color: var(--tp-common-white);
                                }
                            }
                            &:hover{
                                & > a{
                                    &::after{
                                        color: var(--tp-theme-3);
                                    }
                                }
                            }
                        }
                        
                    }
                }
                & .mega-menu{
                    background-color: var(--tp-common-black-5);
                    border: 1px solid var(--tp-border-1);
                    & li{
                        & .mega-menu-title{
                            color: rgba($color: #fff, $alpha: .7);
                        }
                        & ul{
                            & li{
                                & a{
                                    color: var(--tp-common-white);
                                }
                                &:hover{
                                    & > a{
                                        color: var(--tp-theme-3);   
                                        &::before{
                                            background-color: var(--tp-theme-3);
                                        }
                                    }
                                }
                            
                            }
                        }

                        &:hover{
                            & > .mega-menu-title{
                                color: var(--tp-theme-3);                  
                            }
                        }
                    }
                }

                &:hover{
                    & > a{
                        color: var(--tp-theme-3);

                        &::after{
                            color: var(--tp-theme-3);
                        }
                    }
                }
            }
        }
    }
    &-4{
        @include rtl{
            padding-right: 0;
        }
        @media #{$lg}{
            padding-left: 0;

            @include rtl{
                padding-right: 0;
            }
        }

        & ul{
            & li{
                &:last-child{
                    margin-right: 0;
                    @include rtl{
                        margin-left: 0;
                    }
                }
                & a{
                    color: var(--tp-common-white);
                    padding: 11px 0;

                    &::before{
                        position: absolute;
                        content: '';
                        left: auto;
                        right: 0;
                        bottom: -2px;
                        width: 0%;
                        height: 1px;
                        background-color: var(--tp-theme-4);
                        @extend %tp-transition;

                        @include rtl{
                            left: 0;
                            right: auto;
                        }
                    }
                    
                    
                }

                &.has-dropdown{
                    & a{
                        &::after{
                            color: var(--tp-common-white);
                        }
                    }
                }

                & .submenu{
                    & li{
                        & a{
                            color: var(--tp-common-black);

                            &::before{
                                background-color: var(--tp-theme-4);
                            }
                        }

                        &:hover{
                            & > a{
                                color: var(--tp-theme-4);

                                &::after{
                                    color: var(--tp-theme-4);
                                }
                            }
                        }
                    }
                }

                & .mega-menu{
                    & li{
                        & ul{
                            & li{
                                &:hover{
                                    & > a{
                                        color: var(--tp-theme-4);   
                                        &::before{
                                            background-color: var(--tp-theme-4);
                                        }
                                    }
                                }
                            
                            }
                        }

                        &:hover{
                            & > .mega-menu-title{
                                color: var(--tp-theme-4);                  
                            }
                        }
                    }
                }

                &:hover{
                    & > a{
                        color: var(--tp-theme-4);

                        &::after{
                            color: var(--tp-theme-4);
                        }

                        &::before{
                            width: 100%;
                            left: 0;
                            right: auto;

                            @include rtl{
                                left: auto;
                                right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    &-5{
        margin-left: -66px;
        padding-right: 0;
        @include rtl{
            margin-left: 0;
            margin-right: -66px;
            padding-right: 0;
        }

        @media #{$xxl}{
           margin-left: -50px;

           @include rtl{
            margin-left: 0;
            margin-right: -50px;
            padding-right: 0;
           }
        }

        @media #{$xl}{
           margin-left: -45px;

           @include rtl{
            margin-left: 0;
            margin-right: -45px;
            padding-right: 0;
           }
        }

        @media #{$lg}{
            padding-left: 0;

            @include rtl{
                padding-right: 0;
            }
        }

        & ul{
            & li{
                @media #{$xl, $lg}{
                    margin-right: 25px;

                    @include rtl{
                        margin-right: 0;
                        margin-left: 25px;
                    }
                }

                &:last-child{
                    margin-right: 0;
                    @include rtl{
                        margin-left: 0;
                    }
                }
                & a{
                    color: var(--tp-common-black);
                    padding: 25px 0;
                    
                }

                &.has-dropdown{
                    & a{
                        &::after{
                            color: var(--tp-common-black);
                        }
                    }
                }

                & .submenu{
                    & li{
                        & a{
                            color: var(--tp-common-black);
                            &::before{
                                background-color: var(--tp-theme-5);
                            }
                        }

                        &:hover{
                            & > a{
                                color: var(--tp-theme-5);

                                &::after{
                                    color: var(--tp-theme-5);
                                }
                            }
                        }
                    }
                }

                & .mega-menu{
                    & li{
                        & ul{
                            & li{
                                &:hover{
                                    & > a{
                                        color: var(--tp-theme-5);   
                                        &::before{
                                            background-color: var(--tp-theme-5);
                                        }
                                    }
                                }
                            
                            }
                        }

                        &:hover{
                            & > .mega-menu-title{
                                color: var(--tp-theme-5);                  
                            }
                        }
                    }
                }

                &:hover{
                    & > a{
                        color: var(--tp-theme-5);

                        &::after{
                            color: var(--tp-theme-5);
                        }
                    }
                }
            }
        }
    }
    &-6{
        margin-left: 20px;

        @include rtl{
            margin-left: 0;
            margin-right: 20px;
        }
        @media #{$lg}{
            padding-left: 0;
            margin-left: 0;

            @include rtl{
                padding-right: 0;
                margin-right: 0;
            }
        }
        & ul{
            & li{

                @media #{$lg}{
                    margin-right: 25px;

                    @include rtl{
                        margin-right: 0;
                        margin-left: 25px;
                    }
                }
                &:last-child{
                    margin-right: 0;

                    @include rtl{
                        margin-left: 0;
                    }
                }
                & a{
                    color: var(--tp-common-black-10);
                    padding: 25px 0;
                    font-size: 15px;
                    font-family: var(--tp-ff-roboto);
                }
                

                &.has-dropdown{
                    & a{
                        &::after{
                            color: var(--tp-common-black-10);
                        }
                    }
                }

                & .submenu{
                    & li{
                        & a{
                            color: var(--tp-common-black-10);

                            &::before{
                                background-color: var(--tp-theme-6);
                            }
                        }

                        &:hover{
                            & > a{
                                color: var(--tp-theme-6);

                                &::after{
                                    color: var(--tp-theme-6);
                                }
                            }
                        }
                    }
                }

                & .mega-menu{
                    & li{
                        & ul{
                            & li{
                                &:hover{
                                    & > a{
                                        color: var(--tp-theme-6);   
                                        &::before{
                                            background-color: var(--tp-theme-6);
                                        }
                                    }
                                }
                            
                            }
                        }

                        &:hover{
                            & > .mega-menu-title{
                                color: var(--tp-theme-6);                  
                            }
                        }
                    }
                }

                &:hover{
                    & > a{
                        color: var(--tp-theme-6);

                        &::after{
                            color: var(--tp-theme-6);
                        }
                    }
                }
            }
        }
    }
    &-7{
        @include rtl{
            padding-right: 0;
        }
        @media #{$lg}{
            padding-left: 0;

            @include rtl{
                padding-right: 0;
            }
        }

        & ul{
            & li{
                margin-right: 40px;

                @include rtl{
                    margin-right: 0;
                    margin-left: 40px;
                }
                @media #{$xl, $lg}{
                    margin-right: 25px;

                    @include rtl{
                        margin-right: 0;
                        margin-left: 25px;
                    }
                }

                &:last-child{
                    margin-right: 0;

                    @include rtl{
                        margin-left: 0;
                    }
                }
                & a{
                    color: var(--tp-common-black);
                    padding: 25px 0;
                    font-size: 16px;
                    font-weight: 400;
                }

                &.has-dropdown{
                    & a{
                        &::after{
                            color: var(--tp-common-black);
                        }
                    }
                }

                & .submenu{
                    & li{
                        & a{
                            color: var(--tp-common-black);
                            &::before{
                                background-color: var(--tp-theme-10);
                            }
                        }

                        &:hover{
                            & > a{
                                color: var(--tp-theme-10);

                                &::after{
                                    color: var(--tp-theme-10);
                                }
                            }
                        }
                    }
                }

                & .mega-menu{
                    & li{
                        & ul{
                            & li{
                                &:hover{
                                    & > a{
                                        color: var(--tp-theme-10);   
                                        &::before{
                                            background-color: var(--tp-theme-10);
                                        }
                                    }
                                }
                            
                            }
                        }

                        &:hover{
                            & > .mega-menu-title{
                                color: var(--tp-theme-10);                  
                            }
                        }
                    }
                }

                &:hover{
                    & > a{
                        color: var(--tp-theme-10);

                        &::after{
                            color: var(--tp-theme-10);
                        }
                    }
                }
            }
        }
    }
    &-8{

        @media #{$lg, $xxl}{
            padding-left: 0;

            @include rtl{
                padding-right: 0;
            }
        }

        & ul{
            & li{

                @media #{$xxl}{
                    margin-right: 30px;

                    @include rtl{
                        margin-right: 0;
                        margin-left: 30px;
                    }
                }
                @media #{$xl, $lg}{
                    margin-right: 25px;

                    @include rtl{
                        margin-right: 0;
                        margin-left: 25px;
                    }
                }
                &:last-child{
                    margin-right: 0;

                    @include rtl{
                        margin-left: 0;
                    }
                }
                & a{
                    color: var(--tp-common-white);
                    padding: 35px 0;
                    font-size: 16px;
                    font-weight: 500;
                    font-family: var(--tp-ff-space);
                }

                &.has-dropdown{
                    & a{
                        &::after{
                            color: var(--tp-common-white);
                        }
                    }
                }

                & .submenu{
                    
                    & li{
                        & a{

                            &::before{
                                background-color: var(--tp-theme-8);
                            }
                        }

                        &:hover{
                            & > a{
                                color: var(--tp-theme-8);

                                &::after{
                                    color: var(--tp-theme-8);
                                }
                            }
                        }
                    }
                }

                & .mega-menu{
                    & li{
                        & ul{
                            & li{
                                &:hover{
                                    & > a{
                                        color: var(--tp-theme-8);   
                                        &::before{
                                            background-color: var(--tp-theme-8);
                                        }
                                    }
                                }
                            
                            }
                        }

                        &:hover{
                            & > .mega-menu-title{
                                color: var(--tp-theme-8);                  
                            }
                        }
                    }
                }

                &:hover{
                    & > a{
                        color: var(--tp-theme-8);

                        &::after{
                            color: var(--tp-theme-8);
                        }
                    }
                }
            }
        }
    }
    &-9{
        @media #{$lg}{
            padding-left: 0;

            @include rtl{
                padding-right: 0;
            }
        }

        & ul{
            & li{
                margin-right: 35px;

                @include rtl{
                    margin-right: 0;
                    margin-left: 35px;
                }
                @media #{$xxl}{
                    margin-right: 30px;

                    @include rtl{
                        margin-right: 0;
                        margin-left: 30px;
                    }
                }
                @media #{$xl, $lg}{
                    margin-right: 25px;

                    @include rtl{
                        margin-right: 0;
                        margin-left: 25px;
                    }
                }
                &:last-child{
                    margin-right: 0;

                    @include rtl{
                        margin-left: 0;
                    }
                }

                & a{
                    font-family: var(--tp-ff-syne);
                    font-weight: 500;
                    font-size: 16px;
                    color: rgba($color: #051214, $alpha: .8);
                    padding: 30px 0;
                    &::before{
                        position: absolute;
                        content: '';
                        left: 0;
                        right: 0;
                        margin: auto;
                        bottom: 34px;
                        width: 0;
                        height: 1px;
                        background-color: var(--tp-common-black);
                        @extend %tp-transition;
                    }
                }

                &.has-dropdown{
                    & a{
                        &::after{
                            color: rgba($color: #051214, $alpha: .8);
                        }
                    }
                }

                & .submenu{
                    & li{
                        &.has-dropdown{
                            & a{
                                &::after{
                                    color: rgba($color: #051214, $alpha: .8);
                                }
                            }
                        }

                        & a{
                            color: rgba($color: #051214, $alpha: .8);

                            &::before{
                                right: auto;
                                left: 25px;
                                background-color: var(--tp-common-black);
                            }

                            
                        }

                        &:hover{
                            & > a{
                                color: var(--tp-common-black);

                                &::after{
                                    color: var(--tp-common-black);
                                }

                                &::before{
                                    width: 12px;

                                }
                            }
                        }
                    }
                }

                & .mega-menu{
                    & li{
                        & ul{
                            & li{
                                &:hover{
                                    & > a{
                                        color: var(--tp-theme-9);   
                                        &::before{
                                            background-color: var(--tp-theme-9);
                                        }
                                    }
                                }
                            
                            }
                        }

                        &:hover{
                            & > .mega-menu-title{
                                color: var(--tp-theme-9);                  
                            }
                        }
                    }
                }


                &:hover{
                    & > a{
                        color: var(--tp-common-black);

                        &::after{
                            color: var(--tp-common-black);
                        }

                        &::before{
                            width: 100%;

                        }
                    }
                }
            }
        }
    }
    &-10{
        padding-left: 25px;

        @include rtl{
            padding-left: 0;
            padding-right: 25px;
        }

        @media #{$lg}{
            padding-left: 0;
            @include rtl{
                padding-right: 0;
            }
        }

        & ul{
            & li{
                @media #{$xxl}{
                    margin-right: 30px;
                    @include rtl{
                        margin-right: 0;
                        margin-left: 30px;
                    }
                }
                @media #{$xl, $lg}{
                    margin-right: 25px;

                    @include rtl{
                        margin-right: 0;
                        margin-left: 25px;
                    }
                }
                &:last-child{
                    margin-right: 0;
                    @include rtl{
                        margin-left: 0;
                    }
                }

                & a{
                    padding: 27px 0;
                }

                &.has-dropdown{
                    & a{
                        &::after{
                            color: rgba($color: #fff, $alpha: 1);
                        }
                    }
                }

                & .submenu{
                    & li{
                        & a{
                            &::before{
                                right: auto;
                                left: 25px;
                                background-color: var(--tp-theme-10);

                                @include rtl{
                                    right: 25px;
                                    left: auto;
                                }
                            }
                        }

                        &:hover{
                            & > a{
                                color: var(--tp-theme-10);

                                &::after{
                                    color:  var(--tp-theme-10);
                                }

                                &::before{
                                    width: 12px;

                                }
                            }
                        }
                    }
                }

                & .mega-menu{
                    & li{
                        & ul{
                            & li{
                                &:hover{
                                    & > a{
                                        color: var(--tp-theme-10);   
                                        &::before{
                                            background-color: var(--tp-theme-10);
                                        }
                                    }
                                }
                            
                            }
                        }

                        &:hover{
                            & > .mega-menu-title{
                                color: var(--tp-theme-10);                  
                            }
                        }
                    }
                }

                &:hover{
                    & > a{
                        color: var(--tp-theme-10);

                        &::after{
                            color: var(--tp-theme-10);
                        }

                        &::before{
                            width: 100%;

                        }
                    }
                }
            }
        }
    }
    &-11{
        
        @media #{$lg}{
            padding-left: 0;

            @include rtl{
                padding-right: 0;
            }
        }

        & ul{
            & li{
                @media #{$xxl}{
                    margin-right: 30px;
                    @include rtl{
                        margin-right: 0;
                        margin-left: 30px;
                    }
                }
                @media #{$xl, $lg}{
                    margin-right: 25px;
                    @include rtl{
                        margin-right: 0;
                        margin-left: 25px;
                    }
                }
                &:last-child{
                    margin-right: 0;
                    @include rtl{
                        margin-left: 0;
                    }
                }

                & a{
                    padding: 27px 0;
                    color: var(--tp-common-black-19);

                    &::before{
                        position: absolute;
                        content: '';
                        left: 0;
                        right: 0;
                        margin: auto;
                        bottom: 30px;
                        width: 0;
                        height: 1px;
                        background-color: var(--tp-common-black);
                        @extend %tp-transition;
                    }
                }

                &.has-dropdown{
                    & a{
                        &::after{
                            color: var(--tp-common-black-19);
                        }
                    }
                }

                & .submenu{
                    & li{
                        & a{
                            &::before{
                                right: auto;
                                left: 25px;
                                background-color: var(--tp-theme-10);

                                @include rtl{
                                    left: auto;
                                    right: 25px;
                                }
                            }
                        }

                        &:hover{
                            & > a{
                                color: var(--tp-theme-10);

                                &::after{
                                    color:  var(--tp-theme-10);
                                }

                                &::before{
                                    width: 12px;

                                }
                            }
                        }
                    }
                }

                & .mega-menu{
                    & li{
                        & ul{
                            & li{
                                & a{
                                    color: var(--tp-common-black-19);
                                }
                                &:hover{
                                    & > a{
                                        color: var(--tp-common-black);   
                                        &::before{
                                            background-color: var(--tp-common-black);
                                        }
                                    }
                                }
                            
                            }
                        }

                        &:hover{
                            & > .mega-menu-title{
                                color: var(--tp-common-black);                  
                            }
                        }
                    }
                }

                &:hover{
                    & > a{
                        color: var(--tp-common-black);

                        &::after{
                            color: var(--tp-common-black);
                        }

                        &::before{
                            width: 100%;

                        }
                    }
                }
            }
        }
    }
    &-12{
        
        @media #{$lg}{
            padding-left: 0;

            @include rtl{
                padding-right: 0;
            }
        }

        & ul{
            & li{
                margin-right: 36px;

                @include rtl{
                    margin-right: 0;
                    margin-left: 36px;
                }
                @media #{$xxl}{
                    margin-right: 30px;

                    @include rtl{
                        margin-right: 0;
                        margin-left: 30px;
                    }
                }
                @media #{$xl, $lg}{
                    margin-right: 25px;

                    @include rtl{
                        margin-right: 0;
                        margin-left: 25px;
                    }
                }
                &:last-child{
                    margin-right: 0;

                    @include rtl{
                        margin-left: 0;
                    }
                }

                & a{
                    padding: 25px 0;
                    color: var(--tp-common-black-19);

                    &::before{
                        position: absolute;
                        content: '';
                        left: 0;
                        right: 0;
                        margin: auto;
                        bottom: 30px;
                        width: 0;
                        height: 1px;
                        background-color: var(--tp-common-black);
                        @extend %tp-transition;
                    }
                }

                &.has-dropdown{
                    & a{
                        &::after{
                            color: var(--tp-common-black-19);
                        }
                    }
                }

                & .submenu{
                    & li{
                        & a{
                            &::before{
                                right: auto;
                                left: 25px;
                                background-color: var(--tp-theme-10);

                                @include rtl{
                                    right: 25px;
                                    left: auto;
                                }
                            }
                        }

                        &:hover{
                            & > a{
                                color: var(--tp-theme-10);

                                &::after{
                                    color:  var(--tp-theme-10);
                                }

                                &::before{
                                    width: 12px;

                                }
                            }
                        }
                    }
                }

                & .mega-menu{
                    & li{
                        & ul{
                            & li{
                                &:hover{
                                    & > a{
                                        color: var(--tp-theme-10);   
                                        &::before{
                                            background-color: var(--tp-theme-10);
                                        }
                                    }
                                }
                            
                            }
                        }

                        &:hover{
                            & > .mega-menu-title{
                                color: var(--tp-theme-10);                  
                            }
                        }
                    }
                }

                &:hover{
                    & > a{
                        color: var(--tp-common-black);

                        &::after{
                            color: var(--tp-common-black);
                        }

                        &::before{
                            width: 100%;

                        }
                    }
                }
            }
        }
    }
    &-13{
        & ul{
            & li{
                margin-right: 30px;

                @include rtl{
                    margin-right: 0;
                    margin-left: 30px;
                }

                @media #{$xxl}{
                    margin-right: 25px;

                @include rtl{
                    margin-right: 0;
                    margin-left: 25px;
                }

                }
                & a{
                    font-size: 15px;
                    padding: 25px 0;
                }
            }
        }
    }
}