@use '../utils' as *;

/*----------------------------------------*/
/*  SETTINGS CSS START
/*----------------------------------------*/

.tp-theme{
    $self : &;
    &-settings{
        &-area{
            position: fixed;
            top: 50%;
            left: 0;
            width: 240px;
            background-color: #fff;
            border: 1px solid #EAEAEF;
            @include transform(translateY(-50%) translateX(-100%));
            z-index: 991;
            direction: ltr;
            border-bottom-right-radius: 4px;
    
            &.settings-opened{
                @include transform(translateY(-50%) translateX(0%));

                #{$self}{
                    &-settings-gear{
                        opacity: 0;
                    }
                    &-settings-close{
                        opacity: 1;
                    }
                }
            }
        }
        &-open{
            position: absolute;
            top: -1px;
            left: 100%;
            
            
            & button{
                background-color: var(--tp-common-white);
                border: 1px solid #EAEAEF;
                border-left: 0;
                width: 60px;
                height: 60px;
                line-height: 60px;
                text-align: center;
                font-size: 24px;
                color: var(--tp-common-black);
                position: relative;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
        &-gear{
            display: inline-block;
            -webkit-animation:spin 4s linear infinite;
            -moz-animation:spin 4s linear infinite;
            animation:spin 4s linear infinite;
            @at-root{
                @include keyframes(spin){
                    100%{
                        -webkit-transform:rotate(360deg);
                        transform:rotate(360deg);
                    }
                }
            }
        }
        &-close{
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));
            opacity: 0;
        }
    }
    &-header{
        &-title{
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 30px;
        }
    }
    &-wrapper{
        padding: 20px 30px 30px;
    }
    &-toggle{
        text-align: center;
        &-main{
            display: inline-block;
            width: 160px;
            margin: auto;
            position: relative;
            z-index: 1;
            background-color: #f0f0f5;
            padding: 4px;
            border-radius: 20px;
        }
        &-light,
        &-dark{
            display: inline-block;
            width: calc(100% - 52%);
            height: 26px;
            line-height: 26px;
        }
        & input{
            display: none;
        }
        
        &:hover{
            cursor: pointer;
        }

        & label{
            color: var(--tp-common-black);
            font-size: 14px;
            font-weight: 500;
            &:hover{
                cursor: pointer;
            }
        }
        & #tp-theme-toggler{
            display: none;

            &:checked + i{
                right: calc(50% - 4px);
            }
        }
        &-slide{
            position: absolute;
            top: 50%;
            right: 4px;
            @include transform(translateY(-50%));
            width: calc(100% - 50%);
            height: 26px;
            color: var(--tp-common-black);
            background-color: var(--tp-common-white);
            border-radius: 30px;
            -webkit-transform: translate3d(0, 0);
            transform: translate3d(0, 0);
            -webkit-transition: 0.2s cubic-bezier(0.25, 1, 0.5, 1);
            -o-transition: 0.2s cubic-bezier(0.25, 1, 0.5, 1);
            transition: 0.2s cubic-bezier(0.25, 1, 0.5, 1);
            z-index: -1;
        }

    }
    &-dir{
        text-align: center;
        &-main{
            display: inline-block;
            width: 160px;
            margin: auto;
            position: relative;
            z-index: 1;
            background-color: #f0f0f5;
            padding: 4px;
            border-radius: 20px;
        }
        &-ltr,
        &-rtl{
            display: inline-block;
            width: calc(100% - 52%);
            height: 26px;
            line-height: 26px;
        }
        & input{
            display: none;
        }
        
        &:hover{
            cursor: pointer;
        }

        & label{
            color: var(--tp-common-black);
            font-size: 14px;
            font-weight: 500;
            &:hover{
                cursor: pointer;
            }
        }
        & #tp-dir-toggler{
            display: none;

            &:checked + i{
                right: calc(50% - 4px);
                
            }
        }
        &-slide{
            position: absolute;
            top: 50%;
            right: 4px;
            @include transform(translateY(-50%));
            width: calc(100% - 50%);
            height: 26px;
            color: var(--tp-common-black);
            background-color: var(--tp-common-white);
            border-radius: 30px;
            -webkit-transform: translate3d(0, 0);
            transform: translate3d(0, 0);
            -webkit-transition: 0.2s cubic-bezier(0.25, 1, 0.5, 1);
            -o-transition: 0.2s cubic-bezier(0.25, 1, 0.5, 1);
            transition: 0.2s cubic-bezier(0.25, 1, 0.5, 1);
            z-index: -1;
        }

    }
    &-color{
        &-item{
            &.active{    
                & button{
                    &::before{
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
        &-btn{
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            position: relative;

            &::before{
                position: absolute;
                content: '\f00c';
                font-weight: 600;
                font-family: var(--tp-ff-fontawesome);
                color: var(--tp-common-white);
                font-size: 16px;
                left: 50%;
                top: 50%;
                @include transform(translate(-50%, -50%));
                @extend %tp-transition;
                opacity: 0;
                visibility: hidden;
            }

            &.tp-color-settings-btn{
                            
                &[data-color="#F50963"]{
                    background-color: #F50963;
                }
                &[data-color="#008080"]{
                    background-color: #008080;
                }
                &[data-color="#F31E5E"]{
                    background-color: var(--tp-theme-3);
                }
                &[data-color="#AB6C56"]{
                    background-color: var(--tp-theme-4);
                    color: black;
                }
                &[data-color="#4353FF"]{
                    background-color: var(--tp-theme-5);
                }
                &[data-color="#3661FC"]{
                    background-color: var(--tp-theme-6);
                }
                &[data-color="#2CAE76"]{
                    background-color: var(--tp-theme-7);
                }
                &[data-color="#FF5A1B"]{
                    background-color: var(--tp-theme-8);
                }
                &[data-color="#03041C"]{
                    background-color: var(--tp-theme-9);
                }
                &[data-color="#ED212C"]{
                    background-color: var(--tp-theme-10);
                }
            }

           
        }
        &-input{
            margin-top: 15px;

            & h6{
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 5px;
            }

            & label{
                display: inline-block;
                width: 100%;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background-color: var(--tp-theme-1);
                @extend %tp-transition;
                &:hover{
                    cursor: pointer;
                }
            }

            & input{
                display: none;
            }
        }
    }
}