@use '../utils' as *;

/*----------------------------------------*/
/*  41. BANNER CSS START
/*----------------------------------------*/

.banner{
    &__inner{
        padding: 100px 100px;

        @media #{$lg}{
            padding: 100px 70px;
        }

        @media #{$md}{
            padding: 60px 60px;
            background-position: left;

            @include rtl{
                background-position: right;
            }
        }

        @media #{$sm}{
            padding: 45px;
            background-position: left;

            @include rtl{
                background-position: right;
            }
        }

        @media #{$xs}{
            padding: 35px;
            background-position: left;

            @include rtl{
                background-position: right;
            }
        }
    }
    &__title{
        font-family: var(--tp-ff-roboto);
        font-weight: 600;
        font-size: 44px;
        line-height: 1.09;
        margin-bottom: 21px;

        @media #{$xs}{
            font-size: 28px;
        }

        & a{
            &:hover{
                color: var(--tp-theme-1);
            }
        }
    }
    &__content{
        & > span{
            font-family: var(--tp-ff-roboto);
            font-weight: 500;
            font-size: 15px;
            color: var(--tp-text-1);
            display: inline-block;
            margin-bottom: 7px;
        }

        & p{
            font-family: var(--tp-ff-roboto);
            font-style: normal;
            font-size: 16px;
            line-height: 17px;
            margin-bottom: 35px;

            & span{
                font-weight: 700;
                font-size: 24px;
                color: var(--tp-common-black);
            }
        }
    }
    &__btn{
        & .tp-btn{
            font-family: var(--tp-ff-roboto);
            font-weight: 500;
            font-size: 14px;
            background-color: var(--tp-common-white);
            box-shadow: 0px 1px 2px rgba(3, 4, 28, 0.1);
            color: var(--tp-common-black);
            padding: 9px 25px;

            &:hover{
                background-color: var(--tp-theme-1);
                color: var(--tp-common-white);
            }

            & svg{
                @include transform(translateY(-1px));
            }
        }
    }
}