@use '../utils' as *;

/*----------------------------------------*/
/*  ALL Button CSS START
/*----------------------------------------*/



/* pulse btn */

.pulse-btn{
	display: inline-block;
	width: 80px;
	height: 80px;
	line-height: 80px;
	text-align: center;
	background-color: var(--tp-common-white);
	@include border-radius(50%);
	color: var(--tp-common-black);
	animation: pulse 2s infinite;
	&:hover{
		background-color: var(--tp-common-black);
		color: var(--tp-common-black);
	}
	& i{
		padding-left: 2px;
	}
}


.tp-pulse-border{
	border-radius: 50%;
	z-index: 1;
	&::after,
	&::before{
		position: absolute;
		content: '';
		left: 50%;
		top: 50%;
		@include transform(translate(-50%, -50%));
		width: 100%;
		height: 100%;
		border-radius: 50%;
		border: 1px solid rgba($color: #fff, $alpha: .4);
		animation: borderanimate2 2s linear infinite;
		animation-delay: 0s;
		-webkit-animation-delay: 0s;
		animation-delay: 0s;
		@extend %tp-transition;
		z-index: -1;
	}

	&::before{
		animation-delay: .7s;
	}
}

.tp-pulse-btn-2{
	position: relative;
}

.tp-pulse-btn-2-inner{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.tp-pulse-btn-2::after,
.tp-pulse-btn-2::before,
.tp-pulse-btn-2-inner::after{
	content: " ";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	-webkit-box-shadow: 0 0 0 0 currentColor;
	box-shadow: 0 0 0 0 currentColor;
	-webkit-animation: tp-pulse-btn-effect 3s infinite;
	animation: tp-pulse-btn-effect 3s infinite;
	opacity: .6;
	z-index: -1;
	-webkit-animation-delay: .9s;
	animation-delay: .9s;

}

.tp-pulse-btn-2::after{
	-webkit-animation-delay: .3s;
	animation-delay: .3s;
}

.tp-pulse-btn-2-inner::after{
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}



/* hambur btn */
.hamburger-btn{
	width: 34px;
	height: 24px;
	position: relative;
	cursor: pointer;
	background: transparent;
	border: 0;
	outline: 0;

	& span{
		display: inline-block;
		position: absolute;
		left: 0;
		background: var(--tp-common-white);
		height: 2px;
		width: 25px;
		opacity: 1;
		z-index: 1;
		border-radius: 10px;
		@extend %tp-transition;
		&:nth-child(1){
			top: 0;
		}
		&:nth-child(2){
			top: 10px;
			width: 14px;
			left: 50%;
			@include transform(translateX(-50%));
		}
		&:nth-child(3){
			top: 20px;
			right: 0;
			left: auto;
		}
	}

	&:hover{
		& span{
			
			&:nth-child(1){
				left: 10px;
			}
			
			&:nth-child(3){
				right: 10px;				
			}
		}
	}

	&-black{
		height: 15px;
		width: 30px;
		top: -4px;
		& span{
			background-color: var(--tp-common-black-2);

			&:nth-child(1){
				top: 0;
			}
			&:nth-child(2){
				top: 7px;
			}
			&:nth-child(3){
				top: 14px;
			}
		}

		&:hover{
			& span{
				background-color: var(--tp-theme-1);
			}
		}
	}

	&-brown{
		height: 15px;
		width: 30px;
		top: -4px;
		& span{
			background-color: var(--tp-theme-4);

			&:nth-child(1){
				top: 0;
			}
			&:nth-child(2){
				top: 7px;
			}
			&:nth-child(3){
				top: 14px;
			}
		}
	}

	&-red{
		height: 15px;
		width: 30px;
		top: -4px;
		& span{
			&:nth-child(1){
				top: 0;
			}
			&:nth-child(2){
				top: 7px;
			}
			&:nth-child(3){
				top: 14px;
			}
		}

		&:hover{
			& span{
				background-color: var(--tp-theme-10);
			}
		}
	}

	&-blue{
		&:hover{
			& span{
				background-color: var(--tp-theme-5);
			}
		}
	}

	&-text{
		position: relative;
		margin-left: 50px;
		width: 32px;
		height: 12px;
		top: -5px;
		&::after{
			position: absolute;
			content: attr(data-menu-text);
			top: 1px;
			left: -50px;
			font-size: 13px;
			font-weight: 600;
			color: var(--tp-common-black);
			text-transform: uppercase;
			font-family: var(--tp-ff-syne);
			line-height: 1;
			@extend %tp-transition;
		}

		& span{
			display: inline-block;
			position: absolute;
			left: 0;
			background: var(--tp-common-black);
			height: 2px;
			width: 23px;
			opacity: 1;
			z-index: 1;
			border-radius: 10px;
			@extend %tp-transition;
			&:nth-child(1){
				top: 0;
			}
			&:nth-child(2){
				top: 6px;
				width: 12px;
				left: 50%;
				@include transform(translateX(-50%));
			}
			&:nth-child(3){
				top: 12px;
				right: 0;
				left: auto;
				width: 24px;
			}
		}

		&:hover{
			color: var(--tp-common-white);
			&::after{
				color: var(--tp-common-white);
			}
			& span{
				background: var(--tp-common-white);
			}
		}
	}

	&-8{
		font-family: var(--tp-ff-space);
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: #FFFFFF;
		position: relative;
		cursor: pointer;
		padding-right: 35px;
		&::after,
		&::before{
			position: absolute;
			content: '';
			right: 0;
			bottom: 5px;
			width: 22px;
			height: 2px;
			background-color: var(--tp-common-white);
			border-radius: 5px;
			@extend %tp-transition;
		}

		&::after{
			top: 6px;
			bottom: auto;
			width: 10px;
		}

		&:hover{
			&::after{
				width: 22px;
			}
			&::before{
				width: 10px;
			}
		}

		&-black{
			font-family: var(--tp-ff-syne);
			color: var(--tp-common-black);
			&::after,
			&::before{
				background-color: var(--tp-common-black)
			}
		}
	}
	
}


.tp-link-btn-circle{

	& span{
		display: inline-block;
		position: relative;
		margin-left: 5px;
		width: 34px;
		height: 34px;
		line-height: 29px;
		text-align: center;
		border: 2px solid rgba($color: #03041C, $alpha: .1);
		border-radius: 50%;
		overflow: hidden;
		@include transform(translateY(12px));
		& i,
		& svg{
			transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
			&:last-child{
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translateY(-50%) translateX(-20px);
				opacity: 0;
			}
		}
	}

	&:hover{
		& span{
			& i,
			& svg{
				&:first-child{
					@include transform(translateX(15px));
					opacity: 0;
				}
				&:last-child{
					@include transform(translateY(-51%) translateX(-53%));
					opacity: 1;
				}
			}
		}
	}
}

.tp-link-btn-2{
	position: relative;
	display: inline-block;
	&::after,
	&::before{
		position: absolute;
		content: '';
		left: 0;
		bottom: 0;
		width: 100%;
		height: 1px;
		background-color: var(--tp-common-black);

		@include rtl{
			left: auto;
			right: 0;
		}
	}

	&::before{
		width: 0%;
		background-color: var(--tp-theme-1);
		transition: width .4s cubic-bezier(.34,1.56,.64,1);
		z-index: 1;
	}

	& span{
		margin-left: 2px;
		@include rtl{
			margin-left: 0;
			margin-right: 2px;
		}
	}
	& i,
	& svg{
		transition: transform .3s cubic-bezier(.25,1,.5,1);
	}
	& svg{
		@include transform(translateY(-2px));
	}

	&:hover{
		color: var(--tp-theme-1);
		&::before{
			width: 100%
		}
		& i{
			@include transform(translateX(5px));

			@include rtl{
				@include transform(translateX(-5px));
			}
		}
		& svg{
			
			@include transform(translate(5px,-2px));

			@include rtl{
				@include transform(translate(-5px,-2px));
			}
		}
	}

	&.tp-link-btn-white{
		color: rgba($color: #fff, $alpha: .7);
		padding-bottom: 3px;
		&::after{
			background-color:  rgba($color: #fff, $alpha: .7);
		}
		&::before{
			background-color: var(--tp-common-white);
		}
		&:hover{
			color: var(--tp-common-white);
		}
	}
}


.tp-custom-arrow{
	display: inline-block;
	position: relative;
	padding: 5px 0;
	min-width: 60px;
	&::after{
		position: absolute;
		content: '';
		width: 16px;
		height: 16px;
		background-color: transparent;
		border-top: 2px solid var(--tp-common-black-16);
		border-right: 2px solid var(--tp-common-black-16);
		@include transform(rotate(45deg));
		@extend %tp-transition;
		right: 0;
		top: 0;

		@include rtl{
			left: 0;
			right: auto;
			border-right: 0;
			border-left: 2px solid var(--tp-common-black-16);
		}
	}
	&::before{
		position: absolute;
		content: '';
		width: 20px;
		height: 2px;
		background-color: var(--tp-common-black-16);
		top: 7px;
		right: -1px;
		@extend %tp-transition;
	}

	&:hover{
		&::before{
			width: 60px;
		}
	}
}

/* link btn style 1 */
.tp-link-btn{
	
	& span{
		position: relative;
		margin-left: 5px;
		& i,
		& svg{
			transition: transform 0.2s cubic-bezier(0.77, 0, 0.175, 1), opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1);
			margin: 0;
			&:last-child{
				position: absolute;
				left: 0;
				top: 50%;
				@include transform(translateY(-48%) translateX(-15px));
				opacity: 0;
			}
		}
		& svg{
			&:first-child{
				@include transform(translateY(-1px));
			}
		}
	}

	&.tp-link-btn-arrow{
		& span{
			margin-left: 0;
		}
	}

	&:hover{
		& span{
			& i,
			& svg{
				&:first-child{
					@include transform(translateX(15px) translateY(-1px));
					opacity: 0;
				}
				&:last-child{
					@include transform(translateY(-48%) translateX(0));
					opacity: 1;
				}
			}
		}
	}
}

/* load more btn */
.tp-load-more-btn{
	display: inline-block;
	font-family: var(--tp-ff-space);
	font-weight: 500;
	font-size: 16px;
	letter-spacing: -0.02em;
	color: var(--tp-common-black);
	background: #FFFFFF;
	border: 1px solid #EAEAEF;
	padding: 13px 112px;

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-theme-1);
		border-color: var(--tp-theme-1);
	}

	& svg, 
	& i{
		margin-right: 8px;
	}

	& svg{
		@include transform(translateY(-2px));
	}

	@media #{$xs}{
		padding-left: 50px;
		padding-right: 50px;
	}
}


/* mouse scroll icon */
.mouse-scroll-icon{
    display: inline-block;
    width: 24px;
    height: 40px;
    border: 2px solid rgba($color: #03041C, $alpha: .2);
    border-radius: 30px;
    outline: none;
    position: relative;
    &:focus{
        border: 2px solid rgba($color: #03041C, $alpha: .2);
    }

    &::before{
        position: absolute;
        content: '';
        top: 7px;
        left: 8px;
        width: 4px;
        height: 8px;
        background: #414651;
        border-radius: 50%;
		animation: scroll 1s infinite ease-in-out alternate;
		-webkit-animation: scroll 1s infinite ease-in-out alternate;
    }

    &:hover{
        border-color: var(--tp-common-black-3);
    }

	&-4{
		@include transform(rotate(90deg) translate(1px, 23px)!important) ;
		border-color: rgba($color: #fff, $alpha: .2);

		&::before{
			position: absolute;
            content: '';
            top: 8px;
            left: 7px;
            width: 6px;
            height: 6px;
            background: #fff;
            border-radius: 50%;
			animation: scroll 1s infinite ease-in-out alternate;
			-webkit-animation: scroll 1s infinite ease-in-out alternate;
		}

		&:hover{
			border-color: var(--tp-common-white);
		}
		&:focus{
			border-color: var(--tp-common-white);
		}
	}
}

.mouse-scroll{
    position: absolute;
    bottom: 30px;
    left: 50%;
    @include transform(translateX(-50%));
    z-index: 1;
    &-btn{
        display: inline-block;
        width: 24px;
        height: 40px;
        border: 2px solid var(--tp-border-3);
        border-radius: 30px;
        outline: none;
        &:focus{
            border: 2px solid var(--tp-common-white);
        }

        &::before{
            position: absolute;
            content: '';
            top: 8px;
            left: 9px;
            width: 6px;
            height: 6px;
            background: #fff;
            border-radius: 50%;
			animation: scroll 1s infinite ease-in-out alternate;
			-webkit-animation: scroll 1s infinite ease-in-out alternate;
        }

        &:hover{
            border-color: var(--tp-common-white);
        }

        &-2{
            border-color: rgba($color: #03041C, $alpha: .2);
        }
    }

	&-8{
		left: 25%;
		bottom: 40px;

		@media #{$xs}{
			left: 7%;
		}
	}
}


/* theme btn */

.tp-btn{
	display: inline-block;
	font-size: 16px;
	font-weight: 700;
	color: var(--tp-common-white);
	background: var(--tp-theme-1);
	text-align: center;
	font-family: var(--tp-ff-space);
	padding: 10px 30px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	letter-spacing: -0.02em;

	& i,
	& svg{
		margin-left: 5px;

		@include rtl{
			margin-left: 0;
			margin-right: 5px;
		}
	}

	& svg{
		@include transform(translateY(-2px));
	}

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-common-purple);
	}
	&:focus{
		color: var(--tp-common-white);
	}

	&-black{
		background-color: var(--tp-common-black);
		color: var(--tp-common-white);
		font-size: 15px;
		padding: 8px 29px 10px;
		&:hover{
			background-color: var(--tp-theme-1);
			color: var(--tp-common-white);
		}
	}

}

.tp-btn-2{
	display: inline-block;
	font-size: 16px;
	font-weight: 700;
	color: var(--tp-common-white);
	background: var(--tp-theme-3);
	text-align: center;
	font-family: var(--tp-ff-space);
	padding: 17px 56px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	& i{
		padding-left: 5px;
	}

	&:hover{
		color: var(--tp-common-black-5);
		background-color: var(--tp-common-white);
	}
	&:focus{
		color: var(--tp-common-white);
	}

}

.tp-btn-3{
	display: inline-block;
	font-size: 16px;
	font-weight: 700;
	color: var(--tp-common-white);
	background: var(--tp-theme-3);
	text-align: center;
	font-family: var(--tp-ff-space);
	padding: 15px 40px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	text-transform: capitalize;
	border: 2px solid var(--tp-theme-3);
	& i{
		padding-left: 5px;
	}

	&:hover{
		color: var(--tp-common-black-5);
		background-color: var(--tp-common-white);
	}
	&:focus{
		color: var(--tp-common-white);
	}

	&.--hover-style-2{
		&:hover{
			color: var(--tp-common-white);
			border-color: rgba($color: #fff, $alpha: .14);
			background-color: transparent;
		}
	}

}

.tp-btn-4{
	display: inline-block;
	font-size: 18px;
	font-weight: 500;
	color: var(--tp-common-white);
	background: var(--tp-theme-8);
	text-align: center;
	font-family: var(--tp-ff-space);
	padding: 13px 41px 15px;
	position: relative;
	z-index: 1;
	& i{
		padding-left: 5px;
	}

	&:hover{
		color: var(--tp-common-black);
		background-color: var(--tp-common-white);
	}
	&:focus{
		color: var(--tp-common-white);
	}

}

.tp-btn-5{
	display: inline-block;
	font-family: var(--tp-ff-syne);
	font-weight: 700;
	font-size: 13px;
	text-transform: uppercase;
	color: var(--tp-common-white);
	background-color: var(--tp-common-black);
	padding: 17px 33px;
	position: relative;
	&::after{
		position: absolute;
		content: '';
		left: 8px;
		top: 8px;
		width: 100%;
		height: 100%;
		@include tp-gradient((329deg, #FF91FA 13.55%, #FA6490 48.54%, #F7D86A 86.44%));
		z-index: -1;
		@extend %tp-transition;
	}

	& i,
	& svg{
		margin-left: 5px;
	}
	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-common-black);

		&::after{
			top: 0;
			left: 0;
		}
	}

	&-white{
		padding: 14px 31px;
		background-color: var(--tp-common-white);
		color: var(--tp-common-black);

		&:hover{
			background-color: var(--tp-common-white);
			color: var(--tp-common-black);
		}
	}

}

.tp-btn-6{
	display: inline-block;
	font-family: var(--tp-ff-syne);
	font-weight: 700;
	font-size: 13px;
	text-transform: uppercase;
	color: var(--tp-common-white);
	background-color: var(--tp-common-black);
	padding: 15px 33px;
	border: 2px solid var(--tp-common-black);

	& i,
	& svg{
		margin-left: 5px;
	}
	&:hover{
		color: var(--tp-common-black);
		background-color: var(--tp-common-white);
	}
}

.tp-btn-7{
	display: inline-block;
	font-family: var(--tp-ff-space);
	font-weight: 500;
	font-size: 16px;
	text-align: center;
	color: var(--tp-common-white);
	box-shadow: 0px 6px 10px rgba(133, 24, 36, 0.3);
	border-radius: 6px;
	padding: 14px 31px 16px;
	@include tp-gradient((180deg, #FF3939 0.01%, #BF2132 100%));
	position: relative;
	z-index: 1;
	transition: transform .2s ease-in-out;

		
	& i,
	& svg{
		margin-left: 5px;
	}
	&:hover{
		color: var(--tp-common-white);
		@include transform(translateY(-4px));
	}

	&-sm{
		padding: 13px 36px 15px;
	}
}

.tp-btn-8{
	display: inline-block;
	font-family: var(--tp-ff-space);
	font-weight: 500;
	font-size: 16px;
	text-align: center;
	color: var(--tp-common-white);

	padding: 14px 33px 16px;
	background: var(--tp-common-purple-7);
	
	border-radius: 6px;
		
	& i,
	& svg{
		margin-left: 5px;
	}
	&:hover{
		color: var(--tp-common-white);
		background-position: 200% 200%;
		box-shadow: 0px 6px 10px rgba(99, 9, 19, 0.3);
	}
}

.tp-btn-9{
	display: inline-block;
	font-family: var(--tp-ff-syne);
	font-weight: 700;
	font-size: 13px;
	line-height: 1;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: var(--tp-common-black);
	border: 1px solid var(--tp-common-black);
	padding: 18px 30px 18px;
	& span{
		margin-left: 5px;
	}
	& svg{
		@include transform(translateY(-1px));
	}
	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-common-black);
	}
}

.tp-btn-10{
	display: inline-block;
	font-family: var(--tp-ff-syne);
	font-weight: 700;
	font-size: 14px;
	line-height: 1;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: var(--tp-common-white);
	border: 1px solid var(--tp-theme-1);
	background-color: var(--tp-theme-1);
	padding: 19px 30px 19px;
	& span{
		margin-left: 5px;
	}
	& svg{
		@include transform(translateY(-2px));
	}
	&:hover{
		color: var(--tp-common-black);
		background-color: var(--tp-common-white);
	}
}

.tp-btn-11{
	display: inline-block;
	font-weight: 500;
	font-size: 15px;
	color: var(--tp-common-black-11);
	background: var(--tp-common-white);
	box-shadow: 0px 1px 3px rgba(4, 18, 31, 0.1);
	border-radius: 25px;
	padding: 10px 34px;

	&:hover{
		background-color: var(--tp-theme-7);
		color: var(--tp-common-white);
	}

}

.tp-btn-green{
	display: inline-block;
	font-size: 16px;
	font-weight: 500;
	color: var(--tp-common-white);
	background: var(--tp-theme-2);
	text-align: center;
	font-family: var(--tp-ff-oswald);
	padding: 12px 42px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	text-transform: uppercase;
	& i{
		padding-left: 5px;
	}

	&:hover{
		color: var(--tp-common-white);
		background: var(--tp-theme-2-dark);
	}
	&:focus{
		color: var(--tp-common-white);
	}
}

.tp-btn-white{
	display: inline-block;
	font-size: 16px;
	font-weight: 400;
	color: var(--tp-common-black);
	background: var(--tp-common-white);
	text-align: center;
	font-family: var(--tp-ff-oswald);
	padding: 7px 29px 8px;
	position: relative;
	z-index: 1;
	text-transform: uppercase;
	box-shadow: 0px 2px 2px rgba(0, 128, 128, 0.1);
	& i{
		padding-left: 5px;
	}

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-theme-2);
		
	}
	&:focus{
		color: var(--tp-common-white);
	}
}

.tp-btn-white-2{
	display: inline-block;
	font-size: 15px;
	font-weight: 500;
	color: var(--tp-common-black-3);
	background: var(--tp-common-white);
	text-align: center;
	padding: 12px 31px;
	position: relative;
	z-index: 1;
	text-transform: capitalize;
	font-family: var(--tp-ff-roboto);
	
	& i{
		padding-left: 5px;

		@include rtl{
			padding-left: 0;
			padding-right: 5px;
		}
	}

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-theme-6);
		
	}
	&:focus{
		color: var(--tp-common-white);
	}
}

.tp-btn-white-sm{
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	color: var(--tp-common-black);
	background: var(--tp-common-white);
	text-align: center;
	padding: 8px 25px;
	position: relative;
	z-index: 1;
	
	border-radius: 8px;
	
	& i{
		padding-left: 5px;
	}

	&:hover{
		box-shadow: 0px 6px 10px rgba(21, 0, 133, 0.2);
		color: var(--tp-common-black);
	}
	&:focus{
		color: var(--tp-common-white);
	}
}

.tp-btn-purple{
	display: inline-block;
	font-size: 16px;
	font-weight: 400;
	color: var(--tp-common-white);
	background: var(--tp-common-purple-2);
	text-align: center;
	font-family: var(--tp-ff-oswald);
	padding: 7px 29px 8px;
	position: relative;
	z-index: 1;
	text-transform: uppercase;
	box-shadow: 0px 2px 2px rgba(0, 128, 128, 0.1);
	& i{
		padding-left: 5px;
	}

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-common-black);
		
	}
	&:focus{
		color: var(--tp-common-white);
	}
}

.tp-btn-brown{
	display: inline-block;
	font-size: 14px;
	color: var(--tp-common-white);
	padding: 11px 28px 13px;
	background-color: var(--tp-theme-4);
	font-weight: 600;
	letter-spacing: -0.02em;

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-theme-4-dark);
	}
}

.tp-btn-orange{
	display: inline-block;
	font-size: 15px;
	color: var(--tp-common-white);
	padding: 12px 30px 12px;
	background-color: var(--tp-common-orange);
	font-weight: 500;
	letter-spacing: -0.02em;
	text-align: center;
	border-radius: 8px;

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-theme-5);
	}
}

.tp-btn-orange-2{
	display: inline-block;
	font-size: 16px;
	color: var(--tp-common-white);
	padding: 22px 52px 21px;
	background-color: var(--tp-common-orange-2);
	font-weight: 600;
	letter-spacing: -0.02em;
	text-align: center;
	border-radius: 8px;
	
	&:hover{
		color: var(--tp-common-black);
		background-color: var(--tp-common-white);
		box-shadow: 0px 6px 10px rgba(4, 10, 72, 0.2);
	}
}

.tp-btn-blue{
	display: inline-block;
	font-size: 16px;
	font-weight: 500;
	color: var(--tp-common-white);
	padding: 16px 45px 18px;
	background-color: var(--tp-theme-5);
	letter-spacing: -0.02em;
	text-align: center;
	border-radius: 8px;

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-common-black);
	}
}

// home 6
.tp-btn-blue-2{
	display: inline-block;
	font-size: 15px;
	font-weight: 500;
	color: var(--tp-common-white);
	background-color: var(--tp-theme-5);
	padding: 9px 24px;
	font-family: var(--tp-ff-roboto);

	& span{
		margin-left: 4px;
	}

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-common-black-9);
	}
}

.tp-btn-blue-sm{
	display: inline-block;
	font-size: 15px;
	color: var(--tp-common-white);
	padding: 11px 30px 13px;
	background-color: var(--tp-theme-5);
	font-weight: 500;
	letter-spacing: -0.02em;
	text-align: center;
	border-radius: 8px;

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-common-black);
	}
}

.tp-btn-grey{
	display: inline-block;
	font-size: 15px;
	font-weight: 500;
	color: var(--tp-common-black);
	padding: 9px 45px 9px;
	background-color: var(--tp-grey-10);
	text-align: center;
	border-radius: 8px;

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-theme-5);
	}
}

.tp-header-btn{
	display: inline-block;
	font-size: 16px;
	font-weight: 700;
	color: var(--tp-common-white);
	font-family: var(--tp-ff-space);
	text-transform: capitalize;
	padding: 22px 36px 23px;
	background-color: var(--tp-theme-3);

	&:hover{
		color: var(--tp-common-black-5);
		background-color: var(--tp-common-white);
	}
}

.tp-header-btn-2{
	display: inline-block;
	font-size: 16px;
	font-weight: 400;
	color: var(--tp-common-black);
	text-transform: capitalize;

	& i{
		margin-right: 5px;
	}
	
	&:hover{
		color: var(--tp-theme-7);
	}
}

.tp-header-btn-3{
	display: inline-block;
	font-family: var(--tp-ff-space);
	font-weight: 500;
	font-size: 16px;
	text-align: center;
	color: var(--tp-common-white);
	border: 2px solid rgba($color: #fff, $alpha: .14);
	border-radius: 6px;
	padding: 8px 30px 9px;

	& i{
		margin-right: 5px;
	}
	
	&:hover{
		color: var(--tp-theme-10);
		background-color: var(--tp-common-white);
		border-color: var(--tp-common-white);
	}
}

.tp-btn-app{
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	color: var(--tp-text-3);
	padding: 0 16px;
	border: 2px solid var(--tp-border-1);
	text-align: center;
	min-width: 110px;

	& svg{
		@include transform(translateY(-2px));
		margin-right: 2px;
	}

	&:hover{
		color: var(--tp-common-black);
		background-color: var(--tp-common-white);
		border-color: var(--tp-common-white);
	}
}

.tp-btn-border{
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	color: var(--tp-common-black);
	font-family: var(--tp-ff-space);
	padding: 6px 18px;
	border: 1px solid var(--tp-border-5);
	text-align: center;

	& i{
		margin-left: 7px;

		@include rtl{}
		margin-left: 0;
		margin-right: 7px;
	}

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-common-black);
		border-color: var(--tp-common-black);
	}
}

.tp-btn-border-2{
	display: inline-block;
	font-size: 16px;
	font-weight: 700;
	font-family: var(--tp-ff-space);
	color: var(--tp-common-white);
	padding: 14px 37px 16px;
	border: 2px solid rgba(255, 255, 255, 0.14);
	text-transform: capitalize;

	& i{
		margin-left: 7px;
	}

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-theme-3);
		border-color: var(--tp-theme-3);
	}
}

.tp-btn-border-3{
	display: inline-block;
	font-size: 18px;
	font-weight: 700;
	font-family: var(--tp-ff-space);
	color: var(--tp-common-white);
	padding: 14px 48px 16px;
	border: 2px solid rgba(255, 255, 255, 0.14);
	text-transform: capitalize;

	& i{
		margin-left: 7px;
	}

	&:hover{
		color: var(--tp-common-black);
		background-color: var(--tp-common-white);
		border-color: var(--tp-common-white);
	}
}

.tp-btn-border-4{
	display: inline-block;
	font-size: 16px;
	font-weight: 700;
	font-family: var(--tp-ff-space);
	color: var(--tp-common-white);
	padding: 11px 43px 13px;
	border: 2px solid rgba(255, 255, 255, 0.14);
	text-transform: capitalize;

	& i{
		margin-left: 7px;
	}

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-theme-3);
		border-color: var(--tp-theme-3);
	}
}

.tp-btn-border-5{
	display: inline-block;
	font-size: 15px;
	font-weight: 500;
	color: var(--tp-common-black-9);
	padding: 10px 25px 10px 26px;
	border: 2px solid #0D121E1A;
	text-transform: capitalize;
	font-family: var(--tp-ff-roboto);

	& span{
		margin-left: 3px;
	}

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-theme-6);
		border-color: var(--tp-theme-6);
	}
}

.tp-btn-border-6{
	display: inline-block;
	font-size: 15px;
	font-weight: 500;
	color: var(--tp-common-black-9);
	padding: 10px 24px 11px 24px;
	border: 2px solid #0D121E1A;
	text-transform: capitalize;

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-theme-6);
		border-color: var(--tp-theme-6);
	}
}

.tp-btn-border-7{
	display: inline-block;
	padding: 11px 31px 11px 31px;
	border: 1.5px solid #FFFFFF1A;
	font-family: var(--tp-ff-space);
	font-weight: 500;
	font-size: 16px;
	color: var(--tp-common-white);

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-theme-8);
		border-color: var(--tp-theme-8);
	}
}

.tp-btn-border-8{
	display: inline-block;
	padding: 14px 33px 16px 33px;
	border: 2px solid #FFFFFF1A;
	font-family: var(--tp-ff-space);
	font-weight: 500;
	font-size: 16px;
	color: var(--tp-common-white);
	letter-spacing: -0.02em;

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-theme-8);
		border-color: var(--tp-theme-8);
	}
}

.tp-btn-border-9{
	display: inline-block;
	font-family: var(--tp-ff-space);
	font-weight: 500;
	font-size: 15px;
	text-align: center;
	color: var(--tp-common-black);
	border: 2px solid #EAEAEF;
	position: relative;
	padding: 4px 30px 6px;
	border-radius: 6px;
	z-index: 1;
	&::after{
		position: absolute;
		content: '';
		left: -2px;
		top: -2px;
		width: calc(100% + 4px);
		height: calc(100% + 4px);
		@include tp-gradient((180deg, #FF3939 0.01%, #BF2132 100%));
		border-radius: 6px;
		z-index: -1;
		@extend %tp-transition;
		opacity: 0;
		visibility: hidden;
	}
	&:hover{
		color: var(--tp-common-white);
		box-shadow: 0px 4px 10px rgba(150, 21, 35, 0.3);
		@include transform(translateY(-4px));
		&::after{
			opacity: 1;
			visibility: visible;
		}
	}
}

.tp-btn-border-pink{
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	color: var(--tp-common-black);
	font-family: var(--tp-ff-space);
	padding: 6px 23px 6px;
	border: 1px solid var(--tp-border-5);
	letter-spacing: -0.02em;

	& i,
	& svg{
		margin-left: 7px;

		@include rtl{
			margin-left: 0;
			margin-right: 7px;
		}
	}

	& svg{

		@include transform(translateY(-2px));
	}

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-theme-1);
		border-color: var(--tp-theme-1);
	}
}

.tp-btn-border-pink-2{
	display: inline-block;
	font-size: 16px;
	font-weight: 700;
	color: var(--tp-theme-3);
	font-family: var(--tp-ff-space);
	padding: 17px 35px;
	border: 2px solid var(--tp-theme-3);
	border-radius: 2px;

	& i{
		margin-left: 7px;
	}

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-theme-3);
		border-color: var(--tp-theme-3);
	}
}

.tp-btn-border-green{
	display: inline-block;
	font-size: 16px;
	font-weight: 400;
	color: var(--tp-common-black);
	background: transparent;
	text-align: center;
	font-family: var(--tp-ff-oswald);
	padding: 5px 26px 6px;
	position: relative;
	z-index: 1;
	text-transform: uppercase;
	border: 2px solid var(--tp-border-8);
	& i{
		padding-left: 5px;
	}

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-theme-2);
		border-color: var(--tp-theme-2);
		
	}
	&:focus{
		color: var(--tp-common-white);
	}
}

.tp-btn-border-green-2{
	display: inline-block;
	font-size: 16px;
	font-weight: 400;
	color: var(--tp-common-black);
	background: transparent;
	text-align: center;
	font-family: var(--tp-ff-oswald);
	padding: 5px 34px 6px;
	position: relative;
	z-index: 1;
	text-transform: uppercase;
	border: 2px solid var(--tp-border-8);
	& i{
		padding-left: 5px;
	}

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-theme-2);
		border-color: var(--tp-theme-2);
		
	}
	&:focus{
		color: var(--tp-common-white);
	}
}

.tp-btn-border-brown{
	display: inline-block;
	font-size: 14px;
	font-weight: 700;
	color: var(--tp-common-black);
	background: transparent;
	text-align: center;
	font-family: var(--tp-ff-space);
	padding: 10px 31px;
	letter-spacing: -0.02em;
	position: relative;
	z-index: 1;
	text-transform: capitalize;
	border: 2px solid var(--tp-border-5);
	& i{
		padding-left: 5px;
	}

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-theme-4);
		border-color: var(--tp-theme-4);
		
	}
	&:focus{
		color: var(--tp-common-black);
	}
}

.tp-btn-border-brown-sm{
	display: inline-block;
	font-size: 14px;
	font-weight: 700;
	color: var(--tp-common-black);
	background: transparent;
	text-align: center;
	font-family: var(--tp-ff-space);
	padding: 5px 23px;
	letter-spacing: -0.02em;
	position: relative;
	z-index: 1;
	text-transform: capitalize;
	border: 2px solid var(--tp-border-5);
	& i{
		padding-left: 5px;
	
		@include rtl{
			padding-left: 0;
			padding-right: 5px;
		}
	}

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-theme-4);
		border-color: var(--tp-theme-4);
		
	}
	&:focus{
		color: var(--tp-common-black);
	}
}

.tp-btn-transparent{
	display: inline-block;
	font-size: 14px;
	font-weight: 700;
	color: var(--tp-common-white);
	background: transparent;
	text-align: center;
	font-family: var(--tp-ff-space);
	padding: 9px 31px 10px;
	letter-spacing: -0.02em;
	position: relative;
	z-index: 1;
	text-transform: capitalize;
	border: 2px solid rgba($color: #fff, $alpha: .1);
	& i{
		padding-left: 5px;

		@include rtl{
			padding-left: 0;
			padding-right: 5px;
		}
	}

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-theme-4);
		border-color: var(--tp-theme-4);
		
	}
	&:focus{
		color: var(--tp-common-white);
		background-color: var(--tp-theme-4);
		border-color: var(--tp-theme-4);
	}
}

.tp-btn-round{
	display: inline-block;
	color: var(--tp-theme-5);
	background-color: var(--tp-grey-10);
	font-size: 15px;
	font-weight: 500;
	text-align: center;
	padding: 12px 82px;
	border-radius: 8px;
	position: relative;
	z-index: 1;
	& i{
		padding-left: 5px;
		@include rtl{
			padding-left: 0;
			padding-right: 5px;
		}
	}

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-theme-5);		
	}
	&:focus{
		color: var(--tp-common-black);
	}
}

.tp-btn-offcanvas{
	display: inline-block;
	font-size: 14px;
	font-weight: 600;
	color: var(--tp-common-white);
	background: var(--tp-theme-1);
	text-align: center;
	font-family: var(--tp-ff-space);
	padding: 9px 22px;
	letter-spacing: -0.02em;
	position: relative;
	z-index: 1;
	text-transform: capitalize;

	& i{
		padding-left: 5px;
		@include rtl{
			padding-left: 0;
			padding-right: 5px;
		}
	}
	&:hover{
		background-color: var(--tp-common-black);
		color: var(--tp-common-white);
	}
}

.tp-btn-offcanvas-green{
	display: inline-block;
	font-size: 16px;
	font-weight: 400;
	color: var(--tp-common-white);
	background-color: var(--tp-theme-2);
	text-align: center;
	font-family: var(--tp-ff-oswald);
	text-transform: uppercase;
	padding: 9px 27px;
	letter-spacing: -0.02em;
	position: relative;
	z-index: 1;


	& i{
		padding-left: 5px;
		@include rtl{
			padding-left: 0;
			padding-right: 5px;
		}
	}
	&:hover{
		background-color: var(--tp-theme-2-dark);
		color: var(--tp-common-white);
	}
}

.tp-btn-offcanvas-pink{
	display: inline-block;
	font-size: 16px;
	font-weight: 600;
	color: var(--tp-common-white);
	background: var(--tp-theme-1);
	text-align: center;
	font-family: var(--tp-ff-space);
	text-transform: capitalize;
	padding: 11px 42px 13px;
	letter-spacing: -0.02em;
	position: relative;
	z-index: 1;

	& i{
		padding-left: 5px;
		@include rtl{
			padding-left: 0;
			padding-right: 5px;
		}
	}
	&:hover{
		background-color: var(--tp-common-white);
		color: var(--tp-common-black);
	}
}

.tp-btn-offcanvas-white{
	display: inline-block;
	font-size: 16px;
	font-weight: 500;
	color: var(--tp-common-black);
	background: var(--tp-common-white);
	text-align: center;
	font-family: var(--tp-ff-space);
	text-transform: capitalize;
	padding: 12px 42px;
	letter-spacing: -0.02em;
	position: relative;
	z-index: 1;
	border-radius: 6px;

	& i{
		padding-left: 5px;
		@include rtl{
			padding-left: 0;
			padding-right: 5px;
		}
	}
	&:hover{
		background-color: var(--tp-common-black);
		color: var(--tp-common-white);
	}
}

.tp-btn-offcanvas-blue{
	display: inline-block;
	font-size: 14px;
	font-weight: 600;
	color: var(--tp-common-white);
	background: var(--tp-theme-5);
	text-align: center;
	text-transform: capitalize;
	padding: 8px 37px 10px;
	letter-spacing: -0.02em;
	position: relative;
	z-index: 1;
	border-radius: 6px;

	& i{
		padding-left: 5px;
		@include rtl{
			padding-left: 0;
			padding-right: 5px;
		}
	}
	&:hover{
		background-color: var(--tp-common-black);
		color: var(--tp-common-white);
	}
}

// 

.tp-btnr{
	display: inline-block;
	font-weight: 500;
	font-size: 16px;
	text-align: center;
	color: var(--tp-common-white);
	border-radius: 30px;
	padding: 10px 35px 10px;
	background-color: var(--tp-theme-7);
	overflow: hidden;
	position: relative;
	z-index: 1;
	&:after {
		background: var(--tp-common-white);
		content: "";
		height: 155px;
		left: -75px;
		opacity: .2;
		position: absolute;
		top: -50px;
		@include transform(rotate(35deg));
		transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
		width: 50px;
		z-index: -1;
	}

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-common-black);
		&:after {
			left: 76%;
		}
	}
}

.tp-btn-shine-effect{
	&::after {
		position: absolute;
		content: "";	
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(255,255,255,.5);
		-webkit-transition: all .4s;
		transition: all .4s;
		-webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,-45deg) translate3d(0,-150%,0);
		transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,-45deg) translate3d(0,-150%,0);
	}

	&:hover{
		&::after {
			-webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,-45deg) translate3d(0,150%,0);
			transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,-45deg) translate3d(0,150%,0);
		}
	}
	&.effect-delay-3{
		&::after{
			transition: all .6s;
			transition-delay: .3s;
		}
	}
}



.tp-btnr-2{
	display: inline-block;
	font-weight: 500;
	font-size: 15px;
	text-align: center;
	color: var(--tp-common-white);
	border-radius: 30px;
	padding: 10px 29px 10px;
	background-color: var(--tp-theme-7);
	overflow: hidden;
	position: relative;
	z-index: 1;
	border: 2px solid var(--tp-theme-7);
	

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-common-black);
		border-color: var(--tp-common-black);
		
	}
}

.tp-btnr-yellow{
	display: inline-block;
	font-weight: 500;
	font-size: 15px;
	text-align: center;
	color: var(--tp-common-black-11);
	border-radius: 30px;
	padding: 13px 39px 11px;
	background-color: var(--tp-common-yellow-3);
	overflow: hidden;
	position: relative;
	z-index: 1;
}

.tp-btnr-border{
	display: inline-block;
	font-weight: 500;
	font-size: 15px;
	text-align: center;
	color: var(--tp-common-black-11);
	border-radius: 30px;
	padding: 13px 39px 14px;
	padding-right: 39px;
	background-color: transparent;
	overflow: hidden;
	position: relative;
	z-index: 1;
	border: 2px solid rgba(4, 18, 31, 0.1);
	border-radius: 30px;

	& i{
		margin-left: 5px;
	}

	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-common-black);
		border-color: var(--tp-common-black-11);
	}
}

.tp-btnr-border-2{
	display: inline-block;
	font-weight: 500;
	font-size: 15px;
	text-align: center;
	color: var(--tp-common-black-11);
	border-radius: 30px;
	padding: 10px 33px 10px 36px;
	background-color: transparent;
	overflow: hidden;
	position: relative;
	z-index: 1;
	border: 2px solid rgba(4, 18, 31, 0.1);
	border-radius: 30px;

	& i{
		margin-left: 5px;
	}


	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-common-black);
		border-color: var(--tp-common-black-11);
	}
}


.tp-cta-btn{
	display: inline-block;
	font-weight: 600;
	font-size: 18px;
	line-height: 1.4;
	color: var(--tp-common-white);
	background-color: var(--tp-theme-7);
	border-radius: 30px;
	padding: 35px 40px 32px 35px;

	@media #{$lg}{
		padding: 35px 25px 32px 25px;
	}

	& i{
		margin-left: 17px;
		font-size: 26px;

		@include rtl{
			margin-left: 0;
			margin-right: 17px;
		}
	}
	&:hover{
		background-color: var(--tp-common-black-11);
		color: var(--tp-common-white);
	}
}


.tp-cta-btn-yellow{
	display: inline-block;
	font-weight: 600;
	font-size: 18px;
	line-height: 1.4;
	color: var(--tp-common-black-11);
	background-color: var(--tp-common-yellow-3);
	border-radius: 30px;
	padding: 35px 40px 32px 35px;

	& i{
		margin-left: 17px;
		font-size: 26px;

		@include rtl{
			margin-left: 0;
			margin-right: 17px;
		}
	}
	&:hover{
		background-color: var(--tp-common-black-11);
		color: var(--tp-common-white);
	}
}



.tp-link-btn-3{
	& span{
		margin-left: 5px;

		@include rtl{
			margin-left: 0;
			margin-right: 5px;
		}
	}
	& i,
	& svg{
		margin-left: 0;
	}
	&:hover{
		& i{
			animation: tp_arrow .2s linear;
		}
		& svg{
			animation: tp_arrow_svg .2s linear;
		}
	}
}

.tp-link-btn-4{
	& span{
		margin-left: 0;
	}
	& i,
	& svg{
		margin-left: 0;
	}
	&:hover{
		& i{
			animation: tp_arrow .2s linear;
		}
		& svg{
			animation: tp_arrow_svg .2s linear;
		}
	}
}

.tp-button-demo{
	& .tp-link-btn-2{
		color: var(--tp-common-black);

		&:hover{
			color: var(--tp-theme-1);
		}
	}

	& .tp-link-btn-circle{
		color: var(--tp-common-black);
	}
}