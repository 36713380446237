@use '../utils' as *;

/*----------------------------------------*/
/*  26. TICKET CSS START
/*----------------------------------------*/

.ticket{
    &__shape{
        &-1{
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
        &-2{
            position: absolute;
            top: 0;
            right: 40%;
            z-index: -1;
        }
        &-3{
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: -1;
        }
    }
    &__wrapper{
        padding: 20px 50px;
        position: relative;
        z-index: 1;
        border-radius: 14px;
        overflow: hidden;

        @media #{$xs}{
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }
    &__title{
        font-family: var(--tp-ff-space);
        font-weight: 300;
        font-size: 20px;
        letter-spacing: -0.04em;
        color: #FFFFFF;
        margin-bottom: 0;
    }
    &__content{
        & p{
            font-family: var(--tp-ff-space);
            font-weight: 700;
            font-size: 20px;
            letter-spacing: -0.04em;
            color: #FFFFFF;
            margin-bottom: 0;
            line-height: 1;
        }
    }
    &__inner{
        @media #{$sm, $xs}{
            margin-bottom: 30px;
        }
    }
    &__icon{
        @include rtl{
            margin-right: 0;
            margin-left: 15px;
        }
        @media #{$xs}{
            margin-bottom: 20px;
        }
    }
}