@use '../utils' as *;

/*----------------------------------------*/
/*  NAV TAB CSS START
/*----------------------------------------*/

.tp-tab{
    & .nav-tabs{
        padding: 0;
        margin: 0;
        border: 0;

    }
    & .nav-item{
        padding: 0;
        margin: 0;
        border: 0;
    }
    & .nav-link{
        padding: 0;
        margin: 0;
        border: 0;
    }
}