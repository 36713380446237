@use '../utils' as *;

/*----------------------------------------*/
/*  16. SKILL CSS START
/*----------------------------------------*/

.skill{
    $self : &;
    &__item{
        @media #{$xs}{
            flex-wrap: wrap;
        }

        &-9{
            margin-bottom: 56px;
        }
        &-wrapper{
            &-9{
                & .row{
                    & [class*="col-"]{
                        &:nth-child(2n){
                            #{$self}{
                                &__item-9{
                                    padding-left: 20px;

                                    @include rtl{
                                        padding-left: 0;
                                        padding-right: 20px;
                                    }
                                }
                            }
                        }
                        &:last-child{
                            #{$self}{
                                &__item-9{
                                    margin-bottom: 0;
                                }
                            }
                        }
                        &:nth-last-child(2){
                            #{$self}{
                                &__item-9{
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &__single{
        padding: 45px 0 35px;
        border-radius: 200px;
        background-color: var(--tp-common-white);
        box-shadow: 0px 2px 3px rgba(22, 34, 19, 0.1);
        width: 130px;

        @include rtl{
            margin-right: 0;
            margin-left: 20px;
        }
        
        @media #{$xs}{
            width: 120px;
            margin-right: 10px;

            @include rtl{
                margin-right: 0;
                margin-left: 10px;
            }
        }
    }
    &__icon{
        margin-bottom: 17px;

        &-9{
            margin-bottom: 13px;
            & span{
                display: inline-block;
                width: 80px;
                height: 80px;
                line-height: 80px;
                text-align: center;
                background-color: var(--tp-common-white);
                box-shadow: 0px 1px 2px rgba(3, 4, 28, 0.08);
                border-radius: 50%;
            }
        }
    }
    &__content{
        & h4{
            font-size: 22px;
            color: var(--tp-text-6);
            font-family: var(--tp-ff-oswald);
            margin-bottom: 0;
            font-weight: 400;
        }

        &-9{
            & h4{
                font-family: var(--tp-ff-syne);
                font-weight: 400;
                font-size: 20px;
                line-height: 1.2;
                color: #525258;

                @media #{$xs}{
                    font-size: 16px;
                }

                & span{
                    font-weight: 500;
                    color: var(--tp-common-black);
                }
            }
        }
    }
    &__style-2{
        #{$self}{
            &__content-9{
                & h4{
                    font-family: var(--tp-ff-space);
                }
            }
        }
    }

    &__wrapper{
        &-14{
            & .skill-section-title{
                font-size: 50px;
                line-height: 1;
                letter-spacing: -0.04em;
                margin-bottom: 13px;

                @media #{$lg, $xs}{
                    font-size: 40px;
                }
            }
            & p{
                font-size: 16px;
            }
        }
    }
    &__bar{
        padding-top: 6px;
        &-title{
            font-weight: 500;
            font-size: 14px;
            color: var(--tp-common-black-solid);
            margin-bottom: 11px;

            & span{
                color: var(--tp-text-1);
                font-size: 14px;
                min-width: 21px;
                text-align: left;
                display: inline-block;

                @include rtl{
                    text-align: right;
                }

                & svg{
                    @include transform(translateY(-2px));
                }
            }
        }
        &-item{
            margin-bottom: 35px;
            & .progress{
                height: 30px;
                background-color: #F0EFF0;
                border-radius: 20px;
                overflow: visible;
                & .progress-bar{
                    background-color: #50CD57;
                    border-radius: 30px;
                    position: relative;
                    overflow: visible;
                    & span{
                        position: absolute;
                        right: 0;
                        top: 0;
                        transform: translateX(50%);
                        font-family: var(--tp-ff-space);
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 30px;
                        color: var(--tp-common-black-solid);
                        width: 36px;
                        height: 30px;
                        border-radius: 20px;
                        background-color: var(--tp-common-white);
                        box-shadow: 0px 1px 4px rgba(3, 4, 28, 0.1);

                        @include rtl{
                            right: auto;
                            left: 0;
                        }
                    }
                }
            }
            &.is-blue{
                & .progress{
                    & .progress-bar{
                        background-color: #29B4EF;
                    }
                }
            }
            &.is-brown{
                & .progress{
                    & .progress-bar{
                        background-color: var(--tp-theme-4);
                    }
                }
            }
            &.is-pink{
                & .progress{
                    & .progress-bar{
                        background-color: var(--tp-theme-1);
                    }
                }
            }
        }
        &-progress{
            overflow: hidden;
            border-radius: 30px;
        }
        &-count{
            display: inline-block;
            font-family: var(--tp-ff-space);
            font-weight: 500;
            font-size: 15px;
            color: var(--tp-common-black-solid);
            margin-bottom: 11px;
        }
    }
}

.career-link-btn{
    display: inline-block;
    font-size: 18px;
    color: var(--tp-common-black);
    &:hover{
        color: var(--tp-theme-2);
    }
}

.career{
    $self:&;
    &__wrapper{
        @media #{$lg}{
            padding-left: 0;

            @include rtl{
                padding-right: 0;
            }
        }
        @media #{$md, $sm, $xs}{
            padding-left: 0;
            margin-top: 50px;

            @include rtl{
                padding-right: 0;
            }
        }
    }
    &__item{
        padding: 43px 40px 37px;
        box-shadow: 0px 1px 3px rgba(22, 34, 19, 0.1), 0px 6px 0px #EDEDED;
        margin-bottom: 23px;

        @media #{$lg}{
            padding: 32px 15px;
        }

        @media #{$sm}{
            padding: 32px 10px;
        }
        &:last-child{
            margin-bottom: 0;
        }
        &:hover{
            position: relative;
            z-index: 1;
            box-shadow: 0px 30px 50px rgba(3, 4, 28, 0.1), 0px 6px 0px #008080;
        }
    }
    &__logo{
        width: 50px;

        @include rtl{
            margin-right: 0;
            margin-left: 20px;
        }
        
        @media #{$xs}{
            margin-bottom: 15px;
        }

        & span{
            font-size: 50px;
            color: black;
            // color: var(--tp-theme-2);
        }
    }
    &__info{
        // width: 70%;
        @media #{$xs}{
            margin-bottom: 15px;
            width: 100%;
        }
        &-title{
            font-size: 24px;
            font-weight: 500;
            font-family: var(--tp-ff-oswald);
            margin-bottom: 4px;
        }
        &-designation{
            font-size: 16px;
            font-family: var(--tp-ff-inter);
            color: var(--tp-text-6);
        }
    }
    &__year{
        width: 46%;
        @media #{$xs}{
            width: 100%;
        }
        &-info{
            & p{
                font-size: 15px;
                font-family: var(--tp-ff-inter);
                color: var(--tp-text-6);
                margin-bottom: 0;
                position: relative;
                padding-left: 14px;
                display: inline-block;

                @include rtl{
                    padding-left: 0;
                    padding-right: 14px;
                }
                &::after{
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 12px;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: var(--tp-text-6);

                    @include rtl{
                        left: auto;
                        right: 0;
                    }
                }
            }
        }
    }
    &__btn{
        margin-bottom: 5px;
    }
    &__style-2{
        padding-top: 43px;
        #{$self}{
            &__title{
                font-family: var(--tp-ff-syne);
                font-weight: 700;
                font-size: 30px;
                line-height: 1;
                letter-spacing: -0.04em;
                color: var(--tp-common-black);
                margin-bottom: 25px;
               
            }
            &__item{
                padding: 23px 30px;
                box-shadow: 0px 1px 2px rgba(3, 4, 28, 0.08);
                margin-bottom: 10px;

                &:last-child{
                    margin-bottom: 0;
                }
                &:hover{
                    box-shadow: 0px 20px 20px rgba(3, 4, 28, 0.08);
                    position: relative;
                    z-index: 1;
                }
            }
            &__logo{
                & span{
                    display: inline-block;
                    width: 54px;
                    height: 54px;
                    line-height: 52px;
                    text-align: center;
                    border: 1px solid #EAEAEF;
                    border-radius: 50%;
                    & img{
                        @include transform(translateY(-5px));
                    }
                }
            }
            &__info{
                &-title{
                    font-family: var(--tp-ff-syne);
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 1;
                    color: var(--tp-common-black);
                }
                &-designation{
                    font-family: var(--tp-ff-syne);
                    font-weight: 400;
                    font-size: 16px;
                    line-height: .62;
                    color: #525258;
                }
            }
            &__year{
                &-info{
                    & p{
                        font-family: var(--tp-ff-syne);
                        font-weight: 400;
                        font-size: 16px;
                        color: #525258;
                    }
                }
            }
            &__btn{
                & .career-link-btn{
                   &:hover{
                        color: #FA6490;
                   }
                }
            }
        }
    }
    &__style-3{
        & #{$self}{
            &__title{
                font-family: var(--tp-ff-space);
            }
            &__info{
                &-title{
                    font-family: var(--tp-ff-space);
                }
                &-designation{
                    font-family: var(--tp-ff-space);
                }
            }
            &__year{
                &-info{
                    & p{
                        font-family: var(--tp-ff-space);
                    }
                }
            }

            &__btn{
                
                & .career-link-btn:hover{
                    color: var(--tp-theme-1) !important;
                }
            }
        }
    }
}
