@use '../utils' as *;

/*----------------------------------------*/
/*  KEYFRAME CSS START
/*----------------------------------------*/

/* pulse effect animation */

@-webkit-keyframes pulse {
	0% {
	  -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
      box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
	}
	70% {
		-webkit-box-shadow: 0 0 0 45px rgba(255,255,255, 0);
        box-shadow: 0 0 0 45px rgba(255,255,255, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
        box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	}
}
@keyframes pulse {
0% {
	-moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
	box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
}
70% {
	-moz-box-shadow: 0 0 0 45px rgba(255,255,255, 0);
	box-shadow: 0 0 0 45px rgba(255,255,255, 0);
}
100% {
	-moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	}
}


// mouse scoll animation
@include keyframes(scroll){
	0% {
        @include transform(translateY(0px));
    }
    100% {
        @include transform(translateY(16px));
    }
}


// about text animation
@include keyframes(about-text){
	0% {
        width: 0;
    }

    100% {
		width: 100%;
    }
}


// faq text animation
@include keyframes(faq-text){
	0% {
        width: 0;
    }

    100% {
		width: 100%;
    }
}


// slider title 3 gradint animation
@include keyframes(sliderTitle3){
	0% {background-position: 0%}
    100% {background-position: 100%}
}

// slider title pre 3 gradint animation
@include keyframes(sectionPreTitle){
	0% {background-position: 0%}
    100% {background-position: 100%}
}

@include keyframes(aboutGradient){
	0% {background-position: 0%}
    100% {background-position: 100%}
}


// tp rotate animation
@include keyframes(tprotate){
	0%{
		@include transform(rotate(0deg));
	}
	100%{
		@include transform(rotate(360deg));
	}
}

// tp translate vertical animation
@include keyframes(tptranslateY){
	0%{
		@include transform(translateY(-50px));
	}
	100%{
		@include transform(translateY(100px));
	}
}

// tp translate vertical animation
@include keyframes(tptranslateY2){
	0%{
		@include transform(translateY(-50px));
	}
	100%{
		@include transform(translateY(20px));
	}
}

// tp translate horizontal animation
@include keyframes(tptranslateX){
	0%{
		@include transform(translateX(-80px));
	}
	100%{
		@include transform(translateX(30px));
	}
}

// tp translate horizontal animation
@include keyframes(tptranslateX2){
	0%{
		@include transform(translateX(-40px));
	}
	100%{
		@include transform(translateX(0px));
	}
}


@include keyframes(pulse-2){
    0% {
		-moz-box-shadow: 0 0 0 0 rgba(21, 165, 150, 0.1);
		box-shadow: 0 0 0 0 rgba(21, 165, 150, 0.1);
	}
	70% {
		-moz-box-shadow: 0 0 0 20px rgba(21, 165, 150, 0);
		box-shadow: 0 0 0 20px rgba(21, 165, 150, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(21, 165, 150, 0);
		box-shadow: 0 0 0 0 rgba(21, 165, 150, 0);
	}
}



@include keyframes(gadientbg){
	0% {background-position: 0%}
    100% {background-position: 100%}
}

// tp rotate animation
@include keyframes(borderRadius){
	0%{
		border-radius: 65% 35% 50% 50% / 59% 50% 50% 41%;
	}
	100%{
		border-radius: 33% 67% 43% 57% / 69% 24% 76% 31% ;
	}
}


// tp up down animtaion
@include keyframes(tpupdown){
	0% { 
		@include transform(translateY(0));
	}
    100% { 
		@include transform(translateY(-20px));
	}
}

// tp up down animtaion
@include keyframes(tpleftright){
	0% { 
		@include transform(translateX(0));
	}
    100% {
		@include transform(translateX(-20px));
	}
}

// tp swing animtaion
@include keyframes(tpswing){
	0% { 
		@include transform(rotate(6deg));
	}
    100% { 
		@include transform(rotate(-6deg));
	}
}

// tp swing animtaion
@include keyframes(tpscale){
	0% { 
		@include transform(scale(.7)); 
	}
    100% {  
		@include transform(rotate(1.05));
	}
}


// services border home architechture

.animate-border{
    &::before,
    &::after{
        content: "";
        position: absolute;
        background-color: var(--tp-theme-8);
        width: 0;
        height: 1px;
        transition: width 0.0375s;
        @include transition(.2s);
    }

    &::before{
        top: 0;
        left: 0;
        transition-delay: .6s;
    }
    &::after{
        right: 0;
        bottom: 0;
        left: auto;
        transition-delay: .2s;
    }

    & .services-border{
        &::before,
        &::after{
            content: "";
            position: absolute;
            background-color: var(--tp-theme-8);
            height: 0;
            width: 1px;
            transition: height 0.0375s;
            @include transition(.2s);
        }

        &::before{
            transition-delay: 0s;
            bottom: 0;
            left: 0;
        }

        &::after{
            top: 0;
            right: 0;
            transition-delay: .4s;
        }
    }

    &:hover{

        &::after,
        &::before{
            width: 100%;
        }
        &::before{
            transition-delay: 0s;
        }
        &::after{
            transition-delay: .4s;
        }

        & .services-border{
            &::after,
            &::before{
                height: 100%;
            }
            &::before{
                transition-delay: 0.6s;
            }
            &::after{
                transition-delay: 0.2s;
            }
        }
    }
}
.animate-border-2{
    &::before,
    &::after{
        content: "";
        position: absolute;
        background-color: var(--tp-theme-8);
        width: 0;
        height: 1px;
        transition: width 0.0375s;
        @include transition(.4s);
    }

    &::before{
        top: 0;
        left: 0;
    }
    &::after{
        right: 0;
        bottom: 0;
        left: auto;
    }

    & .services-border-2{
        &::before,
        &::after{
            content: "";
            position: absolute;
            background-color: var(--tp-theme-8);
            height: 0;
            width: 1px;
            transition: height 0.0375s;
            @include transition(.4s);
        }

        &::before{
            top: 0;
            left: 0;
        }

        &::after{
            top: auto;
            bottom: 0;
            right: 0;
        }
    }

    &:hover{

        &::after,
        &::before{
            width: 100%;
        }

        & .services-border-2{
            &::after,
            &::before{
                height: 100%;
            }
        }
    }
}




@include keyframes(tp_slide_up){
    0% {
        -webkit-transform: translateY(3.25rem);
        transform: translateY(3.25rem);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@include keyframes(tp_slide_left){
    0% {
        -webkit-transform: translateX(3.25rem);
        transform: translateX(3.25rem);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}



.tp-border-loader svg{
    transform: rotate(-90deg);
}

.tp-border-loader svg circle {
	stroke: rgba($color: #fff, $alpha: .4);

    &:last-child{
        stroke: #fff;
        stroke-dashoffset: -356px; 
        stroke-dasharray: 0px, 366px;
    }

    @at-root{
        @include keyframes(tp-border-loader){
            0%{
                stroke-dashoffset: -356px; 
                stroke-dasharray: 356px, 366px;
            }
            95%{
                stroke-dashoffset: 0; 
                stroke-dasharray: 356px, 366px;
            }
            100%{
                stroke-dashoffset: 0; 
                stroke-dasharray: 0, 366px;
            }
        }
    }
}


@include keyframes( tp-pulse-btn-effect){
	70% {
		-webkit-box-shadow: 0 0 0 40px currentColor;
		box-shadow: 0 0 0 40px currentColor;
		opacity: 0;

	}
	100% {
		-webkit-box-shadow: 0 0 0 0 currentColor;
		box-shadow: 0 0 0 0 currentColor;
		opacity: 0;
	}
}


@include keyframes(borderanimate2){
    0% {
        transform: translate(-50%, -50%) scale(.8);
    }
	60%{
		opacity: 1;
	}
      100% {
        transform: translate(-50%, -50%) scale(2.5);
        opacity: 0;
      }
}


@include keyframes(borderanimate3){
    0% {
        transform: scale(.8);
        opacity: 0;
    }
	60%{
		opacity: 1;
	}
      100% {
        transform: scale(1.3);
        opacity: 0;
      }
}

@include keyframes(tp_arrow){
    49% {
        transform: translateX(30%);
    }
    50% {
        opacity: 0;
        transform: translateX(-30%);
    }
    51% {
        opacity: 1;
    }
}

@include keyframes(tp_arrow_svg){
    49% {
        transform: translateX(30%) translateY(-1px);
    }
    50% {
        opacity: 0;
        transform: translateX(-30%) translateY(-1px);
    }
    51% {
        opacity: 1;
    }
}


@include keyframes(tp-hotspot){
    0% {
        @include transform(scale(.8));
        opacity: 0;
      }
      70% {
        opacity: 1;
      }
      100% {
        @include transform(scale(1.3));
        opacity: 0;
      }
}

@include keyframes(tp-hotspot-2){
    0% {
        @include transform(scale(.1));
        opacity: 0;
      }
      70% {
        opacity: 1;
      }
      100% {
        @include transform(scale(1.3));
        opacity: 0;
      }
}



.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  /* ideally, transition speed should match zoom duration */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}