@use './mixins' as *;

%include-bg{
	@include background();
}

%tp-transition{
	@include transition(.3s);
}


