@use '../utils' as *;

/*----------------------------------------*/
/*  MODAL CSS START
/*----------------------------------------*/

.search__modal{
    & .modal-dialog{
        max-width: 700px;
    }
    &-form{
        padding: 50px 50px 55px;
        position: relative;
    }
    &-box{
        & p{
            font-size: 18px;
            color: #555555;
            font-weight: 400;
            margin-bottom: 40px;
            & span{
                font-weight: 600;
            }
        }
    }
    &-input{
        position: relative;
        & input{
            width: 100%;
            height: 55px;
            line-height: 55px;
            border: none;
            border-bottom-color: currentcolor;
            border-bottom-style: none;
            border-bottom-width: medium;
            border-bottom: 2px solid #dadada;
            outline: none;
            font-size: 20px;
            padding-right: 38px;

            @include tp-placeholder{
                color: var(--tp-text-3);
            }

            &:focus{
                border-color: var(--tp-theme-1);
            }
        }

        & .search-modal-btn{
            position: absolute;
            top: 50%;
            right: 0;
            @include transform(translateY(-50%));
            font-size: 18px;
            color: var(--tp-text-1);

            &:hover{
                color: var(--tp-theme-1);
            }
        }
    }
}

.search-modal-close{
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 16px;
    color: var(--tp-common-black);

    &:hover{
        color: var(--tp-theme-1);
    }
}


.elements-modal{
    .tp-btn-border{
        padding: 9px 30px;
    }
}